import { Template } from '../models/Template';

import { TemplateSegmentation } from '../components/templates/TemplateSegmentation';
import { TemplatePlanHashtag } from '../components/templates/TemplatePlanHashtag';
import { TemplateBudget } from '../components/templates/TemplateBudget';
import { TemplateCreateOffer } from '../components/templates/TemplateCreateOffer';
import { TemplateHQ } from '../components/templates/TemplateHQ';
import { TemplateCompetitors } from '../components/templates/TemplateCompetitors';
import { TemplateWireframes } from '../components/templates/TemplateWireframes';
import { TemplateCollaboration } from '../components/templates/TemplateCollaboration';
import { TemplateHeuristic } from '../components/templates/TemplateHeuristic';

import { Collaboration } from '../components/templates/pdf/Collaboration';
import { Competitors } from '../components/templates/pdf/Competitors';
import { CreateOffer } from '../components/templates/pdf/CreateOffer';
import { Hashtags } from '../components/templates/pdf/Hashtags';
import { Heuristic } from '../components/templates/pdf/Heuristic';
import { HundredQuestions } from '../components/templates/pdf/HundredQuestions';
import { Segmentation } from '../components/templates/pdf/Segmentation';
import { Wireframes } from '../components/templates/pdf/Wireframes';

import imgFrameworkSegmentacion from '../assets/img/template-framework-segmentacion.jpg';
import imgPlanHashtag from '../assets/img/template-hashtags.jpg';
import imgBudgetCalculator from '../assets/img/template-calculadora.jpg';
import imgOffer from '../assets/img/template-ofertas.jpg';
import imgHundredQuestions from '../assets/img/template-100-preguntas.jpg';
import imgCollaboration from '../assets/img/template-collaboration.jpg';
import imgCompetitors from '../assets/img/template-competitors.jpg';
import imgHeuristic from '../assets/img/template-heuristic.jpg';
import imgWireframes from '../assets/img/template-wireframes.jpg';

export const templates = [
  new Template(
    'segmentacion_intereses',
    'Framework de Segmentación',
    imgFrameworkSegmentacion,
    `Utiliza este framework para definir a quien le vendes y algunas palabras
      claves que te sirvan de cara a encontrar intereses ocultos`,
    `Utiliza este framework para definir a quien le vendes y algunas palabras
      claves que te sirvan de cara a encontrar intereses ocultos`,
    `
      <p class="text">
          Para investigar palabras claves puede utilizar UberSuggest. 
          Una vez que tienes las palabras claves comienza a investigar 
          con la extensión de Chrome “M.A.S Intereses” y apunta todos 
          los posibles intereses. Luego agrúpalos por categorías para 
          tenerlos organizados. Por último tendrás que elegir entre 5 
          y 10 intereses que vas a probar en un conjunto de anuncios.
      </p>
      <br><br>
      <a
        href="https://docs.google.com/spreadsheets/d/1Gw7cZ2C3o1s2Hf03lWHKmqNs0cogl4t9embFQ9nkAKk/edit#gid=0" 
        class="euiButton euiButton--primary euiButton--fill"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateSegmentation,
    Segmentation
  ),
  new Template(
    'plan_de_hashtags',
    'Plan de Hashtags',
    imgPlanHashtag,
    `Con esta plantilla podrás realizar un aterrizaje exhaustivo de las etiquetas
      más relevantes y categorizadas que utilizarás para cada una de las cuentas 
      que gestiones`,
    `Con esta plantilla podrás realizar un aterrizaje exhaustivo de las etiquetas
      más relevantes y categorizadas que utilizarás para cada una de las cuentas 
      que gestiones`,
    `
      <p class="text">
          Lo primero que debes hacer es marcar un objetivo, aquello que deseas 
          conseguir con el plan de Hashtag (visibilidad, conversiones, engagement, 
          etc). Luego, realiza una búsqueda profunda de palabras claves asociadas 
          a la marca, que puedan darte una idea de los hashtags que pondrás en cada 
          una de las categorías. Para conocer la ubicación de cada etiqueta, debes ir 
          a la plataforma de Instagram y en el buscador de etiquetas, pondrás la palabra 
          clave y según el resultado de sus publicaciones (y lo que indicamos en la 
          lección de Estrategias de Hashtags) lo posicionas en Lowtag, Midtag, Supertag 
          o Bigtags. Una vez finalices, puedes imprimir tu investigación para entregarla 
          al cliente.
      </p>
      <br><br>
      <a
        href="https://bms.conviertemas.com/leccion/estrategia-de-hashtags/" 
        class="euiButton euiButton--primary euiButton--fill"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver lección
        </span>
      </a>
    `,
    TemplatePlanHashtag,
    Hashtags
  ),
  new Template(
    'calculadora_presupuesto',
    'Calculadora de Presupuestos de Proyectos',
    imgBudgetCalculator,
    'Con esta herramienta calcula en minutos el costo total de tus proyectos de servicios',
    'Con esta herramienta calcula en minutos el costo total de tus proyectos de servicios. Controla todas las varaibles a tener en cuenta a la hora de establecer el valor de tu hora de trabajo y el precio de tus servicios.',
    `
      <p class="text">
        Responde a cada una de las preguntas y obten el total que deberías 
        cobrar por el proyecto y valor del la primera factura.
      </p>
    `,
    TemplateBudget
  ),
  new Template(
    'create_offer',
    'Metodología Oferta Arcoíris',
    imgOffer,
    'Utiliza esta metodología para crear ofertas irresistibles',
    'Utiliza esta metodología para crear ofertas irresistibles. No dejes que tus ideas se queden en papel mojado. Ahora es el momento de tomar acción y lanzar tus ofertas al mercado para que los usuarios puedan verlas y tomarlas como referencia en su toma de decisión de compra.',
    `
      <p class="text">
          Completa la plantilla respondiendo cada una de las preguntas. 
          Si tienes dudas guíate por la aferta de ejemplo que te compartimos 
          en la plantilla original.
      </p>
      <br><br>
      <a 
        href="https://docs.google.com/spreadsheets/d/1btWlCwtvkAzuRS52uUv3KHBntPeBxFGypkd_DGYyvlU/edit?usp=sharing" 
        class="euiButton euiButton--primary euiButton--fill"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateCreateOffer,
    CreateOffer
  ),
  new Template(
    'hundred_questions',
    '100 Preguntas Para Comenzar con un Cliente',
    imgHundredQuestions,
    '100 preguntas básicas que debes hacer a un nuevo cliente y que te permitirán avanzar de forma inmediata',
    'Tras hacer una gran presentación y ser contratado por un cliente, deberás arrancar lo antes posible la ejecución del servicio. Queremos ayudarte con una plantilla que incluye las 100 preguntas básicas que debes hacer y que te permitirá avanzar de forma inmediata, teniendo todo el conjunto de información relevante en un solo vistazo.',
    `
      <a 
        href="https://docs.google.com/document/d/1FblJqU6NXNj0nv_PCVCVBduhofvkdOwMv8vD8gnkHVg/copy"
        class="euiButton euiButton--primary euiButton--fill"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateHQ,
    HundredQuestions
  ),
  new Template(
    'competitors-analysis',
    'Análisis de Competidores',
    imgCompetitors,
    'Con esta plantilla podrás determinar cuáles son tus verdaderos competidores.',
    'Conocer quienes son en realidad tus competidores y los factores que te diferencian es fundamental para plantear estrategias competitivas y efectivas. Con esta plantilla podrás determinar cuáles son tus verdades competidores.',
    `
      <p class="text">
        Completa la plantilla para ti mismo, y luego completa 
        la plantilla para cada competidor.
      </p>
      <br><br>
      <a 
        href="https://docs.google.com/spreadsheets/u/1/d/17N71KsIFVn-TovyozQ_DiCV_mUAuA_eJE9y5TGHBU20/copy" 
        class="euiButton euiButton--primary euiButton--fill"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateCompetitors,
    Competitors
  ),
  new Template(
    'web-planning',
    'Planificación de Sitio Web + Elaboración de Wireframe',
    imgWireframes,
    'Con esta plantilla te ayudamos a que tu web cuente con los elementos necesarios para conectar con tu audiencia.',
    'Cada sitio web es único y en función del tipo de negocio/marca que sea, las páginas y estructura, puede variar. Con esta plantilla te ayudamos a que tu web cuente con los elementos necesarios para conectar con tu audiencia.',
    `
      <p class="text">
        Mira el vídeo en la lección 
        <a 
          target="_blank" 
          href="https://bms.conviertemas.com/leccion/estrategia-de-sitio-web-como-planificar-una-web-elaboracion-wireframe/"
          target="_blank" 
          rel="noopener noreferrer"
        >
        Aquí
        </a> y completa la plantilla.
      </p>
      <br><br>
      <a 
        class="euiButton euiButton--primary euiButton--fill" 
        href="https://drive.google.com/file/d/1buas-wlKrCqxWQr5C25FyEYQwAhAqEDC/view"
        target="_blank" 
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateWireframes,
    Wireframes,
  ),
  new Template(
    'collaboration',
    'Plantilla para colaboraciones',
    imgCollaboration,
    'Con esta plantilla podrás planificar tu estrategia de colaboraciones con marcas',
    'Puedes invertir en campañas de publicidad o puedes invertir en tráfico ganado a través de acciones de relaciones públicas y gracias a las colaboraciones. Esta estrategia no es solo entre marcas personales, al contrario, entre empresas es aún más efectivo. Con esta plantilla podrás planificar tu estrategia de colaboraciones con marcas',
    `
      <p class="text">
        Mira la lección 
        <a 
          href="https://bms.conviertemas.com/leccion/estrategia-de-colaboraciones-con-marcas/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
        Aquí
        </a>
        y completa la plantilla
      </p>
      <br><br>
      <a 
        class="euiButton euiButton--primary euiButton--fill" 
        href="https://docs.google.com/spreadsheets/u/1/d/1TF9qcQlA11WG8F4sKpETfsXF8dh19FCcAtsaj6C2dHo/copy" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateCollaboration,
    Collaboration
  ),
  new Template(
    'heuristic',
    'Evaluación Heurística de CRO',
    imgHeuristic,
    'Con esta  plantilla tendras un marco de referencia para aplicar el análisis heurístico al CRO de forma lógica y estructurada',
    'Comenzar con un análisis heurístico te ayudará a identificar las áreas más críticas de un sitio o página web para encontrar oportunidades de optimización. Esta plantilla te permitirá tener un marco de referencia para aplicar este enfoque de forma lógica y estructurada',
    `
      <p class="text">
        Sigue las instrucciones en la lección
        <a
          href="https://bms.conviertemas.com/leccion/metodologia-de-analisis-heuristico-para-auditorias-de-cro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Aquí
        </a>
        y completa la plantilla
      </p>
      <br><br>
      <a
        class="euiButton euiButton--primary euiButton--fill"
        href="https://coda.io/d/BMS-Evaluacion-Heuristica-de-CRO_dhj3KSzAyTG/Checklist-de-evaluacion_su26E#Friccion_tuGkn/r1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span class="euiButtonContent euiButton__content">
          Ver plantilla
        </span>
      </a>
    `,
    TemplateHeuristic,
    Heuristic
  )
];
