import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Gallery } from '../../views/Gallery';
import { Template } from '../../views/Template';
import { PdfDocument } from '../../views/PdfDocument';
import { Navbar } from '../layout/Navbar';

export const Routes = () => (
  <Fragment>
    <div className="main-content">
      <Navbar />
      <Switch>
        <Route path="/templates/pdf" component={PdfDocument} />
        <Route path="/templates/:id" component={Template} />
        <Route path="/templates" exact component={Gallery} />
        <Route path="/" exact>
          <Redirect to="/templates" />
        </Route>
      </Switch>
    </div>
  </Fragment>
);
