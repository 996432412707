import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { EuiTextArea } from '@elastic/eui';

import '../../assets/scss/components/custom-input.scss';

const PureCustomInput = props => {
  const inputRef = useRef(null);
  const disabled = props.disabled || false;

  const inputStyle = {
    minHeight: `${props.minHeight || 8}rem`,
    fontSize: `${props.fontSize || 0.75}rem`
  };

  const customResize = e => {
    if (e && e.target) {
      const elem = e.target;
      elem.style.height = 'auto';
      elem.style.height = `${elem.scrollHeight}px`;
    }
  };

  const delayedResize = e => setTimeout(customResize, 0);

  useEffect(() => {
    customResize({ target: inputRef.current });
  }, [inputRef]);

  useEffect(() => {
    if (!props.value) {
      inputRef.current.style.height = inputStyle.minHeight;
    }
  }, [props.value, inputStyle.minHeight]);

  return (
    <Fragment>
      <EuiTextArea
        className="border-dashed notPrint"
        resize="none"
        inputRef={inputRef}
        placeholder={props.placeholder}
        onChange={e => {
          if (props.multiple && props.setMultiple && props.level >= 0)
            props.onChangeHandler(e.target.value, props.level, props.index, props.multiple, props.setMultiple);
          else if (props.multiple && props.setMultiple)
            props.onChangeHandler(e.target.value, props.index, props.multiple, props.setMultiple);
          else if (Number(props.index) >= 0)
            props.onChangeHandler(e.target.value, props.index);
          else props.onChangeHandler(e.target.value);
          customResize(e);
        }}
        onCut={delayedResize}
        onPaste={delayedResize}
        onDrop={delayedResize}
        onKeyDown={delayedResize}
        value={props.value}
        style={inputStyle}
        fullWidth={props.isFullWidth}
        disabled={disabled}
      />
      <p className="only-print">{props.value}</p>
    </Fragment>
  );
};

PureCustomInput.propTypes = {
  isFullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  minHeight: PropTypes.number,
  fontSize: PropTypes.number,
  multiple: PropTypes.array,
  setMultiple: PropTypes.func
};

const CustomInput = React.memo(PureCustomInput);

export { CustomInput };
