import React from 'react';
import { Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { TitleSection } from './utils/TitleSection';
import { FlexContainer } from './utils/FlexContainer';
import { Spacer, SpacerS, SpacerXs } from './utils/Spacer';
import { GenericPage, Introduction, } from './utils';

const labels = {
  titleDesc:
    'Determina tu territorio, aterriza quienes son tus competidores y otros territorios complementarios',
  yourTerritory: 'Tu territorio',
  yourCompetitors: 'Tus competidores',
  otherTerritories: 'Otros territorios complementarios',
  yourBrand: 'Tu marca',
  possibleCollaborators: 'Posibles colaboradores'
};

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  col2: {
    width: '50%',
    paddingHorizontal: 8
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#333',
    borderBottomStyle: 'solid'
  },
  content: {
    fontFamily: 'Poppins',
    fontSize: 10
  },
  listElement: {
    fontFamily: 'Poppins',
    fontSize: 10,
    marginBottom: 6
  }
});

const TextSection = ({ label, data }) => (
  <React.Fragment>
    <Text style={styles.subtitle}>{label}</Text>
    <SpacerXs />
    <Text style={styles.content}>{data}</Text>
  </React.Fragment>
);

const List = ({ label, items }) => (
  <React.Fragment>
    <Text style={styles.subtitle}>{label}</Text>
    <SpacerXs />
    {items
      .filter(item => item.value.length > 0)
      .map(item => (
        <Text key={item.key} style={styles.listElement}>
          • {item.value}
        </Text>
      ))}
  </React.Fragment>
);

export const Collaboration = props => {
  return (
    <Document>
      <GenericPage>
        <TitleSection title={props.data.title} description={labels.titleDesc} />
        <Introduction
          showField={props.data.showIntroduction}
          fieldData={props.data.introduction}
        />
        <Spacer />
        <FlexContainer>
          <View style={styles.col2}>
            <TextSection
              label={labels.yourTerritory}
              data={props.data.territory}
            />
            <SpacerS />
            <TextSection
              label={labels.yourCompetitors}
              data={props.data.competitors}
            />
          </View>
          <View style={styles.col2}>
            <List
              label={labels.otherTerritories}
              items={props.data.otherTerritories}
            />
          </View>
        </FlexContainer>
      </GenericPage>
      <GenericPage>
        <FlexContainer>
          <View style={styles.col2}>
            <TextSection label={labels.yourBrand} data={props.data.brand} />
          </View>
          <View style={styles.col2}>
            <List
              label={labels.possibleCollaborators}
              items={props.data.collaborators}
            />
          </View>
        </FlexContainer>
        <Introduction
          showField={props.data.showConclusion}
          fieldData={props.data.conclusion}
        />
      </GenericPage>
    </Document>
  );
};
