import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 12,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  }
})

const Subtitle = React.memo(props => (
  <Text style={styles.subtitle}>{props.subtitle}</Text>
));

Subtitle.propTypes = {
  subtitle: PropTypes.string.isRequired
};

export { Subtitle };