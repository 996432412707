import {
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui';
import React from 'react';
import PropTypes from 'prop-types';
import { utils } from '../../config/env';

const PureEditableTitle = ({ title, setTitle }) => {
  const [isEditable, setIsEditable] = React.useState(false);

  return (
    <React.Fragment>
      <EuiTitle style={{ width: '100%' }}>
        {isEditable ? (
          <React.Fragment>
            <EuiFormRow fullWidth={true} style={{ width: '100%' }}>
              <EuiFieldText
                append={
                  <EuiButton
                    onClick={e => setIsEditable(false)}
                    color="primary"
                  >
                    <EuiIcon
                      type="check"
                      style={{ backgroundColor: 'transparent' }}
                    />
                  </EuiButton>
                }
                onKeyDown={e => {
                  if (e.key.toUpperCase() === utils.enterKey) {
                    setIsEditable(false);
                  }
                }}
                onChange={e => setTitle(e.target.value)}
                value={title}
                fullWidth={true}
                autoFocus
              />
            </EuiFormRow>
            <EuiSpacer size="m" />
          </React.Fragment>
        ) : (
          <h2 onDoubleClick={e => setIsEditable(true)}>{title}</h2>
        )}
      </EuiTitle>
    </React.Fragment>
  );
};

PureEditableTitle.propTypes = {
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired
};

const EditableTitle = React.memo(PureEditableTitle);

export { EditableTitle };
