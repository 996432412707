import axios from 'axios';
import {
  getToken,
  setToken,
  deleteToken,
  getAuthUser,
  setAuthUser,
  deleteUser
} from '../utils/auth';
import { api } from '../config/env';

export const verifyToken = () => {
  const token = getToken();
  const authUser = getAuthUser();

  if (!token || !authUser) return false;

  return authUser;
};

export const requestLogin = async (username, password) => {
  const token = getToken();

  if (token) return false;

  const reqUrl = `${api.baseUrl}${api.login}`;
  const data = { username, password };

  try {
    let res = await axios.post(reqUrl, data);

    const token = res.data.token;
    let authUser = {
      displayName: res.data.displayName,
      avatar: res.data.avatar
    };

    setToken(token);
    setAuthUser(authUser);

    return authUser;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const logout = () => {
  const token = getToken();

  if (!token) return false;

  deleteToken();
  deleteUser();

  return true;
};
