import { EuiCard, EuiFlexItem } from '@elastic/eui';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import { templates } from '../data/templates';
import { style } from '../config/env';

import '../assets/scss/views/gallery.scss';

export const Gallery = () => {
  const context = useContext(GlobalContext);

  const containerStyle = { backgroundColor: style.pageBackgroundColor };

  return (
    <div className="container pt-4" style={containerStyle}>
      <div className="row">
        {templates.map(template => {
          const card = (
            <EuiCard
              image={
                <div>
                  <img src={template.imageUrl} alt={template.name} />
                </div>
              }
              textAlign="left"
              title={<h3 className="card-title">{template.name}</h3>}
              description={
                <span className="card-description">
                  {template.shortDescription}
                </span>
              }
            />
          );

          return (
            <div
              className="col-xs-12 col-sm-6 col-md-3 mb-4"
              key={template.key}
            >
              <EuiFlexItem className="gallery-card">
                {template.showLink ? (
                  <Link
                    to={`/templates/${template.key}`}
                    onClick={() => context.setSelectedTemplate(template)}
                  >
                    {card}
                  </Link>
                ) : (
                  <React.Fragment>{card}</React.Fragment>
                )}
              </EuiFlexItem>
            </div>
          );
        })}
      </div>
    </div>
  );
};
