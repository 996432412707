import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  containerCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
});

const FlexContainer = props => (
  <View
    style={
      props.direction === 'column' ? styles.containerCol : styles.containerRow
    }
  >
    {props.children}
  </View>
);

FlexContainer.propTypes = {
  direction: PropTypes.string
};

export { FlexContainer };
