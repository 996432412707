import React from 'react';
import { View, Document, StyleSheet, Text, Font } from '@react-pdf/renderer';
import { TitleSection } from './utils/TitleSection';
import { GenericPage } from './utils/GenericPage';
import { SpacerS, SpacerXs } from './utils/Spacer';
import { Introduction } from './utils/Introduction';

const labels = {
  frontOffer: 'Oferta Front',
  frontOfferDesc:
    'Describe a continuación los contenidos de la oferta inicial y visible para todos los compradores',
  itemToSell: '¿Qué vendemos? - Nombre producto/servicio',
  offerType: 'Tipo de oferta',
  avatars: 'Avatares (A quién le vendemos)',
  avatarsDesc:
    'Describe el perfil o perfiles del comprador para tu producto o servicio',
  offerResults: 'Resultados de la oferta',
  offerResultsDesc:
    'Describe los resultados que obtendrá el cliente con esta oferta',
  mainProduct: 'Producto/servicio principal',
  formats: 'Formatos',
  addons: 'Add-ons y bonus',
  price: 'Precio',
  productPrice: 'PRECIO producto',
  value: 'Valor',
  backOffer: 'Oferta Back',
  backOfferDesc:
    'Describe a continuación los componentes adicionales de la oferta',
  bump: 'Bump',
  bumpFinalPrice: 'Precio final del producto + BUMP',
  upsell: 'Upsell',
  finalUpsellPrice: 'Precio final del producto + BUMP + Upsell'
};

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  centered: {
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Poppins'
  },
  title: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: 12,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  description: {
    fontSize: 10,
    fontFamily: 'Poppins'
  },
  content: {
    fontSize: 10,
    fontFamily: 'Poppins'
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  mainProductLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    paddingRight: 4
  },
  mainProductRight: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    paddingLeft: 4
  },
  addonFirst: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    paddingRight: 4
  },
  addonSecond: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    paddingHorizontal: 4
  },
  addonThird: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    paddingLeft: 4
  },
  totalizer: {
    backgroundColor: '#eff8ff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: 16
  },
  totalizerTotal: {
    display: 'flex',
    flexDirection: 'column'
  },
  bumpElement: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    paddingLeft: 4
  }
});

const MainSectionTitle = props => (
  <React.Fragment>
    <Text style={styles.title}>{props.title}</Text>
    <Text style={styles.description}>{props.description}</Text>
    <SpacerS />
  </React.Fragment>
);

const TitledText = props => (
  <React.Fragment>
    <Text style={styles.subtitle}>{props.title}</Text>
    <SpacerXs />
    <Text style={styles.content}>{props.content}</Text>
    <SpacerS />
  </React.Fragment>
);

const SingleTextList = props => (
  <React.Fragment>
    <Text style={styles.subtitle}>{props.title}</Text>
    <Text style={styles.description}>{props.description}</Text>
    <SpacerXs />
    {props.items
      .filter(item => item.value.length > 0)
      .map(item => (
        <React.Fragment key={item.key}>
          <Text style={styles.content}>• {item.value}</Text>
        </React.Fragment>
      ))}
    <SpacerS />
  </React.Fragment>
);

const FlexContainer = props => (
  <View style={styles.flexContainer}>{props.children}</View>
);

const MainProduct = props => (
  <React.Fragment>
    <FlexContainer>
      <View style={styles.mainProductLeft}>
        <Text style={styles.subtitle}>{labels.mainProduct}</Text>
        <Text style={styles.content}>{props.mainProduct}</Text>
      </View>
      <View style={styles.mainProductRight}>
        <Text style={styles.subtitle}>{labels.formats}</Text>
        <Text style={styles.content}>{props.format}</Text>
      </View>
    </FlexContainer>
    <SpacerS />
  </React.Fragment>
);

const Addons = props => (
  <React.Fragment>
    <FlexContainer>
      <View style={styles.addonFirst}>
        <Text style={styles.subtitle}>{labels.addons}</Text>
      </View>
      <View style={styles.addonSecond}>
        <Text style={styles.subtitle}>{labels.price}</Text>
      </View>
      <View style={styles.addonThird}>
        <Text style={styles.subtitle}>{labels.formats}</Text>
      </View>
    </FlexContainer>
    <SpacerXs />
    {props.addons.map(addon => (
      <React.Fragment key={addon.key}>
        <FlexContainer>
          <View style={styles.addonFirst}>
            <Text style={styles.subtitle}>{addon.values[0]}</Text>
          </View>
          <View style={styles.addonSecond}>
            <Text style={styles.subtitle}>{addon.values[1]}</Text>
          </View>
          <View style={styles.addonThird}>
            <Text style={styles.subtitle}>{addon.values[2]}</Text>
          </View>
        </FlexContainer>
        <SpacerXs />
      </React.Fragment>
    ))}
    <SpacerS />
  </React.Fragment>
);

const Bump = props => (
  <React.Fragment>
    <FlexContainer>
      <View style={styles.bumpElement}>
        <Text style={styles.subtitle}>{labels.bump}</Text>
        <Text style={styles.content}>{props.bump.addon}</Text>
      </View>
      <View style={styles.addonSecond}>
        <Text style={styles.subtitle}>{labels.value}</Text>
        <Text style={styles.content}>{props.bump.value}</Text>
      </View>
      <View style={styles.addonSecond}>
        <Text style={styles.subtitle}>{labels.price}</Text>
        <Text style={styles.content}>{props.bump.price}</Text>
      </View>
      <View style={styles.bumpElement}>
        <Text style={styles.subtitle}>{labels.formats}</Text>
        <Text style={styles.content}>{props.bump.format}</Text>
      </View>
    </FlexContainer>
    <SpacerS />
  </React.Fragment>
);

const Upsell = props => (
  <React.Fragment>
    <FlexContainer>
      <View style={styles.bumpElement}>
        <Text style={styles.subtitle}>{labels.upsell}</Text>
      </View>
      <View style={styles.addonSecond}>
        <Text style={styles.subtitle}>{labels.value}</Text>
      </View>
      <View style={styles.addonSecond}>
        <Text style={styles.subtitle}>{labels.price}</Text>
      </View>
      <View style={styles.bumpElement}>
        <Text style={styles.subtitle}>{labels.formats}</Text>
      </View>
    </FlexContainer>
    <SpacerXs />
    {props.upsell.map(upsell => (
      <React.Fragment key={upsell.key}>
        <FlexContainer>
          <View style={styles.bumpElement}>
            <Text style={styles.content}>{upsell.values[0]}</Text>
          </View>
          <View style={styles.addonSecond}>
            <Text style={styles.content}>{upsell.values[1]}</Text>
          </View>
          <View style={styles.addonSecond}>
            <Text style={styles.content}>{upsell.values[2]}</Text>
          </View>
          <View style={styles.bumpElement}>
            <Text style={styles.content}>{upsell.values[3]}</Text>
          </View>
        </FlexContainer>
        <SpacerXs />
      </React.Fragment>
    ))}
    <SpacerS />
  </React.Fragment>
);

const Totalizer = props => (
  <React.Fragment>
    <View style={styles.totalizer}>
      <Text style={styles.subtitle}>{props.label}</Text>
      {props.value ? (
        <View style={styles.totalizerTotal}>
          <Text style={styles.subtitle}>{labels.value}</Text>
          <Text style={styles.content}>{props.value}</Text>
        </View>
      ) : null}
      <View style={styles.totalizerTotal}>
        <Text style={styles.subtitle}>{labels.price}</Text>
        <Text style={styles.content}>{props.price}</Text>
      </View>
    </View>
    <SpacerS />
  </React.Fragment>
);

export const CreateOffer = props => {
  const { data } = props;
  console.log(data.offerType[0]);
  const finalPrice =
    Number(data.productPrice.price) +
    Number(data.bump.price) +
    Number(
      data.upsell.reduce((prev, current) => prev + Number(current.values[2]), 0)
    );

  return (
    <Document>
      <GenericPage>
        <TitleSection title={data.title} />
        <Introduction
          showField={data.showIntroduction}
          fieldData={data.introduction}
        />
        <MainSectionTitle
          title={labels.frontOffer}
          description={labels.frontOfferDesc}
        />
        <TitledText title={labels.itemToSell} content={data.itemToSell} />
        <TitledText
          title={labels.offerType}
          content={
            data.offerType[0] !== undefined ? data.offerType[0].label : ''
          }
        />
        <SingleTextList
          title={labels.avatars}
          description={labels.avatarsDesc}
          items={data.avatars}
        />
        <SingleTextList
          title={labels.offerResults}
          description={labels.offerResultsDesc}
          items={data.offerResults}
        />
        <MainProduct
          mainProduct={data.mainProduct}
          format={data.mainProductFormat}
        />
        <Addons addons={data.addons} />
        <Totalizer
          label={labels.productPrice}
          value={data.productPrice.value}
          price={data.productPrice.price}
        />
        <MainSectionTitle
          title={labels.backOffer}
          description={labels.backOfferDesc}
        />
        <Bump bump={data.bump} />
        <Totalizer
          label={labels.bumpFinalPrice}
          price={Number(data.productPrice.price) + Number(data.bump.price)}
        />
        <Upsell upsell={data.upsell} />
        <Totalizer label={labels.finalUpsellPrice} price={finalPrice} />
        <Introduction showField={data.showOutro} fieldData={data.outro} />
      </GenericPage>
    </Document>
  );
};
