import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Redirect } from 'react-router-dom';
import { read } from '../services/storage';
import { storage } from '../config/env';
import { templates } from '../data/templates';

export const PdfDocument = props => {
  const data = read(storage.pdfTemplateData);
  const template = templates.find(temp => temp.key === data.template);

  const style = `
    .navbar {
      display: none;
    }
    .main-content {
      padding-top: 0;
    }
    @media only screen and (max-width: 767px) {
      .main-content {
        margin-top: 0 !important;
      }
    }
  `;

  return (
    <React.Fragment>
      <style>{style}</style>
      {data && template ? (
        <PDFViewer style={{ display: 'block', width: '100%', height: '100vh' }}>
          <template.pdfComponent data={data} template={template} />
        </PDFViewer>
      ) : (
        <Redirect to="/templates" />
      )}
    </React.Fragment>
  );
};
