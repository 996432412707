import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Login } from './views/Login';
import { Routes } from './components/utils/Routes';
import { verifyToken } from './services/auth';
import { GlobalContext } from './context/GlobalContext';
import { Spinner } from './components/utils/Spinner';

import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
import '@elastic/eui/dist/eui_theme_light.min.css';
import './assets/scss/index.scss';

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const context = useContext(GlobalContext);

  useEffect(() => {
    if (!context.isLoggedIn) {
      const authUser = verifyToken();
      if (authUser) {
        context.setIsLoggedIn(true);
        context.setAuthUser(authUser);
      }
      setIsLoading(false);
    }
  }, [context, context.isLoggedIn]);

  const routesTemplate = context.isLoggedIn ? <Routes /> : <Login />;

  return <Router>{isLoading ? <Spinner /> : routesTemplate}</Router>;
};
