import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiIcon,
  EuiSpacer,
  EuiToolTip,
  htmlIdGenerator
} from '@elastic/eui';

const PureSingleInputDynamicList = ({
  items,
  setItems,
  placeholder,
  hideInputTooltipText,
  deleteInputTooltipText,
  addButtonLabel,
  hideShow
}) => {
  const makeId = htmlIdGenerator();

  const hideTooltip = hideInputTooltipText || 'Activar/Desactivar control';
  const deleteTooltip = deleteInputTooltipText || 'Borrar control';
  const addLabel = addButtonLabel || 'Agregar control';

  const handleOnChange = (e, idx) => {
    const newItems = [...items];
    newItems[idx].value = e.target.value;
    setItems(newItems);
  };

  const addItem = () => {
    const newItems = [...items];
    newItems.push({ key: makeId(), value: '', disabled: false });
    setItems(newItems);
  };

  const deleteItem = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const toggleItem = index => {
    const newItems = [...items];
    newItems[index].disabled = !newItems[index].disabled;
    setItems(newItems);
  };

  return (
    <Fragment>
      {items.map((item, index) => {
        return (
          <Fragment key={item.key}>
            <EuiFieldText
              fullWidth
              placeholder={placeholder}
              value={item.value}
              onChange={e => handleOnChange(e, index)}
              disabled={item.disabled}
              append={
                <Fragment>
                  {items.length > 1 ? (
                    <Fragment>
                      {hideShow === true ? null : (
                        <EuiToolTip position="top" content={hideTooltip}>
                          <EuiButtonIcon
                            iconType="eyeClosed"
                            aria-label="Ocultar"
                            onClick={() => toggleItem(index)}
                          />
                        </EuiToolTip>
                      )}
                      <EuiToolTip position="top" content={deleteTooltip}>
                        <EuiButtonIcon
                          iconType="trash"
                          aria-label="Borrar"
                          onClick={() => deleteItem(index)}
                        />
                      </EuiToolTip>
                    </Fragment>
                  ) : null}
                </Fragment>
              }
            />
            <EuiSpacer size="s" />
          </Fragment>
        );
      })}
      <EuiSpacer className="notPrint" size="s" />
      <EuiButtonEmpty className="notPrint" onClick={e => addItem()} size="xs">
        <EuiIcon type="plusInCircle" />
        &nbsp;{addLabel}
      </EuiButtonEmpty>
    </Fragment>
  );
};

PureSingleInputDynamicList.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  hideInputTooltipText: PropTypes.string,
  deleteInputTooltipText: PropTypes.string,
  hideShow: PropTypes.bool
};

const SingleInputDynamicList = React.memo(PureSingleInputDynamicList);

export { SingleInputDynamicList };
