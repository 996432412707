import React, { useContext, useState } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiFieldText,
  EuiLink,
  EuiSpacer,
  EuiFieldPassword,
  EuiSwitch,
  EuiIcon,
  EuiFlexGroup
} from '@elastic/eui';
import { requestLogin } from '../services/auth';
import { GlobalContext } from '../context/GlobalContext';
import { login, app } from '../config/env';
import { deleteUser, deleteToken } from '../utils/auth';

import logo from '../assets/img/mas-plantillas.svg';

import '../assets/scss/views/login.scss';

export const Login = () => {
  const logoSize = 'original';
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [dual, setDual] = useState(true);

  const context = useContext(GlobalContext);

  const doLogin = async () => {
    // Delete saved tokens if any
    deleteToken();
    deleteUser();

    // Normal login process
    setIsLoading(true);

    const loginData = await requestLogin(username, password);

    setIsLoading(false);

    if (!loginData) {
      setShowError(true);
    } else {
      context.setAuthUser(loginData);
      context.setIsLoggedIn(true);
    }
  };

  return (
    <EuiPage className="Login">
      <EuiPageBody component="div">
        <EuiPageContent
          verticalPosition="center"
          horizontalPosition="center"
          className="login-panel"
        >
          <EuiFlexGroup alignItems="center" justifyContent="center">
            <EuiIcon size={logoSize} type={logo} alt={app.title} />
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiForm component="form" style={{ width: '100%' }}>
            <EuiFormRow label="Email" helpText="" isInvalid={showError}>
              <EuiFieldText
                type="email"
                icon="email"
                value={username}
                onChange={e => {
                  if (showError) setShowError(false);
                  setUsername(e.target.value);
                }}
                aria-label="Use aria labels when no actual label is in use"
                isInvalid={showError}
              />
            </EuiFormRow>
            <EuiFormRow
              label="Contraseña"
              helpText=""
              isInvalid={showError}
              error={[login.errorMessage]}
            >
              <EuiFieldPassword
                type={dual ? 'dual' : undefined}
                value={password}
                onChange={e => {
                  if (showError) setShowError(false);
                  setPassword(e.target.value);
                }}
                aria-label="Use aria labels when no actual label is in use"
                isInvalid={showError}
                extras={
                  <EuiSwitch
                    compressed
                    label="dual"
                    checked={dual}
                    onChange={e => setDual(e.target.checked)}
                  />
                }
              />
            </EuiFormRow>
            <EuiFormRow>
              <EuiButton
                onClick={doLogin}
                disabled={isLoading}
                fullWidth={true}
                color="primary"
                fill
              >
                {login.loginLabel}
              </EuiButton>
            </EuiFormRow>
            <EuiFormRow>
              <EuiLink
                href={login.recoverPasswordLink}
                target="_blank"
                rel="noreferrer noopener"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  textAlign: 'center'
                }}
              >
                <small style={{ fontSize: '0.75rem' }}>
                  {login.recoverPasswordLabel}
                </small>
              </EuiLink>
            </EuiFormRow>
          </EuiForm>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};
