/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { GenericPage, Introduction } from './utils/';

Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/'
});

const styles = StyleSheet.create({
  centered: {
    marginHorizontal: 'auto'
  },
  titleSection: {
    fontSize: 14,
    marginBottom: 5,
    color: '#000000',
    fontWeight: 600
  },
  subTitle: {
    fontSize: 11,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 500,
    color: '#000000'
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#dee2e6'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColLeft: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: '#dee2e6',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableColRight: {
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: '#dee2e6'
  },
  tableCell: {
    marginTop: 5,
    marginBottom: 2,
    fontSize: 10,
    padding: 4,
    textAlign: 'left'
  },
  tableCellText: {
    fontSize: 9,
    padding: 4,
    textAlign: 'left'
  },
  tableCellHead: {
    marginTop: 5,
    marginBottom: 2,
    fontSize: 10,
    padding: 4,
    textAlign: 'left',
    color: '#000000'
  },
  spacer: {
    padding: 16
  },
  spacerS: {
    padding: 8
  },
  spacerXs: {
    padding: 4
  }
});

const ListQuestions = props => {
  const { arr } = props;

  return (
    <React.Fragment>
      {arr.map((question, idx) => (
        <React.Fragment key={idx}>
          <Text style={styles.subTitle}>{question.title}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text style={styles.tableCellHead}>Hay Problemas</Text>
              </View>
              <View style={styles.tableColRight}>
                {question.impact !== "" && true && (
                  <Text style={styles.tableCellHead}>Impacto</Text>
                )}
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text style={styles.tableCell}>
                  {question.problem ? 'Si' : 'No'}
                </Text>
              </View>
              <View style={styles.tableColRight}>
                {question.impact !== "" && true && (
                  <Text style={styles.tableCellText}>{question.impact}</Text>
                )}
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text style={styles.tableCellHead}>Es Urgente</Text>
              </View>
              <View style={styles.tableColRight}>
                {question.observation !== "" && true && (
                  <Text style={styles.tableCellHead}>Observaciones</Text>
                )}
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColLeft}>
                <Text style={styles.tableCell}>
                  {question.urgent ? 'Si' : 'No'}
                </Text>
              </View>
              <View style={styles.tableColRight}>
                {question.observation !== "" && true && (
                <Text style={styles.tableCellText}>{question.observation}</Text>
                )}
              </View>
            </View>
          </View>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const Heuristic = props => {
  return (
    <Document>
      <GenericPage>
        <View style={styles.centered}>
          <Text>{props.data.title}</Text>
        </View>
          <Introduction
            fieldData={props.data.introduction}
            showField={props.data.showIntroducction}
          />

        
        <Text style={styles.title}>Relevancia</Text>
        <ListQuestions arr={props.data.formRelevanceQuestions} />
        <Text style={styles.title}>Claridad de diseño</Text>
        <ListQuestions arr={props.data.formDesignQuestions} />
        <Text style={styles.title}>Fricción</Text>
        <ListQuestions arr={props.data.formFrictionQuestions} />
        <Text style={styles.title}>Distracciones</Text>
        <ListQuestions arr={props.data.formDistractionsQuestions} />
        <Text style={styles.title}>Motivación e incentivos</Text>
        <ListQuestions arr={props.data.formMotivationQuestions} />
        <Text style={styles.title}>Ciclo de Compras</Text>
        <ListQuestions arr={props.data.formSalesQuestions} />

        <Introduction
          fieldData={props.data.conclusion}
          showField={props.data.showConclusion}
        />
      </GenericPage>
    </Document>
  );
};
