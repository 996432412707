import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';

const ConfirmModal = ({
  title,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  buttonColor,
  isVisible,
  setIsVisible,
  children
}) => {
  return (
    <Fragment>
      {isVisible ? (
        <EuiOverlayMask>
          <EuiConfirmModal
            title={title}
            onCancel={() => setIsVisible(false)}
            onConfirm={onConfirm}
            cancelButtonText={cancelButtonText || 'Cancelar'}
            confirmButtonText={confirmButtonText || 'Aceptar'}
            buttonColor={buttonColor || 'danger'}
            defaultFocusedButton="confirm"
          >
            {children || null}
          </EuiConfirmModal>
        </EuiOverlayMask>
      ) : null}
    </Fragment>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.any.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  buttonColor: PropTypes.string,
  children: PropTypes.any
};

export { ConfirmModal };
