import { EuiSpacer, EuiTitle } from '@elastic/eui';
import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { templates } from '../data/templates';

export const Template = ({ match }) => {
  const templateId = match.params.id;
  const template = templates.find(template => template.key === templateId);

  if (!template) return <Redirect to="/templates" />;

  return (
    <Fragment>
      <style>
        {`.main-content, .container.pt-4 { background-color: #fff }`}
      </style>
      <div className="container pt-4">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="template-container">
              <template.component template={template} />
            </div>
          </div>
          <div className="col-xs-12 col-md-4 right-section notPrint">
            <EuiTitle>
              <h2>{template.name}</h2>
            </EuiTitle>
            <EuiSpacer />
            <p className="text">{template.longDescription}</p>
            <EuiSpacer />
            <EuiTitle className="subtittle">
              <h3>Instrucciones</h3>
            </EuiTitle>
            <EuiSpacer />
            <div dangerouslySetInnerHTML={{ __html: template.instructions }} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
