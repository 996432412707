import {
  EuiFlexItem,
  EuiGlobalToastList,
  EuiPageContentHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
  htmlIdGenerator
} from '@elastic/eui';
import React, { useState } from 'react';
import { storage } from '../../config/env';
import { read, remove, write } from '../../services/storage';
import { ConfirmModal } from '../utils/ConfirmModal';
import { CustomInput } from '../utils/CustomInput';
import { SingleInputDynamicList } from '../utils/dynamic-lists/SingleInputDynamicList';
import { EditableTitle } from '../utils/EditableTitle';
import { TemplateBottomBar } from '../utils/TemplateBottomBar';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';

const labels = {
  titleDesc:
    'Determina tu territorio, aterriza quienes son tus competidores y otros territorios complementarios',
  yourTerritory: 'Tu territorio',
  yourCompetitors: 'Tus competidores',
  otherTerritories: 'Otros territorios complementarios',
  addTerritory: 'Agregar territorio',
  secondSectionDesc:
    'Haz un ejercicio de pensamiento estratégico para determinar con qué marcas y profesionales podrías colaborar.',
  yourBrand: 'Tu marca',
  possibleCollaborators: 'Posibles colaboradores',
  addCollaborator: 'Agregar colaborador',
  resetModalTitle: (
    <p style={{ textAlign: 'center' }}>
      ¿Deseas reiniciar todos
      <br />
      los campos del formulario?
    </p>
  )
};

const makeId = htmlIdGenerator();

const PureTemplateCollaboration = props => {
  const localData = read(props.template.key) || {};

  const [title, setTitle] = useState(localData.title || props.template.name);
  const [territory, setTerritory] = useState(localData.territory || '');
  const [competitors, setCompetitors] = useState(localData.competitors || '');
  const [otherTerritories, setOtherTerritories] = useState(
    localData.otherTerritories || [
      { key: makeId(), value: '', disabled: false }
    ]
  );
  const [brand, setBrand] = useState(localData.brand || '');
  const [collaborators, setCollaborators] = useState(
    localData.collaborators || [{ key: makeId(), value: '', disabled: false }]
  );
  const [toasts, setToasts] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [introduction, setIntroduction] = useState(localData.introduction || '');
  const [showIntroduction, setShowIntroduction] = useState(
    localData.showIntroduction !== undefined
      ? localData.showIntroduction
      : false
  );
  const [conclusion, setConclusion] = useState(localData.conclusion || '');
  const [showConclusion, setShowConclusion] = useState(
    localData.showConclusion !== undefined
      ? localData.showConclusion
      : false
  );

  const saveData = () => {
    const data = {
      title,
      territory,
      competitors,
      otherTerritories,
      brand,
      collaborators,
      introduction,
      showIntroduction,
      conclusion,
      showConclusion,
    };
    write(props.template.key, data);
    const toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const resetData = () => {
    setTitle(props.template.name);
    setTerritory('');
    setCompetitors('');
    setOtherTerritories([{ key: makeId(), value: '', disabled: false }]);
    setBrand('');
    setCollaborators([{ key: makeId(), value: '', disabled: false }]);
    setShowConfirmModal(false);
    setIntroduction('');
    setShowIntroduction(false);
    setConclusion('');
    setShowConclusion(false);
    remove(props.template.key);
    const toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const makePdf = () => {
    const data = {
      title,
      territory,
      competitors,
      otherTerritories,
      brand,
      collaborators,
      introduction,
      showIntroduction,
      conclusion,
      showConclusion,
      template: props.template.key
    };
    write(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const confirmModal = (
    <ConfirmModal
      title={labels.resetModalTitle}
      isVisible={showConfirmModal}
      setIsVisible={setShowConfirmModal}
      onConfirm={resetData}
    />
  );

  return (
    <EuiFlexItem className="page-template collaboration" id="pageTemplate">
      <EuiPageContentHeader style={{ justifyContent: 'center' }}>
        <EditableTitle title={title} setTitle={setTitle} />
      </EuiPageContentHeader>
      <EuiText size="s" color="subdued" textAlign="center">
        {labels.titleDesc}
      </EuiText>
      <EuiSpacer size="l" />
      <ToggleableTextBlock
        content={introduction}
        setContent={setIntroduction}
        showContent={showIntroduction}
        setShowContent={setShowIntroduction}
        buttonLabel="Agregar texto de introducción"
      />
      <EuiSpacer size="l" />

      

      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div>
            <EuiTitle size="s">
              <span className="text-subdued">{labels.yourTerritory}</span>
            </EuiTitle>
            <CustomInput onChangeHandler={setTerritory} value={territory} />
          </div>
          <EuiSpacer />
          <div>
            <EuiTitle size="s">
              <span className="text-subdued">{labels.yourCompetitors}</span>
            </EuiTitle>
            <CustomInput onChangeHandler={setCompetitors} value={competitors} />
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <EuiTitle size="s">
            <span className="text-subdued">{labels.otherTerritories}</span>
          </EuiTitle>
          <SingleInputDynamicList
            items={otherTerritories}
            setItems={setOtherTerritories}
            hideShow={true}
            addButtonLabel={labels.addTerritory}
          />
        </div>
      </div>

      <EuiSpacer size="xxl" />

      <EuiText size="s" color="subdued">
        {labels.secondSectionDesc}
      </EuiText>
      <EuiSpacer />
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <EuiTitle size="s">
            <span className="text-subdued">{labels.yourBrand}</span>
          </EuiTitle>
          <CustomInput onChangeHandler={setBrand} value={brand} />
        </div>
        <div className="col-xs-12 col-md-6">
          <EuiTitle size="s">
            <span className="text-subdued">{labels.possibleCollaborators}</span>
          </EuiTitle>
          <SingleInputDynamicList
            items={collaborators}
            setItems={setCollaborators}
            hideShow={true}
            addButtonLabel={labels.addCollaborator}
          />
        </div>
      </div>

      <EuiSpacer size="l" />

      <ToggleableTextBlock
          content={conclusion}
          setContent={setConclusion}
          showContent={showConclusion}
          setShowContent={setShowConclusion}
          buttonLabel="Agregar bloque de texto"
        />

      <TemplateBottomBar
        templateName={props.template.name}
        saveDataFunction={saveData}
        generatePdfFunction={makePdf}
        toggleEditableTitles={[]}
        setResetModal={setShowConfirmModal}
      />
      {confirmModal}
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </EuiFlexItem>
  );
};

const TemplateCollaboration = React.memo(PureTemplateCollaboration);

export { TemplateCollaboration };
