import { EuiFieldNumber } from '@elastic/eui';
import React, { useState } from 'react';
import numeral from 'numeral';

export const CurrencyInput = props => {
  const [isEditing, setIsEditing] = useState(false);
  const onChange = event => {
    props.onChange(event.target.value);
  };

  const toCurrency = number => numeral(number).format('0,0.00');

  const toggleEditing = () => {
    // this.setState({ isEditing: !this.state.isEditing });
    setIsEditing(!isEditing);
  };
  return (
    <>
      <div>
        {isEditing ? (
          <EuiFieldNumber
            className="number-field"
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            readOnly={props.readOnly}
            onChange={e => onChange(e)}
            onBlur={e => toggleEditing(e)}
          />
        ) : (
          <EuiFieldNumber
            className="number-field"
            placeholder={props.placeholder}
            type="text"
            name={props.name}
            readOnly={props.readOnly}
            value={props.value === '' ? props.value : toCurrency(props.value)}
            onFocus={e => toggleEditing(e)}
            onChange={() => null}
          />
        )}
      </div>
    </>
  );
};
