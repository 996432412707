import {
  EuiBottomBar,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText
} from '@elastic/eui';
import React from 'react';
import PropTypes from 'prop-types';
import { EuiCustomLink } from './EuiCustomLink';

const TemplateBottomBar = ({
  templateName,
  saveDataFunction,
  generatePdfFunction,
  toggleEditableTitles,
  setResetModal
}) => {
  return (
    <EuiBottomBar className="notPrint">
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiText>
                <EuiCustomLink to="/templates">
                  Todas las Plantillas
                </EuiCustomLink>{' '}
                /
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>{templateName}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                color="ghost"
                size="s"
                iconType="refresh"
                onClick={() => {
                  toggleEditableTitles.forEach(setTitle => setTitle(false));
                  setResetModal(true);
                }}
              >
                Resetear
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                color="ghost"
                size="s"
                iconType="save"
                onClick={() => {
                  toggleEditableTitles.forEach(setTitle => setTitle(false));
                  saveDataFunction();
                }}
              >
                Guardar
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                color="primary"
                fill
                size="s"
                iconType="check"
                onClick={() => {
                  if (toggleEditableTitles && toggleEditableTitles.length > 0)
                    toggleEditableTitles.forEach(setTitle => setTitle(false));
                  generatePdfFunction();
                }}
              >
                Imprimir
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiBottomBar>
  );
};

TemplateBottomBar.propTypes = {
  templateName: PropTypes.string.isRequired,
  saveDataFunction: PropTypes.func.isRequired,
  generatePdfFunction: PropTypes.func.isRequired,
  toggleEditableTitles: PropTypes.array,
  setResetModal: PropTypes.func.isRequired
};

export { TemplateBottomBar };
