import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  spacer: {
    padding: 16
  },
  spacerS: {
    padding: 8
  },
  spacerXs: {
    padding: 4
  }
});

export const Spacer = () => <View style={styles.spacer} />;

export const SpacerS = () => <View style={styles.spacerS} />;

export const SpacerXs = () => <View style={styles.spacerXs} />;
