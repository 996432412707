export const app = {
  title: 'Más Plantillas',
  helpUrl: '#',
  env: 'production'
};

export const api = {
  baseUrl: app.env === 'production'
    ? 'https://bi.conviertemas.com/wp-json'
    : 'https://masmarketerstg.wpengine.com/wp-json',
  login: '/templates/v1/tokens'
};

export const login = {
  errorMessage: 'Has ingresado datos no válidos',
  loginLabel: 'INICIAR SESIÓN',
  recoverPasswordLabel: '¿Olvidaste la contraseña?',
  recoverPasswordLink:
    app.env === 'development'
      ? 'https://masmarketerstg.wpengine.com/acceder/?action=lostpassword'
      : 'https://bi.conviertemas.com/acceder/?action=lostpassword',
  logoutLabel: 'Cerrar Sesión'
};

export const storage = {
  token: 'token',
  authUser: 'auth-user',
  templateData: 'template-data',
  pdfTemplateData: 'pdf-template-data'
};

export const utils = {
  enterKey: 'ENTER',
  logoSize: 164
};

export const style = {
  pageBackgroundColor: '#f4f4f4'
};
