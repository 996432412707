import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiToolTip,
  htmlIdGenerator
} from '@elastic/eui';

const PureUpsellDynamicList = ({
  items,
  setItems,
  placeholders,
  hideInputTooltipText,
  deleteInputTooltipText,
  helpInputTooltipText,
  addButtonLabel,
  setShowModal
}) => {
  const makeId = htmlIdGenerator();

  const hideTooltip = hideInputTooltipText || 'Activar/Desactivar control';
  const deleteTooltip = deleteInputTooltipText || 'Borrar control';
  const helpTooltip = helpInputTooltipText || 'Ayuda';
  const addLabel = addButtonLabel || 'Agregar control';

  const handleOnChange = (e, item, idx) => {
    const newItems = [...items];
    newItems[item].values[idx] = e.target.value;
    setItems(newItems);
  };

  const addItem = () => {
    const newItems = [...items];
    newItems.push({ key: makeId(), values: ['', '', '', ''], disabled: false });
    setItems(newItems);
  };

  const deleteItem = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const toggleItem = index => {
    const newItems = [...items];
    newItems[index].disabled = !newItems[index].disabled;
    setItems(newItems);
  };

  const helpTooltipMarkup = (
    <EuiToolTip position="top" content={helpTooltip}>
      <EuiButtonIcon
        iconType="questionInCircle"
        aria-label="Ayuda"
        onClick={() => setShowModal(true)}
      />
    </EuiToolTip>
  );

  return (
    <div style={{ width: '100%' }}>
      {items.map((item, index) => {
        return (
          <Fragment key={item.key}>
            <EuiFlexGroup>
              <EuiFlexItem grow={3}>
                <EuiFieldText
                  fullWidth
                  placeholder={placeholders[0]}
                  value={item.values[0]}
                  onChange={e => handleOnChange(e, index, 0)}
                  disabled={item.disabled}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiFieldNumber
                  fullWidth
                  placeholder={placeholders[1]}
                  value={item.values[1]}
                  onChange={e => handleOnChange(e, index, 1)}
                  disabled={item.disabled}
                  step={0.01}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiFieldNumber
                  fullWidth
                  placeholder={placeholders[2]}
                  value={item.values[2]}
                  onChange={e => handleOnChange(e, index, 2)}
                  disabled={item.disabled}
                  step={0.01}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={3}>
                <EuiFieldText
                  fullWidth
                  placeholder={placeholders[3]}
                  value={item.values[3]}
                  onChange={e => handleOnChange(e, index, 3)}
                  disabled={item.disabled}
                  append={
                    <Fragment>
                      {items.length > 1 ? (
                        <Fragment>
                          {helpTooltipMarkup}
                          <EuiToolTip position="top" content={hideTooltip}>
                            <EuiButtonIcon
                              iconType="eyeClosed"
                              aria-label="Ocultar"
                              onClick={() => toggleItem(index)}
                            />
                          </EuiToolTip>
                          <EuiToolTip position="top" content={deleteTooltip}>
                            <EuiButtonIcon
                              iconType="trash"
                              aria-label="Borrar"
                              onClick={() => deleteItem(index)}
                            />
                          </EuiToolTip>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {helpTooltipMarkup}
                        </Fragment>
                      )}
                    </Fragment>
                  }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="s" />
          </Fragment>
        );
      })}
      <EuiSpacer className="notPrint" size="s" />
      <EuiButtonEmpty className="notPrint" onClick={e => addItem()} size="xs">
        <EuiIcon type="plusInCircle" />
        &nbsp;{addLabel}
      </EuiButtonEmpty>
    </div>
  );
};

PureUpsellDynamicList.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  placeholders: PropTypes.array,
  hideInputTooltipText: PropTypes.string,
  deleteInputTooltipText: PropTypes.string
};

const UpsellDynamicList = React.memo(PureUpsellDynamicList);

export { UpsellDynamicList };
