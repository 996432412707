import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  centeredTitle: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textAlign: 'center',
    marginHorizontal: 'auto',
    width: '100%'
  }
});

const Title = React.memo(props => (
  <Text style={props.centered ? styles.centeredTitle : styles.title}>
    {props.title}
  </Text>
));

Title.propTypes = {
  title: PropTypes.string.isRequired,
  centered: PropTypes.bool
};

export { Title };
