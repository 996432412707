import React from 'react';
import {
  write as setData,
  read as getData,
  remove as removeData
} from '../../services/storage';
import {
  EuiFlexItem,
  EuiSpacer,
  EuiPageContentHeader,
  EuiButton,
  EuiFlexGrid,
  EuiFormRow,
  EuiText,
  EuiLink,
  EuiIcon,
  EuiFieldText,
  EuiGlobalToastList,
  EuiOverlayMask,
  EuiConfirmModal,
  EuiToolTip
} from '@elastic/eui';
import { CustomInput } from '../utils/CustomInput';
import { EditableTitle } from '../utils/EditableTitle';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';
import { storage } from '../../config/env';
import { TemplateBottomBar } from '../utils/TemplateBottomBar';

const defaultCategories = [
  { name: 'Autores', placeholder: 'Ej: Robert Kiyosaky' },
  { name: 'Libros', placeholder: 'Ej: Padre Rico, Padre Pobre' },
  {
    name: 'Celebridades/Figuras públicas',
    placeholder: 'Ej: Grant Cardone'
  },
  { name: 'Competidores', placeholder: 'Ej: Luis Eduardo Barón' },
  { name: 'Cargos', placeholder: 'Ej: Managing director' },
  { name: 'Revistas', placeholder: 'Ej: Revista Time' },
  { name: 'Webs/Blogs', placeholder: 'Ej: marketingdirecto.com' },
  { name: 'Intereses generales', placeholder: 'Ej: Publicidad online' },
  { name: 'Marcas', placeholder: 'Ej: WeWork' },
  { name: 'Tiendas/Lugares físicos', placeholder: 'Ej: WeWork' },
  { name: 'Asociaciones/Organizaciones/Clubes', placeholder: '' },
  {
    name: 'Intereses específicos',
    placeholder: 'Ej: Agencia de publicidad'
  },
  {
    name: 'Datos demográficos en general',
    placeholder: 'Ej: Educación, relación, etc'
  },
  {
    name: 'Comportamientos',
    placeholder: 'Ej: Compradores que interactuaron'
  },
  {
    name: 'Nichos - Sectores [Negocios e industrias]',
    placeholder: 'Ej: Arquitectura'
  },
  {
    name: 'Escuelas/Universidades',
    placeholder: 'Ej: Universidad Valencia'
  },
  { name: 'Hobbies', placeholder: 'Ej: Professional Golfer' },
  { name: 'Programas TV/Series/Películas', placeholder: 'Ej: Mad Men' },
  {
    name: 'Radios y podcasts',
    placeholder: 'Ej: The Digital Marketing Podcast'
  },
  { name: 'Software/Herramientas', placeholder: 'Ej: Hubspot' },
  { name: 'Campos de estudio', placeholder: 'Ej: Medicina veterinaria' },
  { name: 'Foros', placeholder: 'Ej: Redit' },
  { name: 'Páginas de fans', placeholder: 'Ej: Tony Robbins' },
  {
    name: 'Eventos/Conferencias/Festivales',
    placeholder: 'Ej: Festival Exit'
  }
];

export class TemplateSegmentation extends React.Component {
  constructor(props) {
    super(props);

    this.localData = getData(props.template.key) || {};
    this.blankBox = { title: '', body: '', disabled: false, show: true };

    this.defaultCategoriesConfig = defaultCategories.map(cat => ({
      title: cat.name,
      body: '',
      disabled: false
    }));

    this.initialState = {
      title: this.localData.title || props.template.name,
      introduction: this.localData.introduction || {
        show: false,
        value: ''
      },
      conclusion: this.localData.conclusion || {
        show: false,
        value: ''
      },
      avatar: this.localData.avatar || '',
      brainstorming: this.localData.brainstorming || '',
      rainInterests: this.localData.rainInterests || '',
      additionalCategories:
        this.localData.additionalCategories || this.defaultCategoriesConfig,
      toasts: [],
      isModalVisible: false,
      isResetModalVisible: false,
      selectedIndex: -1
    };

    this.state = this.initialState;

    this.blankState = {
      ...this.state,
      title: props.template.name,
      introduction: { show: false, value: '' },
      conclusion: { show: false, value: '' },
      avatar: '',
      brainstorming: '',
      rainInterests: '',
      additionalCategories: this.defaultCategoriesConfig
    };

    this.setTitle = this.setTitle.bind(this);
    this.setIntroduction = this.setIntroduction.bind(this);
    this.setShowIntroduction = this.setShowIntroduction.bind(this);
    this.setConclusion = this.setConclusion.bind(this);
    this.setShowConclusion = this.setShowConclusion.bind(this);
    this.setAvatar = this.setAvatar.bind(this);
    this.setBrainstorming = this.setBrainstorming.bind(this);
    this.setRainInterests = this.setRainInterests.bind(this);
    this.setCategoryValue = this.setCategoryValue.bind(this);
    this.setCategoryTitle = this.setCategoryTitle.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.setIsResetModalVisible = this.setIsResetModalVisible.bind(this);
    this.setIsModalVisible = this.setIsModalVisible.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.resetData = this.resetData.bind(this);
    this.makePdf = this.makePdf.bind(this);
    this.removeToast = this.removeToast.bind(this);
  }

  setTitle(e) {
    this.setState({ title: e });
  }

  setIntroduction(e) {
    this.setState({
      introduction: { ...this.state.introduction, value: e }
    });
  }
  setShowIntroduction(e) {
    this.setState({
      introduction: { ...this.state.introduction, show: e }
    });
  }
  setConclusion(e) {
    this.setState({
      conclusion: { ...this.state.conclusion, value: e }
    });
  }
  setShowConclusion(e) {
    this.setState({
      conclusion: { ...this.state.conclusion, show: e }
    });
  }

  setAvatar(e) {
    this.setState({ avatar: e });
  }

  setBrainstorming(e) {
    this.setState({ brainstorming: e });
  }

  setRainInterests(e) {
    this.setState({ rainInterests: e });
  }

  setCategoryTitle(index, e) {
    const categories = [...this.state.additionalCategories];
    categories[index].title = e.target.value;
    this.setState({ additionalCategories: categories });
  }

  setCategoryValue(index, e) {
    const categories = [...this.state.additionalCategories];
    categories[index].body = e;
    this.setState({ additionalCategories: categories });
  }

  toggleField(index) {
    const categories = [...this.state.additionalCategories];
    categories[index].disabled = !categories[index].disabled;
    this.setState({ additionalCategories: categories });
  }

  setToasts(value) {
    this.setState({ toasts: value });
  }

  addCategory() {
    this.setState({
      additionalCategories: [...this.state.additionalCategories, this.blankBox]
    });
  }

  setIsModalVisible(value) {
    this.setState({
      isModalVisible: value
    });
  }

  setIsResetModalVisible(value) {
    this.setState({
      isResetModalVisible: value
    });
  }

  showConfirmModal(index) {
    this.setState({ isModalVisible: true, selectedIndex: index });
  }

  closeModal() {
    const categories = [...this.state.additionalCategories].filter(
      (_, idx) => idx !== this.state.selectedIndex
    );
    this.setState({
      additionalCategories: categories,
      isModalVisible: false
    });
  }

  removeToast(removedToast) {
    const toasts = [...this.state.toasts];
    this.setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  }

  makePdf() {
    const data = {
      title: this.state.title,
      introduction: this.state.introduction,
      conclusion: this.state.conclusion,
      avatar: this.state.avatar,
      brainstorming: this.state.brainstorming,
      rainInterests: this.state.rainInterests,
      additionalCategories: this.state.additionalCategories,
      template: this.props.template.key
    };
    setData(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  }

  saveData() {
    const data = {
      title: this.state.title,
      introduction: this.state.introduction,
      conclusion: this.state.conclusion,
      avatar: this.state.avatar,
      brainstorming: this.state.brainstorming,
      rainInterests: this.state.rainInterests,
      additionalCategories: this.state.additionalCategories
    };
    setData(this.props.template.key, data);
    let toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    this.setToasts([...this.state.toasts, toast]);
  }

  resetData() {
    removeData(this.props.template.key);
    let toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    this.setState({
      ...this.blankState,
      toasts: [...this.state.toasts, toast]
    });
  }

  render() {
    let modal = null;
    let resetModal = null;

    if (this.state.isModalVisible) {
      modal = (
        <EuiOverlayMask>
          <EuiConfirmModal
            title={
              <p style={{ textAlign: 'center' }}>
                ¿Deseas borrar esta sección?
              </p>
            }
            onCancel={() => this.setIsModalVisible(false)}
            onConfirm={this.closeModal}
            cancelButtonText="Cancelar"
            confirmButtonText="Sí, borrar sección"
            defaultFocusedButton="confirm"
            buttonColor="danger"
          >
            <p>Esta acción no se puede deshacer</p>
          </EuiConfirmModal>
        </EuiOverlayMask>
      );
    }

    if (this.state.isResetModalVisible) {
      resetModal = (
        <EuiOverlayMask>
          <EuiConfirmModal
            title={
              <p style={{ textAlign: 'center' }}>
                ¿Deseas reiniciar todos
                <br />
                los campos del formulario?
              </p>
            }
            onCancel={() => this.setIsResetModalVisible(false)}
            onConfirm={() => {
              this.setIsResetModalVisible(false);
              this.resetData();
            }}
            cancelButtonText="Cancelar"
            confirmButtonText="Sí, reiniciar formulario"
            defaultFocusedButton="confirm"
            buttonColor="danger"
          >
            <p>Esta acción no se puede deshacer</p>
          </EuiConfirmModal>
        </EuiOverlayMask>
      );
    }

    return (
      <React.Fragment>
        <EuiFlexItem className="page-template segmentation" id="pageTemplate">
          <EuiPageContentHeader style={{ justifyContent: 'center' }}>
            <EditableTitle title={this.state.title} setTitle={this.setTitle} />
          </EuiPageContentHeader>

          <EuiSpacer size="xxl" />

          <ToggleableTextBlock
            content={this.state.introduction.value}
            setContent={this.setIntroduction}
            showContent={this.state.introduction.show}
            setShowContent={this.setShowIntroduction}
            buttonLabel="Agregar texto de introducción"
          />

          <EuiFormRow
            label="Avatar"
            helpText="Describe el cliente ideal para tu producto o servicio"
            fullWidth
          >
            <CustomInput
              placeholder="Personas ocupadas con mucho estrés, poca planificación, poco tiempo para ocio"
              value={this.state.avatar}
              onChangeHandler={this.setAvatar}
              isFullWidth={true}
            />
          </EuiFormRow>

          <EuiFormRow
            label="Lluvia de ideas de palabras clave"
            helpText="Ideas de palabras claves que obtuviste de la investigación (separadas por coma)"
            fullWidth
          >
            <CustomInput
              isFullWidth={true}
              placeholder="estrés, miedo, insomnio, autoestima, ansiedad"
              value={this.state.brainstorming}
              onChangeHandler={this.setBrainstorming}
            />
          </EuiFormRow>

          <EuiFormRow
            label="Lluvia de ideas de intereses"
            helpText="Intereses que obtuviste de la investigación (separadas por coma)"
            fullWidth
          >
            <CustomInput
              isFullWidth={true}
              placeholder="Control del estrés, Robin Sharma, Estrés, Desarrollo personal, Autoestima, Autorrealización, Calidad de Vida"
              value={this.state.rainInterests}
              onChangeHandler={this.setRainInterests}
            />
          </EuiFormRow>

          {this.state.additionalCategories.length > 0 ||
          this.state.additionalCategories.some(cat => cat.show) ? (
            <h3 className="subTitle">Intereses agrupados por categorías</h3>
          ) : null}

          <EuiSpacer size="l" />

          {this.state.additionalCategories.map((category, index) => (
            <div key={index} className="section-dinamics">
              <EuiFormRow
                className={`title-box ${category.disabled ? 'disabled' : ''}`}
              >
                <EuiFieldText
                  className="label"
                  placeholder="Escriba el título"
                  value={category.title}
                  onChange={this.setCategoryTitle.bind(this, index)}
                />
              </EuiFormRow>
              <EuiFormRow
                className={`title-box ${category.disabled ? 'disabled' : ''}`}
                key={index}
                fullWidth
                labelAppend={
                  <EuiText size="xs">
                    <EuiLink
                      className="ml-2"
                      onClick={this.showConfirmModal.bind(this, index)}
                    >
                      <EuiIcon type="trash" />
                    </EuiLink>
                    <EuiLink onClick={this.toggleField.bind(this, index)}>
                      <EuiToolTip
                        position="top"
                        content={`${
                          category.disabled ? 'Muestra' : 'Esconde'
                        } este bloque en el documento generado`}
                      >
                        <EuiIcon
                          type={`${category.disabled ? 'eye' : 'eyeClosed'}`}
                        />
                      </EuiToolTip>
                    </EuiLink>
                  </EuiText>
                }
              >
                <CustomInput
                  index={index}
                  placeholder="Ej. Gary Vaynerchuk"
                  value={category.body}
                  onChangeHandler={this.setCategoryValue.bind(this, index)}
                  isFullWidth={true}
                />
              </EuiFormRow>
              <EuiSpacer size="l" />
            </div>
          ))}

          <EuiSpacer size="l" />

          <EuiFlexGrid className="content-button justify-content-center">
            <EuiFlexItem columns={2}>
              <EuiButton className="notPrint" onClick={this.addCategory}>
                Agregar una nueva categoría
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGrid>

          <EuiSpacer size="l" />

          <ToggleableTextBlock
            content={this.state.conclusion.value}
            setContent={this.setConclusion}
            showContent={this.state.conclusion.show}
            setShowContent={this.setShowConclusion}
            buttonLabel="Agregar texto de conclusión"
          />
        </EuiFlexItem>

        
        {modal}
        {resetModal}
        <TemplateBottomBar
          templateName={this.props.template.name}
          saveDataFunction={this.saveData}
          generatePdfFunction={this.makePdf}
          toggleEditableTitles={[]}
          setResetModal={this.setIsResetModalVisible}
        />
        <EuiGlobalToastList
          toasts={this.state.toasts}
          dismissToast={this.removeToast}
          toastLifeTimeMs={3000}
        />
      </React.Fragment>
    );
  }
}
