import React from 'react';
import { Document } from '@react-pdf/renderer';
import {
  GenericPage,
  TitleSection,
  Introduction,
  DescribedText,
  Title,
  SpacerS
} from './utils';

export const Segmentation = props => {
  const { data } = props;

  const firstSection = [
    {
      label: 'Avatar',
      content: data.avatar,
      description: 'Describe el cliente ideal para tu producto o servicio',
      showAsTags: false
    },
    {
      label: 'Lluvia de ideas de palabras clave',
      content: data.brainstorming,
      description:
        'Ideas de palabras claves que obtuviste de la investigación (separados por coma)',
      showAsTags: true
    },
    {
      label: 'Lluvia de ideas de intereses',
      content: data.rainInterests,
      description:
        'Intereses que obtuviste de la investigación (separados por coma)',
      showAsTags: true
    }
  ];

  return (
    <Document>
      <GenericPage>
        <TitleSection title={data.title} />
        <Introduction
          showField={data.introduction.show}
          fieldData={data.introduction.value}
        />
        {firstSection.map((item, index) => (
          <DescribedText
            key={index}
            title={item.label}
            content={item.content}
            description={item.description}
            // isContentTags={item.showAsTags}
          />
        ))}
        <Title title={`Intereses agrupados por categorías`} centered />
        <SpacerS />
        {data.additionalCategories &&
          data.additionalCategories.length > 0 &&
          data.additionalCategories
            .filter(cat => !cat.disabled)
            .map((item, index) => (
              <DescribedText
                key={index}
                title={item.title}
                content={item.body}
                // isContentTags={true}
              />
            ))}
        <Introduction
          showField={data.conclusion.show}
          fieldData={data.conclusion.value}
        />
      </GenericPage>
    </Document>
  );
};
