import {
  EuiButton,
  EuiFlexItem,
  EuiGlobalToastList,
  EuiPageContentHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
  htmlIdGenerator
} from '@elastic/eui';
import React from 'react';
import { read, remove, write } from '../../services/storage';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';
import { CustomInput } from '../utils/CustomInput';
import { EditableTitle } from '../utils/EditableTitle';
import { TemplateBottomBar } from '../utils/TemplateBottomBar';
import { ConfirmModal } from '../utils/ConfirmModal';
import { storage } from '../../config/env';
import { CompetitorsDynamicList } from '../utils/dynamic-lists/CompetitorsDynamicList';
import { DafoDynamicList } from '../utils/dynamic-lists/DafoDynamicList';

class TemplateCompetitors extends React.Component {
  constructor(props) {
    super(props);
    this.localData = read(props.template.key) || {};
    this.state = {
      title: this.localData.title || props.template.name,
      competitors: this.localData.competitors || [],
      objective: this.localData.objective || '',
      overview: this.localData.overview || [''],
      competitiveAdvantage: this.localData.competitiveAdvantage || [''],
      targetAudience: this.localData.targetAudience || [''],
      marketingStrategies: this.localData.marketingStrategies || [''],
      productsServices: this.localData.productsServices || [''],
      pricing: this.localData.pricing || [''],
      distributionChannels: this.localData.distributionChannels || [''],
      dafo: this.localData.dafo || [['', '', '', '']],
      showConfirmModal: false,
      showConfirmDeleteCompetitorModal: false,
      competitorIndex: -1,
      toasts: [],
      introduction: this.localData.introduction || {
        show: false,
        value: ''
      },
      conclusion: this.localData.conclusion || {
        show: false,
        value: ''
      },
    };
    this.makeId = htmlIdGenerator();
    this.labels = {
      objective: 'Objetivo',
      objectiveDesc:
        'Escribe aquí la principal pregunta que estás tratando de responder con este análisis',
      you: 'Tú',
      competitor: 'Competidor',
      dafoTableHeaders: [
        'Fortalezas',
        'Debilidades',
        'Oportunidades',
        'Amenazas'
      ],
      dafoAnalysis: 'Análisis DAFO',
      dafoAnalysisDesc:
        'Haz esto para ti y tus competidores. Tus fortalezas deben respaldar tus oportunidades y contribuir a lo que te defina como tu ventaja competitiva.',
      business: 'Negocio',
      overview: 'Overview',
      competitiveAdvantage: 'Ventaja competitiva',
      competitiveAdvantageDesc: '¿Qué valor ofreces a tus clientes?',
      marketing: 'Marketing',
      targetAudience: 'Público objetivo',
      marketingStrategies: 'Estrategias de marketing',
      product: 'Producto',
      productsServices: 'Productos y servicios',
      pricing: 'Precio y costes',
      distributionChannels: 'Canales de distribución',
      removeCompetitor: 'Eliminar competidor',
      resetModalTitle: (
        <p style={{ textAlign: 'center' }}>
          ¿Deseas reiniciar todos
          <br />
          los campos del formulario?
        </p>
      ),
      removeCompetitorModalTitle: (
        <p style={{ textAlign: 'center' }}>
          ¿Deseas eliminar al competidor seleccionado?
        </p>
      ),
      removeCompetitorModalDesc: (
        <p style={{ textAlign: 'center' }}>
          <small>
            Todos los datos ingresados que estén relacionados a dicho competidor
            se perderán permanentemente
          </small>
        </p>
      )
    };

    this.setMultiple = this.setMultiple.bind(this);
    this.setBidimensionalMultiple = this.setBidimensionalMultiple.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setCompetitors = this.setCompetitors.bind(this);
    this.setObjective = this.setObjective.bind(this);
    this.setOverview = this.setOverview.bind(this);
    this.setCompetitiveAdvantage = this.setCompetitiveAdvantage.bind(this);
    this.setTargetAudience = this.setTargetAudience.bind(this);
    this.setMarketingStrategies = this.setMarketingStrategies.bind(this);
    this.setProductsServices = this.setProductsServices.bind(this);
    this.setPricing = this.setPricing.bind(this);
    this.setDistributionChannels = this.setDistributionChannels.bind(this);
    this.setDafo = this.setDafo.bind(this);
    this.setShowConfirmModal = this.setShowConfirmModal.bind(this);
    this.setShowConfirmDeleteCompetitorModal = this.setShowConfirmDeleteCompetitorModal.bind(
      this
    );
    this.setCompetitorIndex = this.setCompetitorIndex.bind(this);
    this.setToasts = this.setToasts.bind(this);
    this.removeCompetitor = this.removeCompetitor.bind(this);
    this.addCompetitor = this.addCompetitor.bind(this);
    this.removeToast = this.removeToast.bind(this);
    this.saveData = this.saveData.bind(this);
    this.resetData = this.resetData.bind(this);
    this.makePdf = this.makePdf.bind(this);
    this.setIntroduction = this.setIntroduction.bind(this);
    this.setShowIntroduction = this.setShowIntroduction.bind(this);
    this.setConclusion = this.setConclusion.bind(this);
    this.setShowConclusion = this.setShowConclusion.bind(this);
  }

  setMultiple(str, idx, arr, setArr) {
    const aux = [...arr];
    aux[idx] = str;
    setArr(aux);
  }

  setIntroduction(e) {
    this.setState({
      introduction: { ...this.state.introduction, value: e }
    });
  }

  setShowIntroduction(e) {
    this.setState({
      introduction: { ...this.state.introduction, show: e }
    });
  }

  setConclusion(e) {
    this.setState({
      conclusion: { ...this.state.conclusion, value: e }
    });
  }

  setShowConclusion(e) {
    this.setState({
      conclusion: { ...this.state.conclusion, show: e }
    });
  }

  setBidimensionalMultiple(str, i, j, arr, setArr) {
    const aux = [...arr];
    aux[i][j] = str;
    setArr(aux);
  }

  setTitle(e) {
    this.setState({ title: e });
  }

  setCompetitors(value = []) {
    this.setState({ competitors: value });
  }

  setObjective(value) {
    this.setState({ objective: value });
  }

  setOverview(value = []) {
    this.setState({ overview: value });
  }

  setCompetitiveAdvantage(value = []) {
    this.setState({ competitiveAdvantage: value });
  }

  setTargetAudience(value = []) {
    this.setState({ targetAudience: value });
  }

  setMarketingStrategies(value = []) {
    this.setState({ marketingStrategies: value });
  }

  setProductsServices(value = []) {
    this.setState({ productsServices: value });
  }

  setPricing(value = []) {
    this.setState({ pricing: value });
  }

  setDistributionChannels(value = []) {
    this.setState({ distributionChannels: value });
  }

  setDafo(value = [['', '', '', '']]) {
    this.setState({ dafo: value });
  }

  setShowConfirmModal(value = false) {
    this.setState({ showConfirmModal: value });
  }

  setShowConfirmDeleteCompetitorModal(value = false) {
    this.setState({ showConfirmDeleteCompetitorModal: value });
  }

  setCompetitorIndex(index = -1) {
    this.setState({ competitorIndex: index });
  }

  setToasts(value = []) {
    this.setState({ toasts: value });
  }

  removeCompetitor() {
    const aux =
      this.state.competitors.findIndex(
        comp => comp === this.state.competitorIndex
      ) + 1;
    const newCompetitors = this.state.competitors.filter(
      comp => comp !== this.state.competitorIndex
    );
    this.setState({
      overview: this.state.overview.filter((_, idx) => idx !== aux),
      competitiveAdvantage: this.state.competitiveAdvantage.filter(
        (_, idx) => idx !== aux
      ),
      targetAudience: this.state.targetAudience.filter((_, idx) => idx !== aux),
      marketingStrategies: this.state.marketingStrategies.filter(
        (_, idx) => idx !== aux
      ),
      productsServices: this.state.productsServices.filter(
        (_, idx) => idx !== aux
      ),
      pricing: this.state.pricing.filter((_, idx) => idx !== aux),
      distributionChannels: this.state.distributionChannels.filter(
        (_, idx) => idx !== aux
      ),
      dafo: this.state.dafo.filter((_, idx) => idx !== aux),
      competitors: newCompetitors,
      showConfirmDeleteCompetitorModal: false
    });
  }

  addCompetitor() {
    this.setState({
      competitors: [...this.state.competitors, this.makeId()],
      dafo: [...this.state.dafo, ['', '', '', '']]
    });
  }

  removeToast(removedToast) {
    this.setToasts(
      this.state.toasts.filter(toast => toast.id !== removedToast.id)
    );
  }

  saveData() {
    const data = {
      competitors: this.state.competitors,
      title: this.state.title,
      objective: this.state.objective,
      overview: this.state.overview,
      competitiveAdvantage: this.state.competitiveAdvantage,
      targetAudience: this.state.targetAudience,
      marketingStrategies: this.state.marketingStrategies,
      productsServices: this.state.productsServices,
      pricing: this.state.pricing,
      distributionChannels: this.state.distributionChannels,
      dafo: this.state.dafo,
      introduction: this.state.introduction,
      conclusion: this.state.conclusion,
    };
    write(this.props.template.key, data);
    const toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    this.setToasts(this.state.toasts.concat(toast));
  }

  resetData() {
    remove(this.props.template.key);
    const toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    this.setState({
      title: this.props.template.name,
      competitors: [],
      objective: '',
      overview: [''],
      competitiveAdvantage: [''],
      targetAudience: [''],
      marketingStrategies: [''],
      productsServices: [''],
      pricing: [''],
      distributionChannels: [''],
      dafo: [['', '', '', '']],
      showConfirmModal: false,
      introduction: {show: false, value: ''},
      conclusion: {show: false, value: ''},
      toasts: this.state.toasts.concat(toast)
    });
  }

  makePdf() {
    const data = {
      competitors: this.state.competitors,
      title: this.state.title,
      objective: this.state.objective,
      overview: this.state.overview,
      competitiveAdvantage: this.state.competitiveAdvantage,
      targetAudience: this.state.targetAudience,
      marketingStrategies: this.state.marketingStrategies,
      productsServices: this.state.productsServices,
      pricing: this.state.pricing,
      distributionChannels: this.state.distributionChannels,
      dafo: this.state.dafo,
      introduction: this.state.introduction,
      conclusion: this.state.conclusion,
      template: this.props.template.key
    };
    write(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  }

  render() {
    const confirmRemoveCompetitorModal = (
      <ConfirmModal
        title={this.labels.removeCompetitorModalTitle}
        isVisible={this.state.showConfirmDeleteCompetitorModal}
        setIsVisible={this.setShowConfirmDeleteCompetitorModal}
        onConfirm={this.removeCompetitor}
      >
        {this.labels.removeCompetitorModalDesc}
      </ConfirmModal>
    );

    const confirmModal = (
      <ConfirmModal
        title={this.labels.resetModalTitle}
        isVisible={this.state.showConfirmModal}
        setIsVisible={this.setShowConfirmModal}
        onConfirm={this.resetData}
      />
    );

    const addCompetitorButton = (
      <EuiButton iconType="plusInCircle" onClick={this.addCompetitor}>
        Agregar competidor
      </EuiButton>
    );

    return (
      <React.Fragment>
        <style>
          {`
            .euiModalHeader__title {
              margin: 0 auto;
            }
          `}
        </style>
        <EuiFlexItem className="page-template competitors" id="pageTemplate">
          <EuiPageContentHeader style={{ justifyContent: 'center' }}>
            <EditableTitle title={this.state.title} setTitle={this.setTitle} />
          </EuiPageContentHeader>
          
          <EuiSpacer size="l" />

          <ToggleableTextBlock
            content={this.state.introduction.value}
            setContent={this.setIntroduction}
            showContent={this.state.introduction.show}
            setShowContent={this.setShowIntroduction}
            buttonLabel="Agregar texto de introducción"
          />

          <EuiSpacer size="l" />

          <EuiTitle size="s">
            <span className="text-subdued">{this.labels.objective}</span>
          </EuiTitle>
          <EuiText size="s" color="subdued">
            {this.labels.objectiveDesc}
          </EuiText>
          <EuiSpacer />
          <CustomInput
            isFullWidth
            value={this.state.objective || ''}
            onChangeHandler={this.setObjective}
          />

          <EuiSpacer size="xxl" />

          <EuiTitle size="s">
            <span className="text-subdued">{this.labels.business}</span>
          </EuiTitle>
          <EuiTitle size="xxs">
            <span className="text-subdued">{this.labels.overview}</span>
          </EuiTitle>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.overview}
            setMultiple={this.setOverview}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />
          <EuiSpacer size="xl" />
          <EuiTitle size="xxs">
            <span className="text-subdued">
              {this.labels.competitiveAdvantage}
            </span>
          </EuiTitle>
          <EuiText size="s" color="subdued">
            {this.labels.competitiveAdvantageDesc}
          </EuiText>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.competitiveAdvantage}
            setMultiple={this.setCompetitiveAdvantage}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />

          <EuiSpacer size="xxl" />

          <EuiTitle size="s">
            <span className="text-subdued">{this.labels.marketing}</span>
          </EuiTitle>
          <EuiTitle size="xxs">
            <span className="text-subdued">{this.labels.targetAudience}</span>
          </EuiTitle>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.targetAudience}
            setMultiple={this.setTargetAudience}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />
          <EuiSpacer size="xl" />
          <EuiTitle size="xxs">
            <span className="text-subdued">
              {this.labels.marketingStrategies}
            </span>
          </EuiTitle>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.marketingStrategies}
            setMultiple={this.setMarketingStrategies}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />

          <EuiSpacer size="xxl" />

          <EuiTitle size="s">
            <span className="text-subdued">{this.labels.product}</span>
          </EuiTitle>
          <EuiTitle size="xxs">
            <span className="text-subdued">{this.labels.productsServices}</span>
          </EuiTitle>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.productsServices}
            setMultiple={this.setProductsServices}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />
          <EuiSpacer size="xl" />
          <EuiTitle size="xxs">
            <span className="text-subdued">{this.labels.pricing}</span>
          </EuiTitle>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.pricing}
            setMultiple={this.setPricing}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />
          <EuiSpacer size="xl" />
          <EuiTitle size="xxs">
            <span className="text-subdued">
              {this.labels.distributionChannels}
            </span>
          </EuiTitle>
          <EuiSpacer />
          <CompetitorsDynamicList
            competitors={this.state.competitors}
            addCompetitorButton={addCompetitorButton}
            multiple={this.state.distributionChannels}
            setMultiple={this.setDistributionChannels}
            onChangeHandler={this.setMultiple}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />

          <EuiSpacer size="xxl" />

          <EuiTitle size="s">
            <span className="text-subdued">{this.labels.dafoAnalysis}</span>
          </EuiTitle>
          <EuiText size="s" color="subdued">
            {this.labels.dafoAnalysisDesc}
          </EuiText>
          <EuiSpacer />
          <DafoDynamicList
            competitors={this.state.competitors}
            multiple={this.state.dafo}
            setMultiple={this.setDafo}
            onChangeHandler={this.setBidimensionalMultiple}
            addCompetitorButton={addCompetitorButton}
            setCompetitorIndex={this.setCompetitorIndex}
            setShowConfirmDeleteCompetitorModal={
              this.setShowConfirmDeleteCompetitorModal
            }
          />

          <EuiSpacer size="xxl" />

          <ToggleableTextBlock
            content={this.state.conclusion.value}
            setContent={this.setConclusion}
            showContent={this.state.conclusion.show}
            setShowContent={this.setShowConclusion}
            buttonLabel="Agregar texto de conclusión"
          />

          <TemplateBottomBar
            templateName={this.props.template.name}
            saveDataFunction={this.saveData}
            generatePdfFunction={this.makePdf}
            setResetModal={this.setShowConfirmModal}
            toggleEditableTitles={[]}
            removeCompetitor={this.removeCompetitor}
          />

          {confirmModal}
          {confirmRemoveCompetitorModal}
          <EuiGlobalToastList
            toasts={this.state.toasts}
            dismissToast={this.removeToast}
            toastLifeTimeMs={3000}
          />
        </EuiFlexItem>
      </React.Fragment>
    );
  }
}

export { TemplateCompetitors };
