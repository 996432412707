import React from 'react';
import {
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link
} from '@react-pdf/renderer';
import {
  DescribedText,
  GenericPage,
  SpacerS,
  TitleSection,
  SpacerXs,
  Introduction
} from './utils';

const labels = {
  domainLabel: {
    label: '¿Cuál dominio estás considerando para tu sitio web?',
    helpText:
      'No olvides verificar la disponibilidad del dominio y de que sea corto y fácil de recordar.'
  },
  skillLabel: {
    label:
      '¿Qué habilidades/temáticas (ej. Belleza, marketing, finanzas, etc) quieres presentar/escribir en tu sitio web?'
  },
  imageLabel: {
    label:
      '¿Qué imagen/estilo quieres proyectar en tu sitio web? (ej. Casual, profesional, disruptivo, serio, etc). '
  },
  arrayColorLabel: {
    label:
      '¿Qué paleta de colores anticipas utilizar en tu sitio web y por qué?'
  },
  sitesLabel: {
    label: '¿Qué otros sitios webs te gustan?'
  },
  articlesSitesLabel: {
    label: 'Para los artículos de tu sitio web:',
    helpTexts: [
      '¿Qué tipo de tono/voz quieres utilizar? (ej. Técnico, educacional, personal, profesional, etc).',
      '¿Qué tipo de categorías y palabras claves piensas utilizar?'
    ]
  },
  incomeLabel: {
    label:
      '¿Anticipas crear una línea de ingresos con tu sitio? si lo harás, describe tu plan.'
  },
  wireframesLabel: {
    label:
      'Por cada página de tu sitio web, establece las razones de los siguientes elementos en cada una: ',
    helpTexts: [
      'Imagen / vídeos ',
      'Llamadas a la acción - dónde y por qué',
      'Información de contacto (de, correo, teléfono, dirección, etc).'
    ]
  }
};

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  col1: {
    width: '100%',
    paddingHorizontal: 8
  },
  col2: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  col3: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  subtitle: {
    fontFamily: 'Poppins',
    fontSize: 12
  },
  content: {
    flexDirection: 'row',
    fontFamily: 'Poppins',
    fontSize: 10,
    marginBottom: 5,
    alignSelf: 's',
    width: '25%'
  },
  content50: {
    flexDirection: 'row',
    fontFamily: 'Poppins',
    fontSize: 10,
    marginBottom: 5,
    alignSelf: 's',
    width: '50%'
  },
  listElement: {
    fontFamily: 'Poppins',
    fontSize: 10,
    marginBottom: 6
  },
  description: {
    fontSize: 10,
    fontFamily: 'Poppins'
  }
});

const SingleTextList = props => (
  <React.Fragment>
    <Text style={styles.subtitle}>{props.title}</Text>
    <SpacerXs />
    {props.items.map((item, idx) => (
      <React.Fragment key={idx}>
        <View style={styles.col3}>
          <View style={styles.content}>
            <Text>{item.description}</Text>
          </View>
          <View style={styles.content}>
            <Text
              style={{
                float: 'right',
                backgroundColor: item.color,
                minWidth: 15,
                minHeight: 15,
                maxWidth: 15,
                borderRadius: 5,
                marginRight: 10
              }}
            ></Text>
            <Text>{item.color}</Text>
          </View>
          <View style={styles.content}>
            <Text>{item.use}</Text>
          </View>
        </View>
      </React.Fragment>
    ))}
    <SpacerS />
  </React.Fragment>
);
const SingleTextListLinks = props => (
  <React.Fragment>
    {props.items.map((item, idx) => (
      <React.Fragment key={idx}>
        <View style={styles.col2}>
          <View style={styles.content50}>
            <Text>{item.label}</Text>
          </View>
          <View style={styles.content50}>
            <Text>
              <Link src={item.href}>{item.href}</Link>
            </Text>
          </View>
        </View>
      </React.Fragment>
    ))}
    <SpacerS />
  </React.Fragment>
);

export const Wireframes = props => {
  const { data } = props;

  const firstSection = [
    {
      label: labels.domainLabel.label,
      content: data.domain,
      description: labels.domainLabel.helpText,
      tags: false
    },
    {
      label: labels.skillLabel.label,
      content: data.skill,
      description: '',
      tags: false
    },
    {
      label: labels.imageLabel.label,
      content: data.image,
      description: '',
      tags: false
    }
  ];
  const secondSetion = [
    {
      label: labels.sitesLabel.label,
      content: data.sites,
      description: '',
      tags: false
    },
    {
      label: labels.articlesSitesLabel.label,
      content: data.articlesSites,
      description: labels.articlesSitesLabel.helpTexts,
      tags: false
    },
    {
      label: labels.incomeLabel.label,
      content: data.income,
      description: '',
      tags: false
    },
    {
      label: labels.wireframesLabel.label,
      content: '',
      description: labels.wireframesLabel.helpTexts,
      tags: false
    }
  ];
  return (
    <Document>
      <GenericPage>
        <TitleSection title={data.title} />
        
        <Introduction
          showField={props.data.showIntroduction}
          fieldData={props.data.introduction}
        />
        {firstSection.map((elem, idx) => (
          <DescribedText
            key={idx}
            title={elem.label}
            content={elem.content}
            description={elem.description}
            isContentTags={elem.tags}
          />
        ))}
        <SingleTextList
          title={labels.arrayColorLabel.label}
          items={data.arrayColor}
        />
        {secondSetion.map((elem, idx) => (
          <DescribedText
            key={idx}
            title={elem.label}
            content={elem.content}
            description={elem.description}
            isContentTags={elem.tags}
          />
        ))}
        <SingleTextListLinks items={data.wireframes} />
        <Introduction
          showField={props.data.showConclusion}
          fieldData={props.data.conclusion}
        />
      </GenericPage>
    </Document>
  );
};
