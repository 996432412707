import React, { useState } from 'react';
import {
  write as setData,
  read as getData,
  remove as removeData,
  write
} from '../../services/storage';
import {
  EuiFlexItem,
  EuiSpacer,
  EuiPageContentHeader,
  EuiPageContentBody,
  EuiButton,
  EuiFormRow,
  EuiText,
  EuiLink,
  EuiBottomBar,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiGlobalToastList,
  EuiOverlayMask,
  EuiConfirmModal,
} from '@elastic/eui';
import { CustomInput } from '../utils/CustomInput';
import { EditableTitle } from '../utils/EditableTitle';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';
import { storage } from '../../config/env';

export const TemplateHQ = props => {
  const localData = getData(props.template.key) || {};

  //#region states

  const [toasts, setToasts] = useState([]);
  const [introduction, setIntroduction] = useState(
    localData.introduction || ''
  );
  const [showIntroduction, setShowIntroduction] = useState(
    localData.showIntroduction !== undefined
      ? localData.showIntroduction
      : false
  );
  const [conclusion, setConclusion] = useState(localData.conclusion || '');
  const [showConclusion, setShowConclusion] = useState(
    localData.showConclusion !== undefined
      ? localData.showConclusion
      : false
  );

  // Categories section
  const [question1, setQuestion1] = useState(localData.question1 || '');
  const [question2, setQuestion2] = useState(localData.question2 || '');
  const [question3, setQuestion3] = useState(localData.question3 || '');
  const [question4, setQuestion4] = useState(localData.question4 || '');
  const [question5, setQuestion5] = useState(localData.question5 || '');
  const [question6, setQuestion6] = useState(localData.question6 || '');
  const [question7, setQuestion7] = useState(localData.question7 || '');
  const [question8, setQuestion8] = useState(localData.question8 || '');
  const [question9, setQuestion9] = useState(localData.question9 || '');
  const [question10, setQuestion10] = useState(localData.question10 || '');
  const [question11, setQuestion11] = useState(localData.question11 || '');
  const [question12, setQuestion12] = useState(localData.question12 || '');
  const [question13, setQuestion13] = useState(localData.question13 || '');
  const [question14, setQuestion14] = useState(localData.question14 || '');
  const [question15, setQuestion15] = useState(localData.question15 || '');
  const [question16, setQuestion16] = useState(localData.question16 || '');
  const [question17, setQuestion17] = useState(localData.question17 || '');
  const [question18, setQuestion18] = useState(localData.question18 || '');
  const [question19, setQuestion19] = useState(localData.question19 || '');
  const [question20, setQuestion20] = useState(localData.question20 || '');
  const [question21, setQuestion21] = useState(localData.question21 || '');
  const [question22, setQuestion22] = useState(localData.question22 || '');
  const [question23, setQuestion23] = useState(localData.question23 || '');
  const [question24, setQuestion24] = useState(localData.question24 || '');
  const [question25, setQuestion25] = useState(localData.question25 || '');
  const [question26, setQuestion26] = useState(localData.question26 || '');
  const [question27, setQuestion27] = useState(localData.question27 || '');
  const [question28, setQuestion28] = useState(localData.question28 || '');
  const [question29, setQuestion29] = useState(localData.question29 || '');
  const [question30, setQuestion30] = useState(localData.question30 || '');
  const [question31, setQuestion31] = useState(localData.question31 || '');
  const [question32, setQuestion32] = useState(localData.question32 || '');
  const [question33, setQuestion33] = useState(localData.question33 || '');
  const [question34, setQuestion34] = useState(localData.question34 || '');
  const [question35, setQuestion35] = useState(localData.question35 || '');
  const [question36, setQuestion36] = useState(localData.question36 || '');
  const [question37, setQuestion37] = useState(localData.question37 || '');
  const [question38, setQuestion38] = useState(localData.question38 || '');
  const [question39, setQuestion39] = useState(localData.question39 || '');
  const [question40, setQuestion40] = useState(localData.question40 || '');
  const [question41, setQuestion41] = useState(localData.question41 || '');
  const [question42, setQuestion42] = useState(localData.question42 || '');
  const [question43, setQuestion43] = useState(localData.question43 || '');
  const [question44, setQuestion44] = useState(localData.question44 || '');
  const [question45, setQuestion45] = useState(localData.question45 || '');
  const [question46, setQuestion46] = useState(localData.question46 || '');
  const [question47, setQuestion47] = useState(localData.question47 || '');
  const [question48, setQuestion48] = useState(localData.question48 || '');
  const [question49, setQuestion49] = useState(localData.question49 || '');
  const [question50, setQuestion50] = useState(localData.question50 || '');
  const [question51, setQuestion51] = useState(localData.question51 || '');
  const [question52, setQuestion52] = useState(localData.question52 || '');
  const [question53, setQuestion53] = useState(localData.question53 || '');
  const [question54, setQuestion54] = useState(localData.question54 || '');
  const [question55, setQuestion55] = useState(localData.question55 || '');
  const [question56, setQuestion56] = useState(localData.question56 || '');
  const [question57, setQuestion57] = useState(localData.question57 || '');
  const [question58, setQuestion58] = useState(localData.question58 || '');
  const [question59, setQuestion59] = useState(localData.question59 || '');
  const [question60, setQuestion60] = useState(localData.question60 || '');
  const [question61, setQuestion61] = useState(localData.question61 || '');
  const [question62, setQuestion62] = useState(localData.question62 || '');
  const [question63, setQuestion63] = useState(localData.question63 || '');
  const [question64, setQuestion64] = useState(localData.question64 || '');
  const [question65, setQuestion65] = useState(localData.question65 || '');
  const [question66, setQuestion66] = useState(localData.question66 || '');
  const [question67, setQuestion67] = useState(localData.question67 || '');
  const [question68, setQuestion68] = useState(localData.question68 || '');
  const [question69, setQuestion69] = useState(localData.question69 || '');
  const [question70, setQuestion70] = useState(localData.question70 || '');
  const [question71, setQuestion71] = useState(localData.question71 || '');
  const [question72, setQuestion72] = useState(localData.question72 || '');
  const [question73, setQuestion73] = useState(localData.question73 || '');
  const [question74, setQuestion74] = useState(localData.question74 || '');
  const [question75, setQuestion75] = useState(localData.question75 || '');
  const [question76, setQuestion76] = useState(localData.question76 || '');
  const [question77, setQuestion77] = useState(localData.question77 || '');
  const [question78, setQuestion78] = useState(localData.question78 || '');
  const [question79, setQuestion79] = useState(localData.question79 || '');
  const [question80, setQuestion80] = useState(localData.question80 || '');
  const [question81, setQuestion81] = useState(localData.question81 || '');
  const [question82, setQuestion82] = useState(localData.question82 || '');
  const [question83, setQuestion83] = useState(localData.question83 || '');
  const [question84, setQuestion84] = useState(localData.question84 || '');
  const [question85, setQuestion85] = useState(localData.question85 || '');
  const [question86, setQuestion86] = useState(localData.question86 || '');
  const [question87, setQuestion87] = useState(localData.question87 || '');
  const [question88, setQuestion88] = useState(localData.question88 || '');
  const [question89, setQuestion89] = useState(localData.question89 || '');
  const [question90, setQuestion90] = useState(localData.question90 || '');
  const [question91, setQuestion91] = useState(localData.question91 || '');
  const [question92, setQuestion92] = useState(localData.question92 || '');
  const [question93, setQuestion93] = useState(localData.question93 || '');
  const [question94, setQuestion94] = useState(localData.question94 || '');
  const [question95, setQuestion95] = useState(localData.question95 || '');
  const [question96, setQuestion96] = useState(localData.question96 || '');
  const [question97, setQuestion97] = useState(localData.question97 || '');
  const [question98, setQuestion98] = useState(localData.question98 || '');
  const [question99, setQuestion99] = useState(localData.question99 || '');
  const [question100, setQuestion100] = useState(localData.question100 || '');

  // Additional categories
  const [aditionalCategories, setAditionalCategory] = useState(
    localData.aditionalCategories || []
  );
  const [currentBox,] = useState('');
  const [title, setTitle] = useState(localData.title || props.template.name);
  const [, setIsTitleEditable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  //#endregion

  // List of categories inputs data
  const categoriesData = [
    {
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus objetivos mensuales de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question1,
      onChangeHandler: setQuestion1
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus objetivos trimestrales de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question2,
      onChangeHandler: setQuestion2
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus objetivos anuales de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question3,
      onChangeHandler: setQuestion3
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué consecuencias habrá si no logras llegar a estos objetivos trimestrales o anuales de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question4,
      onChangeHandler: setQuestion4
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus objetivos mensuales de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question5,
      onChangeHandler: setQuestion5
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus objetivos trimestrales de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question6,
      onChangeHandler: setQuestion6
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus objetivos anuales de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question7,
      onChangeHandler: setQuestion7
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué pasará si no logras llegar a estos objetivos trimestrales o anuales de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question8,
      onChangeHandler: setQuestion8
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuentas con un acuerdo de nivel de servicios (SLA) entre las áreas de marketing y ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question9,
      onChangeHandler: setQuestion9
    },{
      showVar: true,
      disableVar: false,
      label: "¿Quién creó este acuerdo?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question10,
      onChangeHandler: setQuestion10
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué pasará si el área de marketing no cumple con los compromisos que tiene con el área de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question11,
      onChangeHandler: setQuestion11
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuánto gastas al año en marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question12,
      onChangeHandler: setQuestion12
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué resultados anuales tendrías que obtener para otorgar más presupuesto al marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question13,
      onChangeHandler: setQuestion13
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es tu coste de adquisición de clientes (CAC)?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question14,
      onChangeHandler: setQuestion14
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es la proporción del valor del tiempo de vida de un cliente respecto al CAC (LTVCAC)?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question15,
      onChangeHandler: setQuestion15
    },{
      showVar: true,
      disableVar: false,
      label: "¿En cuánto tiempo recuperas la inversión del CAC?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question16,
      onChangeHandler: setQuestion16
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué porcentaje de tratos con clientes se genera por el marketing en la actualidad?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question17,
      onChangeHandler: setQuestion17
    },{
      showVar: true,
      disableVar: false,
      label: "¿En qué porcentaje de tratos con clientes influye el marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question18,
      onChangeHandler: setQuestion18
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es tu tasa actual de conversión de clientes?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question19,
      onChangeHandler: setQuestion19
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es el valor del tiempo de vida de uno de tus clientes?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question20,
      onChangeHandler: setQuestion20
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué métricas de marketing monitorizas en la actualidad?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question21,
      onChangeHandler: setQuestion21
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué métricas de marketing son más útiles y valiosas para tu equipo?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question22,
      onChangeHandler: setQuestion22
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué estrategias produjeron más ROI para tu empresa en el año anterior?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question23,
      onChangeHandler: setQuestion23
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué estrategias fueron las menos exitosas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question24,
      onChangeHandler: setQuestion24
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué actividades de marketing se llevan a cabo para desarrollar tu marca en comparación con las que haces para producir un retorno de la inversión?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question25,
      onChangeHandler: setQuestion25
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué pasa si hay errores en tus métricas actuales?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question26,
      onChangeHandler: setQuestion26
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cómo es una campaña de marketing exitosa?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question27,
      onChangeHandler: setQuestion27
    },{
      showVar: true,
      disableVar: false,
      label: "¿Has lanzado campañas que consideras riesgosas y, de ser así, cuáles fueron sus resultados?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question28,
      onChangeHandler: setQuestion28
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué criterios usas para medir la calidad de una oportunidad de venta?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question29,
      onChangeHandler: setQuestion29
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué ofertas o campañas de marketing crean oportunidades de venta de baja calidad?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question30,
      onChangeHandler: setQuestion30
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué ofertas o campañas de marketing crean oportunidades de venta de alta calidad?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question31,
      onChangeHandler: setQuestion31
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué herramientas usas para gestionar tu marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question32,
      onChangeHandler: setQuestion32
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué herramientas usas para monitorizar y analizar tus campañas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question33,
      onChangeHandler: setQuestion33
    },{
      showVar: true,
      disableVar: false,
      label: "Si vendes productos offline, ¿cómo monitorizas esas ventas en tus fuentes digitales?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question34,
      onChangeHandler: setQuestion34
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué valores y convicciones definen a tu marca?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question35,
      onChangeHandler: setQuestion35
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué problemas solucionas para tus clientes?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question36,
      onChangeHandler: setQuestion36
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cómo encuentran tu producto los prospectos?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question37,
      onChangeHandler: setQuestion37
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué fuentes atraen a los clientes más valiosos?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question38,
      onChangeHandler: setQuestion38
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué estrategias usas para retener a tus clientes?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question39,
      onChangeHandler: setQuestion39
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué estrategia usas para hacer que tus clientes adquieran productos de mayor valor?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question40,
      onChangeHandler: setQuestion40
    },{
      showVar: true,
      disableVar: false,
      label: "Desde un punto de vista comercial general, ¿cuál es tu mayor desafío?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question41,
      onChangeHandler: setQuestion41
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus mayores desafíos de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question42,
      onChangeHandler: setQuestion42
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son tus mayores desafíos de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question43,
      onChangeHandler: setQuestion43
    },{
      showVar: true,
      disableVar: false,
      label: "¿Por qué no se han resuelto estos desafíos?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question44,
      onChangeHandler: setQuestion44
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué efecto negativo han tenido estos desafíos o problemas en tu empresa?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question45,
      onChangeHandler: setQuestion45
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cómo es tu proceso de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question46,
      onChangeHandler: setQuestion46
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es el tiempo promedio de tu ciclo de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question47,
      onChangeHandler: setQuestion47
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son los tres elementos de contenido más útiles para el proceso de ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question48,
      onChangeHandler: setQuestion48
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son los mayores desafíos que enfrentan tus representantes de servicio al cliente?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question49,
      onChangeHandler: setQuestion49
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué hace que tu empresa sea única en el mercado?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question50,
      onChangeHandler: setQuestion50
    },{
      showVar: true,
      disableVar: false,
      label: "¿Quiénes son tus tres competidores principales?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question51,
      onChangeHandler: setQuestion51
    },{
      showVar: true,
      disableVar: false,
      label: "¿Quién no forma parte de la competencia actual pero podría llegar a serlo en el futuro?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question52,
      onChangeHandler: setQuestion52
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué marketing creó la competencia que desearías haber creado tú?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question53,
      onChangeHandler: setQuestion53
    },{
      showVar: true,
      disableVar: false,
      label: "¿Por qué característica quieres que tu empresa sea conocida en el mercado?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question54,
      onChangeHandler: setQuestion54
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué te emociona personalmente de ir a trabajar día a día?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question55,
      onChangeHandler: setQuestion55
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué avances o productos tecnológicos podrían afectar a tu modelo de negocios en 5 o 10 años?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question56,
      onChangeHandler: setQuestion56
    },{
      showVar: true,
      disableVar: false,
      label: "¿A qué audiencia te diriges? ¿Has establecido buyer personas (perfiles de consumidor) para cada uno de los grupos de clientes a los que te diriges?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question57,
      onChangeHandler: setQuestion57
    },{
      showVar: true,
      disableVar: false,
      label: "¿De qué tamaño es el mercado al que te puedes dirigir?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question58,
      onChangeHandler: setQuestion58
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué porcentaje de tu mercado conoce a tu marca y productos o servicios?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question59,
      onChangeHandler: setQuestion59
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son las publicaciones o blogs más destacados de tu industria?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question60,
      onChangeHandler: setQuestion60
    },{
      showVar: true,
      disableVar: false,
      label: "¿A qué conferencias en tu industria es indispensable que asistas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question61,
      onChangeHandler: setQuestion61
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué hace que comprar tu producto o servicio sea una necesidad? ¿Qué hace que un cliente compre tu producto o servicio?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question62,
      onChangeHandler: setQuestion62
    },{
      showVar: true,
      disableVar: false,
      label: "¿Hay patrones de compra por temporada que afectan a tus ventas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question63,
      onChangeHandler: setQuestion63
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué tipo de prospectos no son adecuados para tu empresa?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question64,
      onChangeHandler: setQuestion64
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué percepciones erróneas tienen los prospectos sobre tu marca?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question65,
      onChangeHandler: setQuestion65
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué les gusta y qué no a las oportunidades de venta sobre tu marca y marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question66,
      onChangeHandler: setQuestion66
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles son las razones principales por las que una oportunidad de venta no se convierte?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question67,
      onChangeHandler: setQuestion67
    },{
      showVar: true,
      disableVar: false,
      label: "¿Por qué cuentas con una agencia / profesional de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question68,
      onChangeHandler: setQuestion68
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué te hizo querer contratar el servicio?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question69,
      onChangeHandler: setQuestion69
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué funcionó y qué no con tu anterior partner de marketing?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question70,
      onChangeHandler: setQuestion70
    },{
      showVar: true,
      disableVar: false,
      label: "¿Por qué despedirías el servicio?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question71,
      onChangeHandler: setQuestion71
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué factores crees que hacen exitosa a una relación entre el cliente y su agencia?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question72,
      onChangeHandler: setQuestion72
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué opinión tiene el líder de tu empresa sobre el papel del marketing en el éxito general de la marca?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question73,
      onChangeHandler: setQuestion73
    },{
      showVar: true,
      disableVar: false,
      label: "¿Quiénes toman las decisiones en tu departamento?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question74,
      onChangeHandler: setQuestion74
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué herramientas usas para gestionar tus campañas?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question75,
      onChangeHandler: setQuestion75
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué te sorprendió de lo que hemos hecho recientemente?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question76,
      onChangeHandler: setQuestion76
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles de las cosas que hicimos tuvo mayor valor para tu empresa?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question77,
      onChangeHandler: setQuestion77
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cómo podría mi equipo mejorar la comunicación con el tuyo?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question78,
      onChangeHandler: setQuestion78
    },{
      showVar: true,
      disableVar: false,
      label: "¿Con qué miembro de tu equipo necesitamos tener una mejor relación?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question79,
      onChangeHandler: setQuestion79
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué tipo de comunicación (por teléfono, correo electrónico, mensajes de texto, etc.) funciona mejor para ti?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question80,
      onChangeHandler: setQuestion80
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es tu tiempo de respuesta habitual para regresar llamadas y responder correos electrónicos?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question81,
      onChangeHandler: setQuestion81
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cómo podríamos tener una comunicación mejor en cuanto a los plazos, líneas de tiempo y actualizaciones de proyectos?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question82,
      onChangeHandler: setQuestion82
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cómo manejas la gestión de contraseñas de los perfiles de tu empresa?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question83,
      onChangeHandler: setQuestion83
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuentas con un plan de comunicación de crisis?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question84,
      onChangeHandler: setQuestion84
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es el procedimiento para tratar la queja de un cliente o un problema online?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question85,
      onChangeHandler: setQuestion85
    },{
      showVar: true,
      disableVar: false,
      label: "¿A qué miembros de nuestro equipo contratarías y por qué?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question86,
      onChangeHandler: setQuestion86
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué situaciones te quitan el sueño por las noches?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question87,
      onChangeHandler: setQuestion87
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué habilidades hacen falta en los miembros actuales de tu personal?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question88,
      onChangeHandler: setQuestion88
    },{
      showVar: true,
      disableVar: false,
      label: "¿En qué áreas te gustaría tener más training?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question89,
      onChangeHandler: setQuestion89
    },{
      showVar: true,
      disableVar: false,
      label: "Si pudieras elegir, ¿con qué influencer o celebrity te gustaría trabajar?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question90,
      onChangeHandler: setQuestion90
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué producto/servicio te gustaría vender?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question91,
      onChangeHandler: setQuestion91
    },{
      showVar: true,
      disableVar: false,
      label: "¿Por qué no puedes hacerlo? (sobre lo anterior)",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question92,
      onChangeHandler: setQuestion92
    },{
      showVar: true,
      disableVar: false,
      label: "¿Qué nuevas áreas te gustaría explorar?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question93,
      onChangeHandler: setQuestion93
    },{
      showVar: true,
      disableVar: false,
      label: "¿Con qué personas te gustaría reforzar tu negocio?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question94,
      onChangeHandler: setQuestion94
    },{
      showVar: true,
      disableVar: false,
      label: "Si pudieras elegir, ¿qué conocimiento adicional te gustaría adquirir?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question95,
      onChangeHandler: setQuestion95
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuántos clientes te gustaría llegar a tener en 10 años?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question96,
      onChangeHandler: setQuestion96
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuántos empleados te gustaría llegar a tener en 10 años?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question97,
      onChangeHandler: setQuestion97
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuántos seguidores en RRSS te gustaría llegar a tener en 10 años?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question98,
      onChangeHandler: setQuestion98
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuál es el fin último de tu empresa? Toda empresa nace para ser vendida o quebrar.",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question99,
      onChangeHandler: setQuestion99
    },{
      showVar: true,
      disableVar: false,
      label: "¿Cuáles te gustarían que fueran los próximos pasos?",
      showLabel: true,
      disableFunc: '',
      placeholder: "",
      value: question100,
      onChangeHandler: setQuestion100
    },
  ];

  const makePdf = () => {
    const data = {
      introduction,
      showIntroduction,
      conclusion,
      showConclusion,
      title,
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      question9,
      question10,
      question11,
      question12,
      question13,
      question14,
      question15,
      question16,
      question17,
      question18,
      question19,
      question20,
      question21,
      question22,
      question23,
      question24,
      question25,
      question26,
      question27,
      question28,
      question29,
      question30,
      question31,
      question32,
      question33,
      question34,
      question35,
      question36,
      question37,
      question38,
      question39,
      question40,
      question41,
      question42,
      question43,
      question44,
      question45,
      question46,
      question47,
      question48,
      question49,
      question50,
      question51,
      question52,
      question53,
      question54,
      question55,
      question56,
      question57,
      question58,
      question59,
      question60,
      question61,
      question62,
      question63,
      question64,
      question65,
      question66,
      question67,
      question68,
      question69,
      question70,
      question71,
      question72,
      question73,
      question74,
      question75,
      question76,
      question77,
      question78,
      question79,
      question80,
      question81,
      question82,
      question83,
      question84,
      question85,
      question86,
      question87,
      question88,
      question89,
      question90,
      question91,
      question92,
      question93,
      question94,
      question95,
      question96,
      question97,
      question98,
      question99,
      question100,
      template: props.template.key
    };
    write(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  };
 
  const removeItem = index => {
    const categoriesCopy = aditionalCategories;
    categoriesCopy.splice(index, 1);
    setAditionalCategory([...categoriesCopy]);
  };

  const saveDataTemplate = () => {
    const data = {
      introduction,
      showIntroduction,
      conclusion,
      showConclusion,
      title,
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      question9,
      question10,
      question11,
      question12,
      question13,
      question14,
      question15,
      question16,
      question17,
      question18,
      question19,
      question20,
      question21,
      question22,
      question23,
      question24,
      question25,
      question26,
      question27,
      question28,
      question29,
      question30,
      question31,
      question32,
      question33,
      question34,
      question35,
      question36,
      question37,
      question38,
      question39,
      question40,
      question41,
      question42,
      question43,
      question44,
      question45,
      question46,
      question47,
      question48,
      question49,
      question50,
      question51,
      question52,
      question53,
      question54,
      question55,
      question56,
      question57,
      question58,
      question59,
      question60,
      question61,
      question62,
      question63,
      question64,
      question65,
      question66,
      question67,
      question68,
      question69,
      question70,
      question71,
      question72,
      question73,
      question74,
      question75,
      question76,
      question77,
      question78,
      question79,
      question80,
      question81,
      question82,
      question83,
      question84,
      question85,
      question86,
      question87,
      question88,
      question89,
      question90,
      question91,
      question92,
      question93,
      question94,
      question95,
      question96,
      question97,
      question98,
      question99,
      question100
    };
    setData(props.template.key, data);
    let toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const resetData = () => {
    removeData(props.template.key);
    setIntroduction('');
    setShowIntroduction(false);
    setConclusion('');
    setShowConclusion(false);
    setTitle(props.template.name);
    setIsTitleEditable(false);    
    let toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
    setQuestion1('');
    setQuestion2('');
    setQuestion3('');
    setQuestion4('');
    setQuestion5('');
    setQuestion6('');
    setQuestion7('');
    setQuestion8('');
    setQuestion9('');
    setQuestion10('');
    setQuestion11('');
    setQuestion12('');
    setQuestion13('');
    setQuestion14('');
    setQuestion15('');
    setQuestion16('');
    setQuestion17('');
    setQuestion18('');
    setQuestion19('');
    setQuestion20('');
    setQuestion21('');
    setQuestion22('');
    setQuestion23('');
    setQuestion24('');
    setQuestion25('');
    setQuestion26('');
    setQuestion27('');
    setQuestion28('');
    setQuestion29('');
    setQuestion30('');
    setQuestion31('');
    setQuestion32('');
    setQuestion33('');
    setQuestion34('');
    setQuestion35('');
    setQuestion36('');
    setQuestion37('');
    setQuestion38('');
    setQuestion39('');
    setQuestion40('');
    setQuestion41('');
    setQuestion42('');
    setQuestion43('');
    setQuestion44('');
    setQuestion45('');
    setQuestion46('');
    setQuestion47('');
    setQuestion48('');
    setQuestion49('');
    setQuestion50('');
    setQuestion51('');
    setQuestion52('');
    setQuestion53('');
    setQuestion54('');
    setQuestion55('');
    setQuestion56('');
    setQuestion57('');
    setQuestion58('');
    setQuestion59('');
    setQuestion60('');
    setQuestion61('');
    setQuestion62('');
    setQuestion63('');
    setQuestion64('');
    setQuestion65('');
    setQuestion66('');
    setQuestion67('');
    setQuestion68('');
    setQuestion69('');
    setQuestion70('');
    setQuestion71('');
    setQuestion72('');
    setQuestion73('');
    setQuestion74('');
    setQuestion75('');
    setQuestion76('');
    setQuestion77('');
    setQuestion78('');
    setQuestion79('');
    setQuestion80('');
    setQuestion81('');
    setQuestion82('');
    setQuestion83('');
    setQuestion84('');
    setQuestion85('');
    setQuestion86('');
    setQuestion87('');
    setQuestion88('');
    setQuestion89('');
    setQuestion90('');
    setQuestion91('');
    setQuestion92('');
    setQuestion93('');
    setQuestion94('');
    setQuestion95('');
    setQuestion96('');
    setQuestion97('');
    setQuestion98('');
    setQuestion99('');
    setQuestion100('');
  };

  const closeModal = () => {
    switch (currentBox) {
      case 'showIntroduction':
        setShowIntroduction(false);
        setIntroduction('')
        break;
      default:
        removeItem(parseInt(currentBox));
        break;
    }
    setIsModalVisible(false);
  };

  let modal;
  let resetModal;

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <p style={{ textAlign: 'center' }}>¿Deseas borrar esta sección?</p>
          }
          onCancel={e => setIsModalVisible(false)}
          onConfirm={closeModal}
          cancelButtonText="Cancelar"
          confirmButtonText="Sí, borrar sección"
          defaultFocusedButton="confirm"
          buttonColor="danger"
        >
          <p>Esta acción no se puede deshacer</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  if (isResetModalVisible) {
    resetModal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <p style={{ textAlign: 'center' }}>
              ¿Deseas reiniciar todos
              <br />
              los campos del formulario?
            </p>
          }
          onCancel={() => setIsResetModalVisible(false)}
          onConfirm={() => {
            setIsResetModalVisible(false);
            resetData();
          }}
          cancelButtonText="Cancelar"
          confirmButtonText="Sí, reiniciar formulario"
          defaultFocusedButton="confirm"
          buttonColor="danger"
        >
          <p>Esta acción no se puede deshacer</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  return (
    <>
      {modal}
      {resetModal}
      <EuiFlexItem className="page-template" id="pageTemplate">
        <EuiPageContentHeader style={{ justifyContent: 'center' }}>
          <EditableTitle title={title} setTitle={setTitle} />
        </EuiPageContentHeader>

        <EuiSpacer size="xxl" />

        <EuiPageContentBody>
          <ToggleableTextBlock
            content={introduction}
            setContent={setIntroduction}
            showContent={showIntroduction}
            setShowContent={setShowIntroduction}
            buttonLabel="Agregar texto de introducción"
          />

          <EuiSpacer size="l" />

          {categoriesData.map((catData, idx) => {
            return catData.showVar ? (
              <EuiFormRow
                key={idx}
                className={catData.disableVar ? 'disabled' : ''}
                label={catData.label}
                fullWidth
              >
                <CustomInput
                  isFullWidth={true}
                  placeholder={catData.placeholder}
                  value={catData.value || ''}
                  onChangeHandler={catData.onChangeHandler}
                />
              </EuiFormRow>
            ) : null;
          })}
        </EuiPageContentBody>

        <EuiSpacer size="l" />

        <ToggleableTextBlock
            content={conclusion}
            setContent={setConclusion}
            showContent={showConclusion}
            setShowContent={setShowConclusion}
            buttonLabel="Agregar bloque de texto"
          />
      </EuiFlexItem>
      <EuiBottomBar className="notPrint">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiLink href="/templates">Todas las Plantillas</EuiLink> /
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>{props.template.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="refresh"
                  onClick={() => {
                    setIsTitleEditable(false);
                    setIsResetModalVisible(true);
                  }}
                >
                  Resetear
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="save"
                  onClick={() => {
                    setIsTitleEditable(false);
                    saveDataTemplate();
                  }}
                >
                  Guardar
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  color="primary"
                  fill
                  size="s"
                  iconType="check"
                  onClick={() => {
                    setIsTitleEditable(false);
                    makePdf();
                  }}
                >
                  Imprimir
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </>
  );
};
