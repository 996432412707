import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from '../CustomInput';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiSpacer,
  EuiToolTip
} from '@elastic/eui';

const labels = {
  you: 'Tú',
  competitor: 'Competidor',
  dafoTableHeaders: ['Fortalezas', 'Debilidades', 'Oportunidades', 'Amenazas']
};

const PureDafoDynamicList = props => {
  const onClickHandler = competitor => {
    props.setCompetitorIndex(competitor);
    props.setShowConfirmDeleteCompetitorModal(true);
  };

  return (
    <React.Fragment>
      <EuiFormRow label={labels.you} fullWidth>
        <EuiFlexGroup>
          {labels.dafoTableHeaders.map((_, i) => (
            <EuiFlexItem key={i}>
              <EuiFormRow label={labels.dafoTableHeaders[i]}>
                <CustomInput
                  level={0}
                  index={i}
                  value={props.multiple[0][i] || ''}
                  onChangeHandler={props.onChangeHandler}
                  multiple={props.multiple}
                  setMultiple={props.setMultiple}
                />
              </EuiFormRow>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiSpacer />
      {props.competitors.map((competitor, i) => (
        <React.Fragment key={competitor}>
          <EuiFormRow
            label={`${labels.competitor} ${i + 1}`}
            labelAppend={
              <EuiToolTip position="top" content="Eliminar competidor">
                <EuiIcon
                  style={{ cursor: 'pointer' }}
                  type="minusInCircle"
                  onClick={onClickHandler.bind(this, competitor)}
                />
              </EuiToolTip>
            }
            fullWidth
          >
            <EuiFlexGroup>
              {props.multiple[i].map((_, j) => (
                <EuiFlexItem key={j}>
                  <EuiFormRow label={labels.dafoTableHeaders[j]}>
                    <CustomInput
                      level={i + 1}
                      index={j}
                      value={props.multiple[i + 1][j] || ''}
                      onChangeHandler={props.onChangeHandler}
                      multiple={props.multiple}
                      setMultiple={props.setMultiple}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiSpacer />
        </React.Fragment>
      ))}
      {props.addCompetitorButton}
    </React.Fragment>
  );
};

PureDafoDynamicList.propTypes = {
  competitors: PropTypes.array.isRequired,
  multiple: PropTypes.array.isRequired,
  setMultiple: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  addCompetitorButton: PropTypes.element.isRequired,
  setCompetitorIndex: PropTypes.func.isRequired,
  setShowConfirmDeleteCompetitorModal: PropTypes.func.isRequired
};

const DafoDynamicList = React.memo(PureDafoDynamicList);

export { DafoDynamicList };
