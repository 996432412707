import React from 'react';
import { Document } from '@react-pdf/renderer';
import {
  DescribedText,
  GenericPage,
  Introduction,
  SpacerS,
  Title,
  TitleSection
} from './utils';

export const Hashtags = props => {
  const { data } = props;

  const firstSection = [
    {
      label: 'Objetivo',
      content: data.objetive,
      description: 'Describe el objetivo que deseas lograr',
      tags: false
    },
    {
      label: 'Lluvia de ideas de palabras clave',
      content: data.brainstorming,
      description: 'Ideas de palabras claves que obtuviste de la investigación',
      tags: false
    },
    {
      label: 'Lluvia de ideas de etiquetas',
      content: data.rainlabels,
      description:
        'Ideas de etiquetas que obtuviste de las palabras clave (separadas por coma)',
      tags: false
    }
  ];

  const tagsSection = [
    {
      label: 'Lowtags',
      content: data.lowTags,
      description:
        'Incluye al menos 10 hashtags que tengan como máximo 50 mil seguidores'
    },
    {
      label: 'Midtags',
      content: data.midtags,
      description:
        'Incluye etiquetas que cuentan con más de 100k de publicaciones o seguidores pero no superan el millón'
    },
    {
      label: 'Supertags',
      content: data.supertags,
      description:
        'Incluye etiquetas con más del millón de usuarios pero menores a 10 millones'
    },
    {
      label: 'Bigtags',
      content: data.bigtags,
      description: 'Incluye etiquetas con más de 10 millones de usuarios'
    }
  ];

  return (
    <Document>
      <GenericPage>
        <TitleSection title={data.title} />
        <Introduction
          showField={data.showIntroduction}
          fieldData={data.introduction}
        />
        {firstSection.map((elem, idx) => (
          <DescribedText
            key={idx}
            title={elem.label}
            content={elem.content}
            description={elem.description}
            isContentTags={elem.tags}
          />
        ))}
        <Title title={`Estrategia de distribución de Tags`} />
        <SpacerS />
        {tagsSection.map((elem, idx) => (
          <DescribedText
            key={idx}
            title={elem.label}
            content={elem.content}
            showAsTags={true}
            description={elem.description}
          />
        ))}
        <Introduction showField={data.showOutro} fieldData={data.outro} />
      </GenericPage>
    </Document>
  );
};
