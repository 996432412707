import React from 'react';
import { Document } from '@react-pdf/renderer';
import {
  DescribedText,
  GenericPage,
  Introduction,
  TitleSection
} from './utils';

const labels = [
  '¿Cuáles son tus objetivos mensuales de marketing?',
  '¿Cuáles son tus objetivos trimestrales de marketing?',
  '¿Cuáles son tus objetivos anuales de marketing?',
  '¿Qué consecuencias habrá si no logras llegar a estos objetivos trimestrales o anuales de marketing?',
  '¿Cuáles son tus objetivos mensuales de ventas?',
  '¿Cuáles son tus objetivos trimestrales de ventas?',
  '¿Cuáles son tus objetivos anuales de ventas?',
  '¿Qué pasará si no logras llegar a estos objetivos trimestrales o anuales de ventas?',
  '¿Cuentas con un acuerdo de nivel de servicios (SLA) entre las áreas de marketing y ventas?',
  '¿Quién creó este acuerdo?',
  '¿Qué pasará si el área de marketing no cumple con los compromisos que tiene con el área de ventas?',
  '¿Cuánto gastas al año en marketing?',
  '¿Qué resultados anuales tendrías que obtener para otorgar más presupuesto al marketing?',
  '¿Cuál es tu coste de adquisición de clientes (CAC)?',
  '¿Cuál es la proporción del valor del tiempo de vida de un cliente respecto al CAC (LTVCAC)?',
  '¿En cuánto tiempo recuperas la inversión del CAC?',
  '¿Qué porcentaje de tratos con clientes se genera por el marketing en la actualidad?',
  '¿En qué porcentaje de tratos con clientes influye el marketing?',
  '¿Cuál es tu tasa actual de conversión de clientes?',
  '¿Cuál es el valor del tiempo de vida de uno de tus clientes?',
  '¿Qué métricas de marketing monitorizas en la actualidad?',
  '¿Qué métricas de marketing son más útiles y valiosas para tu equipo?',
  '¿Qué estrategias produjeron más ROI para tu empresa en el año anterior?',
  '¿Qué estrategias fueron las menos exitosas?',
  '¿Qué actividades de marketing se llevan a cabo para desarrollar tu marca en comparación con las que haces para producir un retorno de la inversión?',
  '¿Qué pasa si hay errores en tus métricas actuales?',
  '¿Cómo es una campaña de marketing exitosa?',
  '¿Has lanzado campañas que consideras riesgosas y, de ser así, cuáles fueron sus resultados?',
  '¿Qué criterios usas para medir la calidad de una oportunidad de venta?',
  '¿Qué ofertas o campañas de marketing crean oportunidades de venta de baja calidad?',
  '¿Qué ofertas o campañas de marketing crean oportunidades de venta de alta calidad?',
  '¿Qué herramientas usas para gestionar tu marketing?',
  '¿Qué herramientas usas para monitorizar y analizar tus campañas?',
  'Si vendes productos offline, ¿cómo monitorizas esas ventas en tus fuentes digitales?',
  '¿Qué valores y convicciones definen a tu marca?',
  '¿Qué problemas solucionas para tus clientes?',
  '¿Cómo encuentran tu producto los prospectos?',
  '¿Qué fuentes atraen a los clientes más valiosos?',
  '¿Qué estrategias usas para retener a tus clientes?',
  '¿Qué estrategia usas para hacer que tus clientes adquieran productos de mayor valor?',
  'Desde un punto de vista comercial general, ¿cuál es tu mayor desafío?',
  '¿Cuáles son tus mayores desafíos de marketing?',
  '¿Cuáles son tus mayores desafíos de ventas?',
  '¿Por qué no se han resuelto estos desafíos?',
  '¿Qué efecto negativo han tenido estos desafíos o problemas en tu empresa?',
  '¿Cómo es tu proceso de ventas?',
  '¿Cuál es el tiempo promedio de tu ciclo de ventas?',
  '¿Cuáles son los tres elementos de contenido más útiles para el proceso de ventas?',
  '¿Cuáles son los mayores desafíos que enfrentan tus representantes de servicio al cliente?',
  '¿Qué hace que tu empresa sea única en el mercado?',
  '¿Quiénes son tus tres competidores principales?',
  '¿Quién no forma parte de la competencia actual pero podría llegar a serlo en el futuro?',
  '¿Qué marketing creó la competencia que desearías haber creado tú?',
  '¿Por qué característica quieres que tu empresa sea conocida en el mercado?',
  '¿Qué te emociona personalmente de ir a trabajar día a día?',
  '¿Qué avances o productos tecnológicos podrían afectar a tu modelo de negocios en 5 o 10 años?',
  '¿A qué audiencia te diriges? ¿Has establecido buyer personas (perfiles de consumidor) para cada uno de los grupos de clientes a los que te diriges?',
  '¿De qué tamaño es el mercado al que te puedes dirigir?',
  '¿Qué porcentaje de tu mercado conoce a tu marca y productos o servicios?',
  '¿Cuáles son las publicaciones o blogs más destacados de tu industria?',
  '¿A qué conferencias en tu industria es indispensable que asistas?',
  '¿Qué hace que comprar tu producto o servicio sea una necesidad? ¿Qué hace que un cliente compre tu producto o servicio?',
  '¿Hay patrones de compra por temporada que afectan a tus ventas?',
  '¿Qué tipo de prospectos no son adecuados para tu empresa?',
  '¿Qué percepciones erróneas tienen los prospectos sobre tu marca?',
  '¿Qué les gusta y qué no a las oportunidades de venta sobre tu marca y marketing?',
  '¿Cuáles son las razones principales por las que una oportunidad de venta no se convierte?',
  '¿Por qué cuentas con una agencia / profesional de marketing?',
  '¿Qué te hizo querer contratar el servicio?',
  '¿Qué funcionó y qué no con tu anterior partner de marketing?',
  '¿Por qué despedirías el servicio?',
  '¿Qué factores crees que hacen exitosa a una relación entre el cliente y su agencia?',
  '¿Qué opinión tiene el líder de tu empresa sobre el papel del marketing en el éxito general de la marca?',
  '¿Quiénes toman las decisiones en tu departamento?',
  '¿Qué herramientas usas para gestionar tus campañas?',
  '¿Qué te sorprendió de lo que hemos hecho recientemente?',
  '¿Cuáles de las cosas que hicimos tuvo mayor valor para tu empresa?',
  '¿Cómo podría mi equipo mejorar la comunicación con el tuyo?',
  '¿Con qué miembro de tu equipo necesitamos tener una mejor relación?',
  '¿Qué tipo de comunicación (por teléfono, correo electrónico, mensajes de texto, etc.) funciona mejor para ti?',
  '¿Cuál es tu tiempo de respuesta habitual para regresar llamadas y responder correos electrónicos?',
  '¿Cómo podríamos tener una comunicación mejor en cuanto a los plazos, líneas de tiempo y actualizaciones de proyectos?',
  '¿Cómo manejas la gestión de contraseñas de los perfiles de tu empresa?',
  '¿Cuentas con un plan de comunicación de crisis?',
  '¿Cuál es el procedimiento para tratar la queja de un cliente o un problema online?',
  '¿A qué miembros de nuestro equipo contratarías y por qué?',
  '¿Qué situaciones te quitan el sueño por las noches?',
  '¿Qué habilidades hacen falta en los miembros actuales de tu personal?',
  '¿En qué áreas te gustaría tener más training?',
  'Si pudieras elegir, ¿con qué influencer o celebrity te gustaría trabajar?',
  '¿Qué producto/servicio te gustaría vender?',
  '¿Por qué no puedes hacerlo? (sobre lo anterior)',
  '¿Qué nuevas áreas te gustaría explorar?',
  '¿Con qué personas te gustaría reforzar tu negocio?',
  'Si pudieras elegir, ¿qué conocimiento adicional te gustaría adquirir?',
  '¿Cuántos clientes te gustaría llegar a tener en 10 años?',
  '¿Cuántos empleados te gustaría llegar a tener en 10 años?',
  '¿Cuántos seguidores en RRSS te gustaría llegar a tener en 10 años?',
  '¿Cuál es el fin último de tu empresa? Toda empresa nace para ser vendida o quebrar.',
  '¿Cuáles te gustarían que fueran los próximos pasos?'
];

export const HundredQuestions = props => {
  const { data } = props;

  return (
    <Document>
      <GenericPage>
        <TitleSection title={data.title} />
        <Introduction
          showField={data.showIntroduction}
          fieldData={data.introduction}
        />
        {Object.entries(data)
          .filter(entry => entry[0].indexOf('question') !== -1)
          .map((entry, index) => (
            <DescribedText
              key={entry[0]}
              title={labels[index]}
              content={entry[1]}
            />
          ))}
        <Introduction
          showField={data.showConclusion}
          fieldData={data.conclusion}
        />
      </GenericPage>
    </Document>
  );
};
