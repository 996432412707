import React, { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import {
  read as getData,
  write as setData,
  remove as removeData
} from '../../services/storage';
import {
  EuiFlexItem,
  EuiSpacer,
  EuiPageContentHeader,
  EuiPageContentBody,
  EuiButton,
  EuiFormRow,
  EuiText,
  EuiLink,
  EuiBottomBar,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiGlobalToastList,
  EuiOverlayMask,
  EuiConfirmModal,
  htmlIdGenerator
} from '@elastic/eui';
import { CustomInput } from '../utils/CustomInput';
import { EditableTitle } from '../utils/EditableTitle';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';
import { storage } from '../../config/env';

export const TemplatePlanHashtag = props => {
  // #region states
  const localData = getData(props.template.key) || {};
  const [toasts, setToasts] = useState([]);
  const [introduction, setIntroduction] = useState(
    localData.introduction || ''
  );
  const [showIntroduction, setShowIntroduction] = useState(
    localData.showIntroduction !== undefined
      ? localData.showIntroduction
      : false
  );
  const [outro, setOutro] = useState(localData.outro || '');
  const [showOutro, setShowOutro] = useState(
    localData.showOutro !== undefined ? localData.showOutro : false
  );
  const [objetive, setObjetive] = useState(localData.objetive || '');
  const [brainstorming, setBrainstorming] = useState(
    localData.brainstorming || ''
  );
  const [rainlabels, setRainlabels] = useState(localData.rainlabels || '');

  const [title, setTitle] = useState(localData.title || props.template.name);
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [suggestions, setSuggestions] = useState(localData.suggestions || []);
  const [lowTags, setLowTags] = useState(localData.lowTags || []);
  const [midtags, setMidtags] = useState(localData.midtags || []);
  const [supertags, setSupertags] = useState(localData.supertags || []);
  const [bigtags, setBigtags] = useState(localData.bigtags || []);
  const [itemsCalc, setItemsCalc] = useState(localData.itemsCalc || []);
  const [total, setTotal] = useState(parseInt(localData.totalCalc) || 0);
  //#endregion

  const makeId = htmlIdGenerator();

  const makePdf = () => {
    const totalCalc = total.toString();
    const data = {
      introduction,
      showIntroduction,
      outro,
      showOutro,
      objetive,
      brainstorming,
      rainlabels,
      title,
      isTitleEditable,
      isModalVisible,
      isResetModalVisible,
      suggestions,
      lowTags,
      midtags,
      supertags,
      bigtags,
      itemsCalc,
      totalCalc,
      template: props.template.key
    };
    setData(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  };

  useEffect(() => {
    if (rainlabels) {
      const tags = rainlabels.split(',');
      const processed = tags.map((tag, index) => {
        const suggestion = suggestions.find(sug => sug.text === tag);
        if (suggestion) {
          return {
            id: suggestion.id,
            text: suggestion.text.toLowerCase().trim(),
            used: suggestion.used,
            points: suggestion.points
          };
        } else {
          return {
            id: makeId(),
            text: tag,
            used: false,
            points: 0
          };
        }
      });
      setSuggestions([...processed]);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [rainlabels]);
  /* eslint-enable */

  const saveDataTemplate = () => {
    let totalCalc = total.toString();
    const data = {
      introduction,
      showIntroduction,
      outro,
      showOutro,
      objetive,
      brainstorming,
      rainlabels,
      title,
      isTitleEditable,
      isModalVisible,
      isResetModalVisible,
      suggestions,
      lowTags,
      midtags,
      supertags,
      bigtags,
      itemsCalc,
      totalCalc
    };
    setData(props.template.key, data);
    let toast = { id: '1', title: 'Datos Guardados', color: 'success' };
    setToasts(toasts.concat(toast));
  };

  const calculateTotal = () => {
    if (!itemsCalc.length) {
      setTotal(0);
    } else {
      let temp = 0;
      itemsCalc.forEach(calcItem => {
        const item = suggestions.find(sug => sug.id === calcItem.id);
        if (item) temp += item.points;
      });
      setTotal(temp);
    }
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const resetData = () => {
    removeData(props.template.key);
    setTitle(props.template.name);
    setIntroduction('');
    setShowIntroduction(false);
    setOutro('');
    setShowOutro(false);
    setObjetive('');
    setBrainstorming('');
    setRainlabels('');
    setTitle(props.template.name);
    setIsTitleEditable(false);
    setIsModalVisible(false);
    setIsResetModalVisible(false);
    setSuggestions([]);
    setLowTags([]);
    setMidtags([]);
    setSupertags([]);
    setBigtags([]);
    setItemsCalc([]);
    setTotal(0);
  };

  const handleAddition = tag => {
    const copyArray = [...suggestions];
    let item = searchTag(tag.text);
    if (item && !item.used) {
      setLowTags([...lowTags, { id: item.id, text: item.text }]);
      item.used = true;
      item.points = 1;
      setSuggestions([...copyArray]);
    }
  };
  const handleAdditionMidTags = tag => {
    const copyArray = [...suggestions];
    let item = searchTag(tag.text);
    if (item && !item.used) {
      setMidtags([...midtags, { id: item.id, text: item.text }]);
      item.used = true;
      item.points = 2;
      setSuggestions([...copyArray]);
    }
  };
  const handleAdditionBigTags = tag => {
    const copyArray = [...suggestions];
    let item = searchTag(tag.text);
    if (item && !item.used) {
      setBigtags([...bigtags, { id: item.id, text: item.text }]);
      item.used = true;
      item.points = 10;
      setSuggestions([...copyArray]);
    }
  };
  const handleAdditionSuperTags = tag => {
    const copyArray = [...suggestions];
    let item = searchTag(tag.text);
    if (item && !item.used) {
      setSupertags([...supertags, { id: item.id, text: item.text }]);
      item.used = true;
      item.points = 5;
      setSuggestions([...copyArray]);
    }
  };

  const handleAdditionItemCalc = tag => {
    let item = searchTag(tag.text);
    if (item) {
      if (item.points > 0) {
        setTotal(item.points + parseInt(total));
      } else {
        let toast = {
          id: '1',
          title: `Etiqueta ${item.text} no asignada a ninguna sección`,
          color: 'warning'
        };
        setToasts(toasts.concat(toast));
      }
      setItemsCalc([...itemsCalc, { id: item.id, text: item.text }]);
    }
  };

  const handleDelete = (index, ownTags, setOwnTags) => {
    const itemRef = ownTags.find((_, idx) => idx === index);
    const item = suggestions.find(sug => sug.id === itemRef.id);
    const newArray = ownTags.filter((tag, idx) => idx !== index);
    setItemsCalc(itemsCalc.filter(aux => aux.id !== item.id));
    if (item.used) {
      item.used = false;
      item.points = 0;
      const filteredSugs = suggestions.filter(sug => sug.id !== item.id);
      setSuggestions([...filteredSugs, item]);
    }
    setOwnTags([...newArray]);
    calculateTotal();
  };

  const handleDeleteItemCalc = index => {
    const itemRef = itemsCalc.find((_, idx) => idx === index);
    const item = suggestions.find(sug => sug.id === itemRef.id);
    if (item.points !== 0) {
      setTotal(parseInt(total) - item.points);
    }
    const newArray = itemsCalc.filter((tag, idx) => idx !== index);
    setItemsCalc([...newArray]);
  };

  const handleRainLabelsChange = e => {
    setRainlabels(e);
    const sugs = e.split(',');
    const tagCollections = [lowTags, midtags, supertags, bigtags, itemsCalc];
    const collectionsSetters = [
      setLowTags,
      setMidtags,
      setSupertags,
      setBigtags,
      setItemsCalc
    ];
    tagCollections.forEach((collection, idx) => {
      if (collection.length > 0) {
        const filteredCollection = collection.filter(tag =>
          sugs.find(sug => sug.toLowerCase().trim() === tag.text)
        );
        collectionsSetters[idx](filteredCollection);
      }
    });
    calculateTotal();
    const filteredSugs = suggestions.filter(sug =>
      sugs.find(aux => aux === sug.text)
    );
    setSuggestions(filteredSugs);
  };

  const searchTag = text => {
    for (let i = 0; i < suggestions.length; i++) {
      let element = suggestions[i].text.indexOf(text);
      if (element >= 0) {
        return suggestions[i];
      }
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  let modal;
  let resetModal;

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <p style={{ textAlign: 'center' }}>¿Deseas borrar esta sección?</p>
          }
          onCancel={e => setIsModalVisible(false)}
          onConfirm={closeModal}
          cancelButtonText="Cancelar"
          confirmButtonText="Sí, borrar sección"
          defaultFocusedButton="confirm"
          buttonColor="danger"
        >
          <p>Esta acción no se puede deshacer</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  if (isResetModalVisible) {
    resetModal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <p style={{ textAlign: 'center' }}>
              ¿Deseas reiniciar todos
              <br />
              los campos del formulario?
            </p>
          }
          onCancel={() => setIsResetModalVisible(false)}
          onConfirm={() => {
            setIsResetModalVisible(false);
            resetData();
          }}
          cancelButtonText="Cancelar"
          confirmButtonText="Sí, reiniciar formulario"
          defaultFocusedButton="confirm"
          buttonColor="danger"
        >
          <p>Esta acción no se puede deshacer</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  return (
    <>
      {modal}
      {resetModal}
      <EuiFlexItem className="page-template plan-hastag" id="pageTemplate">
        <EuiPageContentHeader style={{ justifyContent: 'center' }}>
          <EditableTitle title={title} setTitle={setTitle} />
        </EuiPageContentHeader>

        <EuiSpacer size="xl" />

        <EuiPageContentBody>
          <ToggleableTextBlock
            content={introduction}
            setContent={setIntroduction}
            showContent={showIntroduction}
            setShowContent={setShowIntroduction}
          />

          <EuiFormRow
            label="Objetivo"
            helpText="Describe el objetivo que deseas lograr"
            fullWidth
          >
            <CustomInput
              placeholder="Ej. Persona ocupadas con mucho estrés, poca planificación, poco tiempo para ocio"
              value={objetive}
              onChangeHandler={setObjetive}
              isFullWidth={true}
            />
          </EuiFormRow>

          <EuiFormRow
            label="Lluvia de ideas de palabras clave"
            helpText="Ideas de palabras claves que obtuviste de la investigación"
            fullWidth
          >
            <CustomInput
              isFullWidth={true}
              placeholder="Ej. Persona ocupadas con mucho estrés, poca planificación, poco tiempo para ocio"
              value={brainstorming}
              onChangeHandler={setBrainstorming}
            />
          </EuiFormRow>

          <EuiFormRow
            label="Lluvia de ideas de etiquetas"
            helpText="Ideas de etiquetas que obtuviste de las palabras clave (separadas por coma)"
            fullWidth
            className=""
          >
            <CustomInput
              isFullWidth={true}
              placeholder=""
              value={rainlabels}
              onChangeHandler={handleRainLabelsChange}
            />
          </EuiFormRow>

          <EuiSpacer style={{ pageBreakBefore: 'always' }} />

          <h3 className="subTitle">Estrategia de distribución de Tags</h3>

          <EuiSpacer size="xl" />

          <EuiFormRow
            fullWidth
            label="Lowtags"
            helpText="Incluye al menos 10 hashtags que tengan como máximo 50 mil seguidores"
          >
            <div className="react-tag border-dashed">
              <ReactTags
                className="notPrint"
                tags={lowTags}
                suggestions={suggestions}
                handleDelete={e => handleDelete(e, lowTags, setLowTags)}
                handleAddition={handleAddition}
                placeholder="Comienza a escribir para seleccionar"
                autofocus={false}
                handleTagClick={handleAddition}
              />
            </div>
          </EuiFormRow>

          <EuiFormRow
            label="Midtags"
            fullWidth
            helpText="Incluye etiquetas que cuentan con más de 100k de publicaciones o seguidores pero no superan el millón"
          >
            <div className="react-tag border-dashed">
              <ReactTags
                className="notPrint"
                tags={midtags}
                suggestions={suggestions}
                handleDelete={e => handleDelete(e, midtags, setMidtags)}
                handleAddition={handleAdditionMidTags}
                placeholder="Comienza a escribir para seleccionar"
                autofocus={false}
                handleTagClick={handleAdditionMidTags}
              />
            </div>
          </EuiFormRow>

          <EuiFormRow
            label="Supertags"
            fullWidth
            helpText="Incluye etiquetas con más del millón de usuarios pero menores a 10 millones"
          >
            <div className="react-tag border-dashed">
              <ReactTags
                className="notPrint"
                tags={supertags}
                suggestions={suggestions}
                handleDelete={e => handleDelete(e, supertags, setSupertags)}
                handleAddition={handleAdditionSuperTags}
                placeholder="Comienza a escribir para seleccionar"
                autofocus={false}
                handleTagClick={handleAdditionSuperTags}
              />
            </div>
          </EuiFormRow>

          <EuiFormRow
            label="Bigtags"
            fullWidth
            helpText="Incluye etiquetas con más de 10 millones de usuarios"
          >
            <div className="react-tag border-dashed">
              <ReactTags
                className="notPrint"
                tags={bigtags}
                suggestions={suggestions}
                handleDelete={e => handleDelete(e, bigtags, setBigtags)}
                handleAddition={handleAdditionBigTags}
                placeholder="Comienza a escribir para seleccionar"
                autofocus={false}
                handleTagClick={handleAdditionBigTags}
              />
            </div>
          </EuiFormRow>
          <EuiSpacer size="xxl" />

          <ToggleableTextBlock
            content={outro}
            setContent={setOutro}
            showContent={showOutro}
            setShowContent={setShowOutro}
          />

          <EuiSpacer size="l" className="notPrint" />
          <hr className="notPrint" />

          <div className="container-fluid content-calc notPrint">
            <h2>Calculadora de Tags (No se imprime)</h2>
            <div className="row">
              <div className="col-sm-10">
                <EuiFormRow label="Tags" fullWidth>
                  <div className="react-tag itemsCalc border-dashed">
                    <ReactTags
                      className="notPrint"
                      tags={itemsCalc}
                      suggestions={suggestions}
                      handleDelete={handleDeleteItemCalc}
                      handleAddition={handleAdditionItemCalc}
                      placeholder="Comienza a escribir para seleccionar"
                      autofocus={false}
                      handleTagClick={handleAdditionItemCalc}
                    />
                  </div>
                </EuiFormRow>
              </div>
              <div className="col-sm-2">
                <EuiFormRow label="Puntos">
                  <span>{total}</span>
                </EuiFormRow>
              </div>
            </div>
          </div>
        </EuiPageContentBody>
      </EuiFlexItem>

      <EuiBottomBar className="notPrint">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiLink href="/templates">Todas las Plantillas</EuiLink> /
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>{props.template.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="refresh"
                  onClick={() => {
                    setIsTitleEditable(false);
                    setIsResetModalVisible(true);
                  }}
                >
                  Resetear
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="save"
                  onClick={() => {
                    setIsTitleEditable(false);
                    saveDataTemplate();
                  }}
                >
                  Guardar
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  color="primary"
                  fill
                  size="s"
                  iconType="check"
                  onClick={() => {
                    setIsTitleEditable(false);
                    makePdf();
                  }}
                >
                  Imprimir
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </>
  );
};
