import React from 'react';
import PropTypes from 'prop-types';
import { EuiButton, EuiFlexGrid, EuiFlexItem, EuiFormRow, EuiIcon, EuiLink, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui';
import { CustomInput } from './CustomInput';

const PureToggleableTextBlock = ({
  showContent,
  setShowContent,
  content,
  setContent,
  placeholder,
  buttonLabel
}) => {
  const defaultButtonLabel = 'Agregar bloque de texto';

  const handleToggleContent = () => {
    setShowContent(!showContent);
    if (showContent && content !== '') setContent('');
  };

  return (
    <React.Fragment>
      {!showContent ? (
        <React.Fragment>
          <EuiFlexGrid className="content-button justify-content-center">
            <EuiFlexItem columns={2}>
              <EuiButton className="notPrint" onClick={handleToggleContent}>
                {buttonLabel || defaultButtonLabel}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGrid>
          <EuiSpacer size="xl" />
        </React.Fragment>
      ) : (
        <EuiFormRow
          className="introduction"
          fullWidth
          labelAppend={
            <EuiText size="xs">
              <EuiLink onClick={handleToggleContent}>
                <EuiToolTip position="top" content="Elimina este bloque">
                  <EuiIcon type="trash" />
                </EuiToolTip>
              </EuiLink>
            </EuiText>
          }
        >
          <CustomInput
            placeholder={placeholder}
            value={content}
            onChangeHandler={setContent}
            isFullWidth={true}
          />
        </EuiFormRow>
      )}
    </React.Fragment>
  );
};

PureToggleableTextBlock.propTypes = {
  showContent: PropTypes.bool.isRequired,
  setShowContent: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string
};

const ToggleableTextBlock = React.memo(PureToggleableTextBlock);

export { ToggleableTextBlock };
