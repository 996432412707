import React, { createContext, useState } from 'react';

export const GlobalContext = createContext(null);

export const GlobalProvider = props => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const value = {
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn,
    selectedTemplate,
    setSelectedTemplate
  };

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
};
