export class Template {
  constructor(
    key,
    name,
    imageUrl,
    shortDescription,
    longDescription,
    instructions,
    component,
    pdfComponent = null,
    showLink = true
  ) {
    this.key = key;
    this.imageUrl = imageUrl;
    this.name = name;
    this.shortDescription = shortDescription;
    this.longDescription = longDescription;
    this.instructions = instructions;
    this.component = component;
    this.pdfComponent = pdfComponent;
    this.showLink = showLink;
  }
}
