import React, { useContext, useEffect, useState } from 'react';
import {
  EuiCollapsibleNavGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiImage,
  EuiIcon,
  EuiPanel,
  EuiPopover
} from '@elastic/eui';
import { Link } from 'react-router-dom';

import { app, login } from '../../config/env';
import { GlobalContext } from '../../context/GlobalContext';
import { logout } from '../../services/auth';

import logoImg from '../../assets/img/mas-plantillas.svg';

import '../../assets/scss/components/navbar.scss';

export const Navbar = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(GlobalContext);

  const showAuthUser = () =>
    context.authUser.displayName && context.authUser.displayName.length > 16
      ? `${context.authUser.displayName.substring(0, 16)}...`
      : context.authUser.displayName;

  const doLogout = e => {
    e.preventDefault();
    logout();
    window.location.href = '/';
  };

  const evaluateInnerWidth = () => {
    if (window.innerWidth < 767) setShowPopover(false);
    else setShowPopover(true);
  };

  window.onresize = () => {
    evaluateInnerWidth();
  };

  useEffect(() => {
    evaluateInnerWidth();
  }, []);

  const logo = (
    <Link to="/templates">
      <EuiIcon
        type={logoImg}
        alt={app.title}
        size={'original'}
        className="logoNavbar"
      />
    </Link>
  );

  const logoutLink = (
    <EuiHeaderLink href="#" onClick={e => doLogout(e)}>
      {login.logoutLabel}
    </EuiHeaderLink>
  );

  const popover = (
    <EuiPopover
      ownFocus
      button={
        <EuiHeaderLink onClick={() => setIsOpen(!isOpen)}>
          <EuiImage
            className="user-avatar"
            url={context.authUser.avatar}
            alt={context.authUser.displayName}
            size={32}
            style={{ borderRadius: '50%' }}
          />
          <span className="logo-blue bold">{context.authUser.displayName}</span>
        </EuiHeaderLink>
      }
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
    >
      {logoutLink}
    </EuiPopover>
  );

  const collapsibleNav = (
    <EuiCollapsibleNavGroup
      background="light"
      isCollapsible={true}
      initialIsOpen={false}
      iconType={null}
      title={showAuthUser()}
    >
      {logoutLink}
    </EuiCollapsibleNavGroup>
  );

  return (
    <EuiPanel paddingSize="m" className="navbar notPrint">
      <div className="container">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem>{logo}</EuiFlexItem>
          <EuiFlexItem>
            <EuiHeaderLinks style={{ justifyContent: 'flex-end' }}>
              {showPopover ? popover : collapsibleNav}
            </EuiHeaderLinks>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPanel>
  );
};
