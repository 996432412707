import { Font, Page, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    paddingVertical: 64,
    paddingHorizontal: 64,
    fontFamily: 'Poppins',
    color: '#3f495f'
  }
});

const PureGenericPage = props => (
  <Page size="A4" style={styles.page} wrap>
    {props.children}
  </Page>
);

const GenericPage = React.memo(PureGenericPage);

export { GenericPage };
