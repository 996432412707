import { storage } from '../config/env';
import { read, write, remove } from '../services/storage';

export const getToken = () => read(storage.token);

export const setToken = token => write(storage.token, token);

export const deleteToken = () => remove(storage.token);

export const setAuthUser = authUser => write(storage.authUser, authUser);

export const getAuthUser = () => read(storage.authUser);

export const deleteUser = () => remove(storage.authUser);