import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  description: {
    fontSize: 8,
    fontFamily: 'Poppins',
    opacity: 0.75,
    width: '100%',
  }
});

const Description = React.memo(props => (
  <Text style={styles.description}>{props.description}</Text>
));

Description.propTypes = {
  description: PropTypes.string.isRequired
};

export { Description };
