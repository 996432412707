import React from 'react';
import {
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { GenericPage, Introduction, } from './utils';

const labels = {
  you: 'Tú',
  competitor: 'Competidor',
  objective: 'Objetivo',
  objectiveDesc:
    'Escribe aquí la principal pregunta que estás tratando de responder con este análisis',
  business: 'Negocio',
  overview: 'Overview',
  tableHeaders: ['Tú', 'Competidor 1', 'Competidor 2'],
  competitiveAdvantage: 'Ventaja competitiva',
  competitiveAdvantageDesc: '¿Qué valor ofreces a tus clientes?',
  marketing: 'Marketing',
  targetAudience: 'Público objetivo',
  marketingStrategies: 'Estrategias de marketing',
  product: 'Producto',
  productsServices: 'Productos y servicios',
  pricing: 'Precio y costes',
  distributionChannels: 'Canales de distribución',
  dafoAnalysis: 'Análisis DAFO',
  dafoAnalysisDesc:
    'Haz esto para ti y tus competidores. Tus fortalezas deben respaldar tus oportunidades y contribuir a lo que te defina como tu ventaja competitiva.',
  dafoTableHeaders: ['Fortalezas', 'Debilidades', 'Oportunidades', 'Amenazas']
};

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  centered: {
    marginHorizontal: 'auto'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
  subtitle: {
    fontSize: 16
  },
  aloneSubtitle: {
    fontSize: 16,
    marginBottom: 10
  },
  subSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10
  },
  description: {
    fontSize: 10,
    marginBottom: 10
  },
  content: {
    fontSize: 12,
    color: '#333'
  },
  tableLike: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tableLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 6
  },
  tableElement4: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%'
  },
  tableContent: {
    color: '#333',
    fontSize: 10,
    width: '100%',
    padding: 6
  },
  spacer: {
    padding: 16
  },
  spacerS: {
    padding: 8
  },
  spacerXs: {
    padding: 4
  },
  textareaTitle: {
    fontSize: 13,
    borderBottom: '1pt solid #333'
  }
});

const CompetitorList = props => {
  const {arr, competitors} = props;
  return (
    <React.Fragment>
      <Text style={styles.textareaTitle}>{labels.you}</Text>
      <View style={styles.spacerXs} />
      <Text style={styles.content}>{arr[0]}</Text>
      <View style={styles.spacerS} />
      {competitors.map((competitor, idx) => (
        <React.Fragment key={competitor}>
          <Text style={styles.textareaTitle}>{`${labels.competitor} ${
            idx + 1
          }`}</Text>
          <View style={styles.spacerXs} />
          <Text style={styles.content} key={competitor}>
            {arr[idx + 1]}
          </Text>
          <View style={styles.spacerS} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const Competitors = props => {
  return (
    <Document>
      <GenericPage>
        <View style={styles.centered}>
          <Text>{props.data.title}</Text>
        </View>
        <Introduction
          showField={props.data.introduction.show}
          fieldData={props.data.introduction.value}
        />
        <View style={styles.spacer} />
        <View style={styles.section}>
          <Text style={styles.subtitle}>{labels.objective}</Text>
          <Text style={styles.description}>{labels.objectiveDesc}</Text>
          <Text style={styles.content}>{props.data.objective}</Text>
        </View>
        <View style={styles.spacerS} />
        <Text style={styles.aloneSubtitle}>{labels.business}</Text>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.overview}</Text>
          <CompetitorList
            arr={props.data.overview}
            competitors={props.data.competitors}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.competitiveAdvantage}</Text>
          <Text style={styles.description}>
            {labels.competitiveAdvantageDesc}
          </Text>
          <CompetitorList
            arr={props.data.competitiveAdvantage}
            competitors={props.data.competitors}
          />
        </View>
        <Text style={styles.aloneSubtitle}>{labels.marketing}</Text>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.targetAudience}</Text>
          <CompetitorList
            arr={props.data.targetAudience}
            competitors={props.data.competitors}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.marketingStrategies}</Text>
          <CompetitorList
            arr={props.data.marketingStrategies}
            competitors={props.data.competitors}
          />
        </View>
        <Text style={styles.aloneSubtitle}>{labels.product}</Text>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.productsServices}</Text>
          <CompetitorList
            arr={props.data.productsServices}
            competitors={props.data.competitors}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.pricing}</Text>
          <CompetitorList
            arr={props.data.pricing}
            competitors={props.data.competitors}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.subSubtitle}>{labels.distributionChannels}</Text>
          <CompetitorList
            arr={props.data.distributionChannels}
            competitors={props.data.competitors}
          />
        </View>
        <Text style={styles.subtitle}>{labels.dafoAnalysis}</Text>
        <Text style={styles.description}>{labels.dafoAnalysisDesc}</Text>
        <View style={styles.section}>
          <Text style={styles.textareaTitle}>{labels.you}</Text>
          <View style={styles.tableLike}>
            {labels.dafoTableHeaders.map((header, idx) => (
              <View key={header} style={styles.tableElement4}>
                <Text style={styles.tableLabel}>{header}</Text>
                <Text style={styles.tableContent}>
                  {props.data.dafo[0][idx]}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.spacer} />
          {props.data.competitors.map((competitor, i) => (
            <React.Fragment key={competitor}>
              <Text style={styles.textareaTitle}>{`${labels.competitor} ${
                i + 1
              }`}</Text>
              <View style={styles.tableLike}>
                {labels.dafoTableHeaders.map((header, j) => (
                  <View key={j} style={styles.tableElement4}>
                    <Text style={styles.tableLabel}>{header}</Text>
                    <Text style={styles.tableContent}>
                      {props.data.dafo[i][j]}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={styles.spacer} />
            </React.Fragment>
          ))}
        </View>
        <Introduction
          showField={props.data.conclusion.show}
          fieldData={props.data.conclusion.value}
        />
      </GenericPage>
    </Document>
  );
};
