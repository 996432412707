import React, { useState, Fragment } from 'react';
import { read as getData, write as setData, remove as removeData } from '../../services/storage';
import {
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiTitle,
  EuiFormRow,
  EuiBottomBar,
  EuiText,
  EuiLink,
  EuiButtonEmpty,
  EuiButton,
  EuiGlobalToastList,
  EuiOverlayMask,
  EuiConfirmModal,
  EuiSpacer,

} from '@elastic/eui';
import { CustomInput } from '../utils/CustomInput';
import { EditableTitle } from '../utils/EditableTitle';
import { htmlIdGenerator } from '@elastic/eui/lib/services';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';
import { storage } from '../../config/env';

export const TemplateHeuristic = props => {
  const localData = getData(props.template.key) || {};
  const [title, setTitle] = useState(localData.title || props.template.name);
  const [toasts, setToasts] = useState([]);
  
  const [, setIsTitleEditable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  const [introduction, setIntroducction] = useState(localData.introduction || '');
  const [showIntroducction, setShowIntroducction] = useState(localData.showIntroducction || false);

  const [conclusion, setConclusion] = useState(localData.conclusion || '');
  const [showConclusion, setShowConclusion] = useState(localData.showConclusion || false);

  
  const [relevanceOneProblem, setRelevanceOneProblem] = useState(localData.relevanceOneProblem || false);
  const [relevanceOneUrgent, setRelevanceOneUrgent] = useState(localData.relevanceOneUrgent || false);
  const [relevanceOneImpac, setRelevanceOneImpac] = useState(localData.relevanceOneImpac || '');
  const [relevanceOneObservation, setRelevanceOneObservation] = useState(localData.relevanceOneObservation || '');

  const [relevanceTwoProblem, setRelevanceTwoProblem] = useState(localData.relevanceTwoProblem || false);
  const [relevanceTwoUrgent, setRelevanceTwoUrgent] = useState(localData.relevanceTwoUrgent || false);
  const [relevanceTwoImpac, setRelevanceTwoImpac] = useState(localData.relevanceTwoImpac || '');
  const [relevanceTwoObservation, setRelevanceTwoObservation] = useState(localData.relevanceTwoObservation || '');

  const [relevanceThreeProblem, setRelevanceThreeProblem] = useState(localData.relevanceThreeProblem || false);
  const [relevanceThreeUrgent, setRelevanceThreeUrgent] = useState(localData.relevanceThreeUrgent || false);
  const [relevanceThreeImpac, setRelevanceThreeImpac] = useState(localData.relevanceThreeImpac || '');
  const [relevanceThreeObservation, setRelevanceThreeObservation] = useState(localData.relevanceThreeObservation || '');

  const [relevanceFourthProblem, setRelevanceFourthProblem] = useState(localData.relevanceFourthProblem || false);
  const [relevanceFourthUrgent, setRelevanceFourthUrgent] = useState(localData.relevanceFourthUrgent || false);
  const [relevanceFourthImpac, setRelevanceFourthImpac] = useState(localData.relevanceFourthImpac || '');
  const [relevanceFourthObservation, setRelevanceFourthObservation] = useState(localData.relevanceFourthObservation || '');

  //Design
  const [designOneProblem, setDesignOneProblem] = useState(localData.designOneProblem || false);
  const [designOneUrgent, setDesignOneUrgent] = useState(localData.designOneUrgent || false);
  const [designOneImpac, setDesignOneImpac] = useState(localData.designOneImpac || '');
  const [designOneObservation, setDesignOneObservation] = useState(localData.designOneObservation || '');

  const [designTwoProblem, setDesignTwoProblem] = useState(localData.designTwoProblem || false);
  const [designTwoUrgent, setDesignTwoUrgent] = useState(localData.designTwoUrgent || false);
  const [designTwoImpac, setDesignTwoImpac] = useState(localData.designTwoImpac || '');
  const [designTwoObservation, setDesignTwoObservation] = useState(localData.designTwoObservation || '');

  const [designThreeProblem, setDesignThreeProblem] = useState(localData.designThreeProblem || false);
  const [designThreeUrgent, setDesignThreeUrgent] = useState(localData.designThreeUrgent || false);
  const [designThreeImpac, setDesignThreeImpac] = useState(localData.designThreeImpac || '');
  const [designThreeObservation, setDesignThreeObservation] = useState(localData.designThreeObservation || '');

  const [designFourthProblem, setDesignFourthProblem] = useState(localData.designFourthProblem || false);
  const [designFourthUrgent, setDesignFourthUrgent] = useState(localData.designFourthUrgent || false);
  const [designFourthImpac, setDesignFourthImpac] = useState(localData.designFourthImpac || '');
  const [designFourthObservation, setDesignFourthObservation] = useState(localData.designFourthObservation || '');

  const [designFiveProblem, setDesignFiveProblem] = useState(localData.designFiveProblem || false);
  const [designFiveUrgent, setDesignFiveUrgent] = useState(localData.designFiveUrgent || false);
  const [designFiveImpac, setDesignFiveImpac] = useState(localData.designFiveImpac || '');
  const [designFiveObservation, setDesignFiveObservation] = useState(localData.designFiveObservation || '');

  const [designSixProblem, setDesignSixProblem] = useState(localData.designSixProblem || false);
  const [designSixUrgent, setDesignSixUrgent] = useState(localData.designSixUrgent || false);
  const [designSixImpac, setDesignSixImpac] = useState(localData.designSixImpac || '');
  const [designSixObservation, setDesignSixObservation] = useState(localData.designSixObservation || '');

  const [designSevenProblem, setDesignSevenProblem] = useState(localData.designSevenProblem || false);
  const [designSevenUrgent, setDesignSevenUrgent] = useState(localData.designSevenUrgent || false);
  const [designSevenImpac, setDesignSevenImpac] = useState(localData.designSevenImpac || '');
  const [designSevenObservation, setDesignSevenObservation] = useState(localData.designSevenObservation || '');

  const [designEightProblem, setDesignEightProblem] = useState(localData.designEightProblem || false);
  const [designEightUrgent, setDesignEightUrgent] = useState(localData.designEightUrgent || false);
  const [designEightImpac, setDesignEightImpac] = useState(localData.designEightImpac || '');
  const [designEightObservation, setDesignEightObservation] = useState(localData.designEightObservation || '');

  //Friction
  const [frictionOneProblem, setFrictionOneProblem] = useState(localData.frictionOneProblem || false);
  const [frictionOneUrgent, setFrictionOneUrgent] = useState(localData.frictionOneUrgent || false);
  const [frictionOneImpac, setFrictionOneImpac] = useState(localData.frictionOneImpac || '');
  const [frictionOneObservation, setFrictionOneObservation] = useState(localData.frictionOneObservation || '');

  const [frictionTwoProblem, setFrictionTwoProblem] = useState(localData.frictionTwoProblem || false);
  const [frictionTwoUrgent, setFrictionTwoUrgent] = useState(localData.frictionTwoUrgent || false);
  const [frictionTwoImpac, setFrictionTwoImpac] = useState(localData.frictionTwoImpac || '');
  const [frictionTwoObservation, setFrictionTwoObservation] = useState(localData.frictionTwoObservation || '');

  const [frictionThreeProblem, setFrictionThreeProblem] = useState(localData.frictionThreeProblem || false);
  const [frictionThreeUrgent, setFrictionThreeUrgent] = useState(localData.frictionThreeUrgent || false);
  const [frictionThreeImpac, setFrictionThreeImpac] = useState(localData.frictionThreeImpac || '');
  const [frictionThreeObservation, setFrictionThreeObservation] = useState(localData.frictionThreeObservation || '');

  const [frictionFourthProblem, setFrictionFourthProblem] = useState(localData.frictionFourthProblem || false);
  const [frictionFourthUrgent, setFrictionFourthUrgent] = useState(localData.frictionFourthUrgent || false);
  const [frictionFourthImpac, setFrictionFourthImpac] = useState(localData.frictionFourthImpac || '');
  const [frictionFourthObservation, setFrictionFourthObservation] = useState(localData.frictionFourthObservation || '');

  const [frictionFiveProblem, setFrictionFiveProblem] = useState(localData.frictionFiveProblem || false);
  const [frictionFiveUrgent, setFrictionFiveUrgent] = useState(localData.frictionFiveUrgent || false);
  const [frictionFiveImpac, setFrictionFiveImpac] = useState(localData.frictionFiveImpac || '');
  const [frictionFiveObservation, setFrictionFiveObservation] = useState(localData.frictionFiveObservation || '');

  const [frictionSixProblem, setFrictionSixProblem] = useState(localData.frictionSixProblem || false);
  const [frictionSixUrgent, setFrictionSixUrgent] = useState(localData.frictionSixUrgent || false);
  const [frictionSixImpac, setFrictionSixImpac] = useState(localData.frictionSixImpac || '');
  const [frictionSixObservation, setFrictionSixObservation] = useState(localData.frictionSixObservation || '');

  const [frictionSevenProblem, setFrictionSevenProblem] = useState(localData.frictionSevenProblem || false);
  const [frictionSevenUrgent, setFrictionSevenUrgent] = useState(localData.frictionSevenUrgent || false);
  const [frictionSevenImpac, setFrictionSevenImpac] = useState(localData.frictionSevenImpac || '');
  const [frictionSevenObservation, setFrictionSevenObservation] = useState(localData.frictionSevenObservation || '');

  const [frictionEightProblem, setFrictionEightProblem] = useState(localData.frictionEightProblem || false);
  const [frictionEightUrgent, setFrictionEightUrgent] = useState(localData.frictionEightUrgent || false);
  const [frictionEightImpac, setFrictionEightImpac] = useState(localData.frictionEightImpac || '');
  const [frictionEightObservation, setFrictionEightObservation] = useState(localData.frictionEightObservation || '');

  const [frictionNineProblem, setFrictionNineProblem] = useState(localData.frictionNineProblem || false);
  const [frictionNineUrgent, setFrictionNineUrgent] = useState(localData.frictionNineUrgent || false);
  const [frictionNineImpac, setFrictionNineImpac] = useState(localData.frictionNineImpac || '');
  const [frictionNineObservation, setFrictionNineObservation] = useState(localData.frictionNineObservation || '');

  const [frictionTenProblem, setFrictionTenProblem] = useState(localData.frictionTenProblem || false);
  const [frictionTenUrgent, setFrictionTenUrgent] = useState(localData.frictionTenUrgent || false);
  const [frictionTenImpac, setFrictionTenImpac] = useState(localData.frictionTenImpac || '');
  const [frictionTenObservation, setFrictionTenObservation] = useState(localData.frictionTenObservation || '');

  //Distractions
  const [distractionsOneProblem, setDistractionsOneProblem] = useState(localData.distractionsOneProblem || false);
  const [distractionsOneUrgent, setDistractionsOneUrgent] = useState(localData.distractionsOneUrgent || false);
  const [distractionsOneImpac, setDistractionsOneImpac] = useState(localData.distractionsOneImpac || '');
  const [distractionsOneObservation, setDistractionsOneObservation] = useState(localData.distractionsOneObservation || '');

  const [distractionsTwoProblem, setDistractionsTwoProblem] = useState(localData.distractionsTwoProblem || false);
  const [distractionsTwoUrgent, setDistractionsTwoUrgent] = useState(localData.distractionsTwoUrgent || false);
  const [distractionsTwoImpac, setDistractionsTwoImpac] = useState(localData.distractionsTwoImpac || '');
  const [distractionsTwoObservation, setDistractionsTwoObservation] = useState(localData.distractionsTwoObservation || '');

  const [distractionsThreeProblem, setDistractionsThreeProblem] = useState(localData.distractionsThreeProblem || false);
  const [distractionsThreeUrgent, setDistractionsThreeUrgent] = useState(localData.distractionsThreeUrgent || false);
  const [distractionsThreeImpac, setDistractionsThreeImpac] = useState(localData.distractionsThreeImpac || '');
  const [distractionsThreeObservation, setDistractionsThreeObservation] = useState(localData.distractionsThreeObservation || '');

  const [distractionsFourthProblem, setDistractionsFourthProblem] = useState(localData.distractionsFourthProblem || false);
  const [distractionsFourthUrgent, setDistractionsFourthUrgent] = useState(localData.distractionsFourthUrgent || false);
  const [distractionsFourthImpac, setDistractionsFourthImpac] = useState(localData.distractionsFourthImpac || '');
  const [distractionsFourthObservation, setDistractionsFourthObservation] = useState(localData.distractionsFourthObservation || '');

  const [distractionsFiveProblem, setDistractionsFiveProblem] = useState(localData.distractionsFiveProblem || false);
  const [distractionsFiveUrgent, setDistractionsFiveUrgent] = useState(localData.distractionsFiveUrgent || false);
  const [distractionsFiveImpac, setDistractionsFiveImpac] = useState(localData.distractionsFiveImpac || '');
  const [distractionsFiveObservation, setDistractionsFiveObservation] = useState(localData.distractionsFiveObservation || '');

  const [distractionsSixProblem, setDistractionsSixProblem] = useState(localData.distractionsSixProblem || false);
  const [distractionsSixUrgent, setDistractionsSixUrgent] = useState(localData.distractionsSixUrgent || false);
  const [distractionsSixImpac, setDistractionsSixImpac] = useState(localData.distractionsSixImpac || '');
  const [distractionsSixObservation, setDistractionsSixObservation] = useState(localData.distractionsSixObservation || '');

  const [distractionsSevenProblem, setDistractionsSevenProblem] = useState(localData.distractionsSevenProblem || false);
  const [distractionsSevenUrgent, setDistractionsSevenUrgent] = useState(localData.distractionsSevenUrgent || false);
  const [distractionsSevenImpac, setDistractionsSevenImpac] = useState(localData.distractionsSevenImpac || '');
  const [distractionsSevenObservation, setDistractionsSevenObservation] = useState(localData.distractionsSevenObservation || '');

  //Motivations
  const [motivationOneProblem, setMotivationOneProblem] = useState(localData.motivationOneProblem || false);
  const [motivationOneUrgent, setMotivationOneUrgent] = useState(localData.motivationOneUrgent || false);
  const [motivationOneImpac, setMotivationOneImpac] = useState(localData.motivationOneImpac || '');
  const [motivationOneObservation, setMotivationOneObservation] = useState(localData.motivationOneObservation || '');

  const [motivationTwoProblem, setMotivationTwoProblem] = useState(localData.motivationTwoProblem || false);
  const [motivationTwoUrgent, setMotivationTwoUrgent] = useState(localData.motivationTwoUrgent || false);
  const [motivationTwoImpac, setMotivationTwoImpac] = useState(localData.motivationTwoImpac || '');
  const [motivationTwoObservation, setMotivationTwoObservation] = useState(localData.motivationTwoObservation || '');

  const [motivationThreeProblem, setMotivationThreeProblem] = useState(localData.motivationThreeProblem || false);
  const [motivationThreeUrgent, setMotivationThreeUrgent] = useState(localData.motivationThreeUrgent || false);
  const [motivationThreeImpac, setMotivationThreeImpac] = useState(localData.motivationThreeImpac || '');
  const [motivationThreeObservation, setMotivationThreeObservation] = useState(localData.motivationThreeObservation || '');

  const [motivationFourthProblem, setMotivationFourthProblem] = useState(localData.motivationFourthProblem || false);
  const [motivationFourthUrgent, setMotivationFourthUrgent] = useState(localData.motivationFourthUrgent || false);
  const [motivationFourthImpac, setMotivationFourthImpac] = useState(localData.motivationFourthImpac || '');
  const [motivationFourthObservation, setMotivationFourthObservation] = useState(localData.motivationFourthObservation || '');

  const [motivationFiveProblem, setMotivationFiveProblem] = useState(localData.motivationFiveProblem || false);
  const [motivationFiveUrgent, setMotivationFiveUrgent] = useState(localData.motivationFiveUrgent || false);
  const [motivationFiveImpac, setMotivationFiveImpac] = useState(localData.motivationFiveImpac || '');
  const [motivationFiveObservation, setMotivationFiveObservation] = useState(localData.motivationFiveObservation || '');

  const [motivationSixProblem, setMotivationSixProblem] = useState(localData.motivationSixProblem || false);
  const [motivationSixUrgent, setMotivationSixUrgent] = useState(localData.motivationSixUrgent || false);
  const [motivationSixImpac, setMotivationSixImpac] = useState(localData.motivationSixImpac || '');
  const [motivationSixObservation, setMotivationSixObservation] = useState(localData.motivationSixObservation || '');

  const [motivationSevenProblem, setMotivationSevenProblem] = useState(localData.motivationSevenProblem || false);
  const [motivationSevenUrgent, setMotivationSevenUrgent] = useState(localData.motivationSevenUrgent || false);
  const [motivationSevenImpac, setMotivationSevenImpac] = useState(localData.motivationSevenImpac || '');
  const [motivationSevenObservation, setMotivationSevenObservation] = useState(localData.motivationSevenObservation || '');

  const [motivationEightProblem, setMotivationEightProblem] = useState(localData.motivationEightProblem || false);
  const [motivationEightUrgent, setMotivationEightUrgent] = useState(localData.motivationEightUrgent || false);
  const [motivationEightImpac, setMotivationEightImpac] = useState(localData.motivationEightImpac || '');
  const [motivationEightObservation, setMotivationEightObservation] = useState(localData.motivationEightObservation || '');

  //Sales
  const [salesOneProblem, setSalesOneProblem] = useState(localData.salesOneProblem || false);
  const [salesOneUrgent, setSalesOneUrgent] = useState(localData.salesOneUrgent || false);
  const [salesOneImpac, setSalesOneImpac] = useState(localData.salesOneImpac || '');
  const [salesOneObservation, setSalesOneObservation] = useState(localData.salesOneObservation || '');

  const [salesTwoProblem, setSalesTwoProblem] = useState(localData.salesTwoProblem || false);
  const [salesTwoUrgent, setSalesTwoUrgent] = useState(localData.salesTwoUrgent || false);
  const [salesTwoImpac, setSalesTwoImpac] = useState(localData.salesTwoImpac || '');
  const [salesTwoObservation, setSalesTwoObservation] = useState(localData.salesTwoObservation || '');

  const [salesThreeProblem, setSalesThreeProblem] = useState(localData.salesThreeProblem || false);
  const [salesThreeUrgent, setSalesThreeUrgent] = useState(localData.salesThreeUrgent || false);
  const [salesThreeImpac, setSalesThreeImpac] = useState(localData.salesThreeImpac || '');
  const [salesThreeObservation, setSalesThreeObservation] = useState(localData.salesThreeObservation || '');
  
  const formRelevanceQuestions = [
    {
      title: "¿El título coincide con el contenido de la página?",
      problem: relevanceOneProblem,
      setProblem: e => { setRelevanceOneProblem(e.target.checked) },
      impact: relevanceOneImpac,
      setImpact: setRelevanceOneImpac,
      urgent: relevanceOneUrgent,
      setUrgent: e => { setRelevanceOneUrgent(e.target.checked) },
      observation: relevanceOneObservation,
      setObservation: setRelevanceOneObservation,
    },
    
    {
      title: "¿Los botones de llamada a la acción coinciden con lo que obtendrán al hacer clic?",
      problem: relevanceTwoProblem,
      setProblem: e => { setRelevanceTwoProblem(e.target.checked) },
      impact: relevanceTwoImpac,
      setImpact: setRelevanceTwoImpac,
      urgent: relevanceTwoUrgent,
      setUrgent: e => { setRelevanceTwoUrgent(e.target.checked) },
      observation: relevanceTwoObservation,
      setObservation: setRelevanceTwoObservation,
    }, {
      title: "¿Las imágenes de la página son relevantes para el contenido?",
      problem: relevanceThreeProblem,
      setProblem: e => { setRelevanceThreeProblem(e.target.checked) },
      impact: relevanceThreeImpac,
      setImpact: setRelevanceThreeImpac,
      urgent: relevanceThreeUrgent,
      setUrgent: e => { setRelevanceThreeUrgent(e.target.checked) },
      observation: relevanceThreeObservation,
      setObservation: setRelevanceThreeObservation,
    }, {
      title: "Si el usuario proviene de un sitio externo (ej búsqueda de Google, PPC, referencia, etc.), ¿Percibirá que existe continuidad?",
      problem: relevanceFourthProblem,
      setProblem: e => { setRelevanceFourthProblem(e.target.checked) },
      impact: relevanceFourthImpac,
      setImpact: setRelevanceFourthImpac,
      urgent: relevanceFourthUrgent,
      setUrgent: e => { setRelevanceFourthUrgent(e.target.checked) },
      observation: relevanceFourthObservation,
      setObservation: setRelevanceFourthObservation,
    }
  ];
  const formDesignQuestions = [
    {
      title: "¿Existe una jerarquía visual sólida? ¿Favorece a la acción principal de la página?",
      problem: designOneProblem,
      setProblem: e => { setDesignOneProblem(e.target.checked) },
      impact: designOneImpac,
      setImpact: setDesignOneImpac,
      urgent: designOneUrgent,
      setUrgent: e => { setDesignOneUrgent(e.target.checked) },
      observation: designOneObservation,
      setObservation: setDesignOneObservation,
    },
    {
      title: "¿Son las cosas menos importantes también menos importantes en cuanto al diseño?",
      problem: designTwoProblem,
      setProblem: e => { setDesignTwoProblem(e.target.checked) },
      impact: designTwoImpac,
      setImpact: setDesignTwoImpac,
      urgent: designTwoUrgent,
      setUrgent: e => { setDesignTwoUrgent(e.target.checked) },
      observation: designTwoObservation,
      setObservation: setDesignTwoObservation,
    }, {
      title: "¿Hay suficiente espacio en blanco para llamar la atención sobre los elementos más importantes?",
      problem: designThreeProblem,
      setProblem: e => { setDesignThreeProblem(e.target.checked) },
      impact: designThreeImpac,
      setImpact: setDesignThreeImpac,
      urgent: designThreeUrgent,
      setUrgent: e => { setDesignThreeUrgent(e.target.checked) },
      observation: designThreeObservation,
      setObservation: setDesignThreeObservation,
    }, {
      title: "¿Existen imágenes que refuerzan el contenido?",
      problem: designFourthProblem,
      setProblem: e => { setDesignFourthProblem(e.target.checked) },
      impact: designFourthImpac,
      setImpact: setDesignFourthImpac,
      urgent: designFourthUrgent,
      setUrgent: e => { setDesignFourthUrgent(e.target.checked) },
      observation: designFourthObservation,
      setObservation: setDesignFourthObservation,
    }, {
      title: "¿La llamada a la acción se destaca lo suficiente?",
      problem: designFiveProblem,
      setProblem: e => { setDesignFiveProblem(e.target.checked) },
      impact: designFiveImpac,
      setImpact: setDesignFiveImpac,
      urgent: designFiveUrgent,
      setUrgent: e => { setDesignFiveUrgent(e.target.checked) },
      observation: designFiveObservation,
      setObservation: setDesignFiveObservation,
    }, {
      title: "¿Está la información de máxima prioridad presente above the fold?",
      problem: designSixProblem,
      setProblem: e => { setDesignSixProblem(e.target.checked) },
      impact: designSixImpac,
      setImpact: setDesignSixImpac,
      urgent: designSixUrgent,
      setUrgent: e => { setDesignSixUrgent(e.target.checked) },
      observation: designSixObservation,
      setObservation: setDesignSixObservation,
    }, {
      title: "Si hay más información más abajo en la página, ¿está claro para los usuarios que deben hacer scroll?",
      problem: designSevenProblem,
      setProblem: e => { setDesignSevenProblem(e.target.checked) },
      impact: designSevenImpac,
      setImpact: setDesignSevenImpac,
      urgent: designSevenUrgent,
      setUrgent: e => { setDesignSevenUrgent(e.target.checked) },
      observation: designSevenObservation,
      setObservation: setDesignSevenObservation,
    }, {
      title: "¿El tamaño de la fuente del texto del cuerpo es lo suficientemente grande para facilitar la lectura?",
      problem: designEightProblem,
      setProblem: e => { setDesignEightProblem(e.target.checked) },
      impact: designEightImpac,
      setImpact: setDesignEightImpac,
      urgent: designEightUrgent,
      setUrgent: e => { setDesignEightUrgent(e.target.checked) },
      observation: designEightObservation,
      setObservation: setDesignEightObservation,
    }
  ];
  const formFrictionQuestions = [
    {
      title: "¿Existen procesos largos y complicados en el sitio web?",
      problem: frictionOneProblem,
      setProblem: e => { setFrictionOneProblem(e.target.checked) },
      impact: frictionOneImpac,
      setImpact: setFrictionOneImpac,
      urgent: frictionOneUrgent,
      setUrgent: e => { setFrictionOneUrgent(e.target.checked) },
      observation: frictionOneObservation,
      setObservation: setFrictionOneObservation,
    }, {
      title: "¿Se solicita información sensible de manera innecesaria?",
      problem: frictionTwoProblem,
      setProblem: e => { setFrictionTwoProblem(e.target.checked) },
      impact: frictionTwoImpac,
      setImpact: setFrictionTwoImpac,
      urgent: frictionTwoUrgent,
      setUrgent: e => { setFrictionTwoUrgent(e.target.checked) },
      observation: frictionTwoObservation,
      setObservation: setFrictionTwoObservation,
    }, {
      title: "¿El sitio carga a una velocidad razonable?",
      problem: frictionThreeProblem,
      setProblem: e => { setFrictionThreeProblem(e.target.checked) },
      impact: frictionThreeImpac,
      setImpact: setFrictionThreeImpac,
      urgent: frictionThreeUrgent,
      setUrgent: e => { setFrictionThreeUrgent(e.target.checked) },
      observation: frictionThreeObservation,
      setObservation: setFrictionThreeObservation,
    }, {
      title: "¿Es fácil encontrar elementos y contenido?",
      problem: frictionFourthProblem,
      setProblem: e => { setFrictionFourthProblem(e.target.checked) },
      impact: frictionFourthImpac,
      setImpact: setFrictionFourthImpac,
      urgent: frictionFourthUrgent,
      setUrgent: e => { setFrictionFourthUrgent(e.target.checked) },
      observation: frictionFourthObservation,
      setObservation: setFrictionFourthObservation,
    }, {
      title: "¿El diseño del sitio web luce seguro, legítimo  y profesional?",
      problem: frictionFiveProblem,
      setProblem: e => { setFrictionFiveProblem(e.target.checked) },
      impact: frictionFiveImpac,
      setImpact: setFrictionFiveImpac,
      urgent: frictionFiveUrgent,
      setUrgent: e => { setFrictionFiveUrgent(e.target.checked) },
      observation: frictionFiveObservation,
      setObservation: setFrictionFiveObservation,
    }, {
      title: "¿Las  imágenes lucen de stock o falsas?",
      problem: frictionSixProblem,
      setProblem: e => { setFrictionSixProblem(e.target.checked) },
      impact: frictionSixImpac,
      setImpact: setFrictionSixImpac,
      urgent: frictionSixUrgent,
      setUrgent: e => { setFrictionSixUrgent(e.target.checked) },
      observation: frictionSixObservation,
      setObservation: setFrictionSixObservation,
    }, {
      title: "¿Existen errores tipográficos y mala redacción?",
      problem: frictionSevenProblem,
      setProblem: e => { setFrictionSevenProblem(e.target.checked) },
      impact: frictionSevenImpac,
      setImpact: setFrictionSevenImpac,
      urgent: frictionSevenUrgent,
      setUrgent: e => { setFrictionSevenUrgent(e.target.checked) },
      observation: frictionSevenObservation,
      setObservation: setFrictionSevenObservation,
    }, {
      title: "¿Existen problemas evidentes de usabilidad?",
      problem: frictionEightProblem,
      setProblem: e => { setFrictionEightProblem(e.target.checked) },
      impact: frictionEightImpac,
      setImpact: setFrictionEightImpac,
      urgent: frictionEightUrgent,
      setUrgent: e => { setFrictionEightUrgent(e.target.checked) },
      observation: frictionEightObservation,
      setObservation: setFrictionEightObservation,
    }, {
      title: "¿Existen errores técnicos, problemas al visualizar el sitio en diferentes navegadores y dispositivos?",
      problem: frictionNineProblem,
      setProblem: e => { setFrictionNineProblem(e.target.checked) },
      impact: frictionNineImpac,
      setImpact: setFrictionNineImpac,
      urgent: frictionNineUrgent,
      setUrgent: e => { setFrictionNineUrgent(e.target.checked) },
      observation: frictionNineObservation,
      setObservation: setFrictionNineObservation,
    }, {
      title: "¿Existen bajo contraste entre el texto y los colores de fondo, mala legibilidad?",
      problem: frictionTenProblem,
      setProblem: e => { setFrictionTenProblem(e.target.checked) },
      impact: frictionTenImpac,
      setImpact: setFrictionTenImpac,
      urgent: frictionTenUrgent,
      setUrgent: e => { setFrictionTenUrgent(e.target.checked) },
      observation: frictionTenObservation,
      setObservation: setFrictionTenObservation,
    }

  ]
  const formDistractionsQuestions = [
    {
      title: "¿Hay elementos en movimiento de forma innecesaria?",
      problem: distractionsOneProblem,
      setProblem: e => { setDistractionsOneProblem(e.target.checked) },
      impact: distractionsOneImpac,
      setImpact: setDistractionsOneImpac,
      urgent: distractionsOneUrgent,
      setUrgent: e => { setDistractionsOneUrgent(e.target.checked) },
      observation: distractionsOneObservation,
      setObservation: setDistractionsOneObservation,
    },
    {
      title: "¿Existen elementos de la página que no contribuyen a que las personas realicen las acción principal?",
      problem: distractionsTwoProblem,
      setProblem: e => { setDistractionsTwoProblem(e.target.checked) },
      impact: distractionsTwoImpac,
      setImpact: setDistractionsTwoImpac,
      urgent: distractionsTwoUrgent,
      setUrgent: e => { setDistractionsTwoUrgent(e.target.checked) },
      observation: distractionsTwoObservation,
      setObservation: setDistractionsTwoObservation,
    }, {
      title: "¿Qué podríamos eliminar de la página sin comprometer su rendimiento?",
      problem: distractionsThreeProblem,
      setProblem: e => { setDistractionsThreeProblem(e.target.checked) },
      impact: distractionsThreeImpac,
      setImpact: setDistractionsThreeImpac,
      urgent: distractionsThreeUrgent,
      setUrgent: e => { setDistractionsThreeUrgent(e.target.checked) },
      observation: distractionsThreeObservation,
      setObservation: setDistractionsThreeObservation,
    }, {
      title: "En las páginas de pago (embudo de conversión), ¿hay elementos de navegación innecesarios?",
      problem: distractionsFourthProblem,
      setProblem: e => { setDistractionsFourthProblem(e.target.checked) },
      impact: distractionsFourthImpac,
      setImpact: setDistractionsFourthImpac,
      urgent: distractionsFourthUrgent,
      setUrgent: e => { setDistractionsFourthUrgent(e.target.checked) },
      observation: distractionsFourthObservation,
      setObservation: setDistractionsFourthObservation,
    }, {
      title: "¿El encabezado superior está ocupando demasiado espacio valioso en la pantalla?",
      problem: distractionsFiveProblem,
      setProblem: e => { setDistractionsFiveProblem(e.target.checked) },
      impact: distractionsFiveImpac,
      setImpact: setDistractionsFiveImpac,
      urgent: distractionsFiveUrgent,
      setUrgent: e => { setDistractionsFiveUrgent(e.target.checked) },
      observation: distractionsFiveObservation,
      setObservation: setDistractionsFiveObservation,
    }, {
      title: "¿Hay elementos visuales de poca importancia con gran jerarquía visual?",
      problem: distractionsSixProblem,
      setProblem: e => { setDistractionsSixProblem(e.target.checked) },
      impact: distractionsSixImpac,
      setImpact: setDistractionsSixImpac,
      urgent: distractionsSixUrgent,
      setUrgent: e => { setDistractionsSixUrgent(e.target.checked) },
      observation: distractionsSixObservation,
      setObservation: setDistractionsSixObservation,
    }, {
      title: "¿Existen elementos de copy que no se refieran a la acción específica que queremos que los usuarios realicen?",
      problem: distractionsSevenProblem,
      setProblem: e => { setDistractionsSevenProblem(e.target.checked) },
      impact: distractionsSevenImpac,
      setImpact: setDistractionsSevenImpac,
      urgent: distractionsSevenUrgent,
      setUrgent: e => { setDistractionsSevenUrgent(e.target.checked) },
      observation: distractionsSevenObservation,
      setObservation: setDistractionsSevenObservation,
    }
  ];
  const formMotivationQuestions = [
    {
      title: "¿Existe una oferta clara basada en beneficios?",
      problem: motivationOneProblem,
      setProblem: e => { setMotivationOneProblem(e.target.checked)},
      impact: motivationOneImpac,
      setImpact: setMotivationOneImpac,
      urgent: motivationOneUrgent,
      setUrgent: e => { setMotivationOneUrgent(e.target.checked) },
      observation: motivationOneObservation,
      setObservation: setMotivationOneObservation,
    },
    {
      title: "¿Entiendo por qué debería actuar?",
      problem: motivationTwoProblem,
      setProblem: e => { setMotivationTwoProblem(e.target.checked) },
      impact: motivationTwoImpac,
      setImpact: setMotivationTwoImpac,
      urgent: motivationTwoUrgent,
      setUrgent: e => { setMotivationTwoUrgent(e.target.checked) },
      observation: motivationTwoObservation,
      setObservation: setMotivationTwoObservation,
    }, {
      title: "¿Las características se traducen en beneficios?",
      problem: motivationThreeProblem,
      setProblem: e => { setMotivationThreeProblem(e.target.checked) },
      impact: motivationThreeImpac,
      setImpact: setMotivationThreeImpac,
      urgent: motivationThreeUrgent,
      setUrgent: e => { setMotivationThreeUrgent(e.target.checked) },
      observation: motivationThreeObservation,
      setObservation: setMotivationThreeObservation,
    }, {
      title: "¿Está claro lo que obtienen los usuarios cuando hacen clic en un botón o rellenan un formulario? ¿Es algo deseable / útil para el público objetivo?",
      problem: motivationFourthProblem,
      setProblem: e => { setMotivationFourthProblem(e.target.checked) },
      impact: motivationFourthImpac,
      setImpact: setMotivationFourthImpac,
      urgent: motivationFourthUrgent,
      setUrgent: e => { setMotivationFourthUrgent(e.target.checked) },
      observation: motivationFourthObservation,
      setObservation: setMotivationFourthObservation,
    }, {
      title: "¿Hay suficiente información sobre el producto?",
      problem: motivationFiveProblem,
      setProblem: e => { setMotivationFiveProblem(e.target.checked) },
      impact: motivationFiveImpac,
      setImpact: setMotivationFiveImpac,
      urgent: motivationFiveUrgent,
      setUrgent: e => { setMotivationFiveUrgent(e.target.checked) },
      observation: motivationFiveObservation,
      setObservation: setMotivationFiveObservation,
    }, {
      title: "¿Es interesante el contenido? ¿Utiliza un lenguaje sencillo?",
      problem: motivationSixProblem,
      setProblem: e => { setMotivationSixProblem(e.target.checked) },
      impact: motivationSixImpac,
      setImpact: setMotivationSixImpac,
      urgent: motivationSixUrgent,
      setUrgent: e => { setMotivationSixUrgent(e.target.checked) },
      observation: motivationSixObservation,
      setObservation: setMotivationSixObservation,
    }, {
      title: "¿Es persuasivo el copy de ventas?",
      problem: motivationSevenProblem,
      setProblem: e => { setMotivationSevenProblem(e.target.checked) },
      impact: motivationSevenImpac,
      setImpact: setMotivationSevenImpac,
      urgent: motivationSevenUrgent,
      setUrgent: e => { setMotivationSevenUrgent(e.target.checked) },
      observation: motivationSevenObservation,
      setObservation: setMotivationSevenObservation,
    }, {
      title: "¿Podríamos aplicar algunos principios de persuasión como prueba social, urgencia o escasez?",
      problem: motivationEightProblem,
      setProblem: e => { setMotivationEightProblem(e.target.checked) },
      impact: motivationEightImpac,
      setImpact: setMotivationEightImpac,
      urgent: motivationEightUrgent,
      setUrgent: e => { setMotivationEightUrgent(e.target.checked) },
      observation: motivationEightObservation,
      setObservation: setMotivationEightObservation,
    }
  ];
  const formSalesQuestions = [
    {
      title: "Si el usuario no está listo para comprar, ¿lo ayudamos en su proceso de investigación y evaluación?",
      problem: salesOneProblem,
      setProblem: e => { setSalesOneProblem(e.target.checked) },
      impact: salesOneImpac,
      setImpact: setSalesOneImpac,
      urgent: salesOneUrgent,
      setUrgent: e => { setSalesOneUrgent(e.target.checked) },
      observation: salesOneObservation,
      setObservation: setSalesOneObservation,
    },
    {
      title: "¿Hay llamadas a la acción secundarias para obtener más información sobre el producto en los embudos clave?",
      problem: salesTwoProblem,
      setProblem: e => { setSalesTwoProblem(e.target.checked) },
      impact: salesTwoImpac,
      setImpact: setSalesTwoImpac,
      urgent: salesTwoUrgent,
      setUrgent: e => { setSalesTwoUrgent(e.target.checked) },
      observation: salesTwoObservation,
      setObservation: setSalesTwoObservation,
    }, {
      title: "¿Existe un proceso de captura de correo electrónico eficaz? ¿Tiene un lead magnet adecuado?",
      problem: salesThreeProblem,
      setProblem: e => { setSalesThreeProblem(e.target.checked) },
      impact: salesThreeImpac,
      setImpact: setSalesThreeImpac,
      urgent: salesThreeUrgent,
      setUrgent: e => { setSalesThreeUrgent(e.target.checked) },
      observation: salesThreeObservation,
      setObservation: setSalesThreeObservation,
    }
  ];

  const saveDataTemplate = () => {
    const data = {
      title,
      relevanceOneProblem,
      relevanceOneUrgent,
      relevanceOneImpac,
      relevanceOneObservation,
      relevanceTwoProblem,
      relevanceTwoUrgent,
      relevanceTwoImpac,
      relevanceTwoObservation,
      relevanceThreeProblem,
      relevanceThreeUrgent,
      relevanceThreeImpac,
      relevanceThreeObservation,
      relevanceFourthProblem,
      relevanceFourthUrgent,
      relevanceFourthImpac,
      relevanceFourthObservation,
      designOneProblem,
      designOneUrgent,
      designOneImpac,
      designOneObservation,
      designTwoProblem,
      designTwoUrgent,
      designTwoImpac,
      designTwoObservation,
      designThreeProblem,
      designThreeUrgent,
      designThreeImpac,
      designThreeObservation,
      designFourthProblem,
      designFourthUrgent,
      designFourthImpac,
      designFourthObservation,
      designFiveProblem,
      designFiveUrgent,
      designFiveImpac,
      designFiveObservation,
      designSixProblem,
      designSixUrgent,
      designSixImpac,
      designSixObservation,
      designSevenProblem,
      designSevenUrgent,
      designSevenImpac,
      designSevenObservation,
      designEightProblem,
      designEightUrgent,
      designEightImpac,
      designEightObservation,
      frictionOneProblem,
      frictionOneUrgent,
      frictionOneImpac,
      frictionOneObservation,
      frictionTwoProblem,
      frictionTwoUrgent,
      frictionTwoImpac,
      frictionTwoObservation,
      frictionThreeProblem,
      frictionThreeUrgent,
      frictionThreeImpac,
      frictionThreeObservation,
      frictionFourthProblem,
      frictionFourthUrgent,
      frictionFourthImpac,
      frictionFourthObservation,
      frictionFiveProblem,
      frictionFiveUrgent,
      frictionFiveImpac,
      frictionFiveObservation,
      frictionSixProblem,
      frictionSixUrgent,
      frictionSixImpac,
      frictionSixObservation,
      frictionSevenProblem,
      frictionSevenUrgent,
      frictionSevenImpac,
      frictionSevenObservation,
      frictionEightProblem,
      frictionEightUrgent,
      frictionEightImpac,
      frictionEightObservation,
      frictionNineProblem,
      frictionNineUrgent,
      frictionNineImpac,
      frictionNineObservation,
      frictionTenProblem,
      frictionTenUrgent,
      frictionTenImpac,
      frictionTenObservation,
      distractionsOneProblem,
      distractionsOneUrgent,
      distractionsOneImpac,
      distractionsOneObservation,
      distractionsTwoProblem,
      distractionsTwoUrgent,
      distractionsTwoImpac,
      distractionsTwoObservation,
      distractionsThreeProblem,
      distractionsThreeUrgent,
      distractionsThreeImpac,
      distractionsThreeObservation,
      distractionsFourthProblem,
      distractionsFourthUrgent,
      distractionsFourthImpac,
      distractionsFourthObservation,
      distractionsFiveProblem,
      distractionsFiveUrgent,
      distractionsFiveImpac,
      distractionsFiveObservation,
      distractionsSixProblem,
      distractionsSixUrgent,
      distractionsSixImpac,
      distractionsSixObservation,
      distractionsSevenProblem,
      distractionsSevenUrgent,
      distractionsSevenImpac,
      distractionsSevenObservation,
      motivationOneProblem,
      motivationOneUrgent,
      motivationOneImpac,
      motivationOneObservation,
      motivationTwoProblem,
      motivationTwoUrgent,
      motivationTwoImpac,
      motivationTwoObservation,
      motivationThreeProblem,
      motivationThreeUrgent,
      motivationThreeImpac,
      motivationThreeObservation,
      motivationFourthProblem,
      motivationFourthUrgent,
      motivationFourthImpac,
      motivationFourthObservation,
      motivationFiveProblem,
      motivationFiveUrgent,
      motivationFiveImpac,
      motivationFiveObservation,
      motivationSixProblem,
      motivationSixUrgent,
      motivationSixImpac,
      motivationSixObservation,
      motivationSevenProblem,
      motivationSevenUrgent,
      motivationSevenImpac,
      motivationSevenObservation,
      motivationEightProblem,
      motivationEightUrgent,
      motivationEightImpac,
      motivationEightObservation,
      salesOneProblem,
      salesOneUrgent,
      salesOneImpac,
      salesOneObservation,
      salesTwoProblem,
      salesTwoUrgent,
      salesTwoImpac,
      salesTwoObservation,
      salesThreeProblem,
      salesThreeUrgent,
      salesThreeImpac,
      salesThreeObservation,
      introduction,
      showIntroducction,
      conclusion,
      showConclusion,
    };
    setData(props.template.key, data);
    let toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const makePdf = () => {
    const data = {
      formRelevanceQuestions,
      formDesignQuestions,
      formFrictionQuestions,
      formDistractionsQuestions,
      formMotivationQuestions,
      formSalesQuestions,
      introduction,
      showIntroducction,
      conclusion,
      showConclusion,
      template: props.template.key,
      title: title
    };
    setData(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  };

  const resetData = () => {
    removeData(props.template.key);
    setTitle(props.template.name);
    setRelevanceOneProblem(false);
    setRelevanceOneUrgent(false);
    setRelevanceOneImpac('');
    setRelevanceOneObservation('');
    setRelevanceTwoProblem(false);
    setRelevanceTwoUrgent(false);
    setRelevanceTwoImpac('');
    setRelevanceTwoObservation('');
    setRelevanceThreeProblem(false);
    setRelevanceThreeUrgent(false);
    setRelevanceThreeImpac('');
    setRelevanceThreeObservation('');
    setRelevanceFourthProblem(false);
    setRelevanceFourthUrgent(false);
    setRelevanceFourthImpac('');
    setRelevanceFourthObservation('');
    setDesignOneProblem(false);
    setDesignOneUrgent(false);
    setDesignOneImpac('');
    setDesignOneObservation('');
    setDesignTwoProblem(false);
    setDesignTwoUrgent(false);
    setDesignTwoImpac('');
    setDesignTwoObservation('');
    setDesignThreeProblem(false);
    setDesignThreeUrgent(false);
    setDesignThreeImpac('');
    setDesignThreeObservation('');
    setDesignFourthProblem(false);
    setDesignFourthUrgent(false);
    setDesignFourthImpac('');
    setDesignFourthObservation('');
    setDesignFiveProblem(false);
    setDesignFiveUrgent(false);
    setDesignFiveImpac('');
    setDesignFiveObservation('');
    setDesignSixProblem(false);
    setDesignSixUrgent(false);
    setDesignSixImpac('');
    setDesignSixObservation('');
    setDesignSevenProblem(false);
    setDesignSevenUrgent(false);
    setDesignSevenImpac('');
    setDesignSevenObservation('');
    setDesignEightProblem(false);
    setDesignEightUrgent(false);
    setDesignEightImpac('');
    setDesignEightObservation('');
    setFrictionOneProblem(false);
    setFrictionOneUrgent(false);
    setFrictionOneImpac('');
    setFrictionOneObservation('');
    setFrictionTwoProblem(false);
    setFrictionTwoUrgent(false);
    setFrictionTwoImpac('');
    setFrictionTwoObservation('');
    setFrictionThreeProblem(false);
    setFrictionThreeUrgent(false);
    setFrictionThreeImpac('');
    setFrictionThreeObservation('');
    setFrictionFourthProblem(false);
    setFrictionFourthUrgent(false);
    setFrictionFourthImpac('');
    setFrictionFourthObservation('');
    setFrictionFiveProblem(false);
    setFrictionFiveUrgent(false);
    setFrictionFiveImpac('');
    setFrictionFiveObservation('');
    setFrictionSixProblem(false);
    setFrictionSixUrgent(false);
    setFrictionSixImpac('');
    setFrictionSixObservation('');
    setFrictionSevenProblem(false);
    setFrictionSevenUrgent(false);
    setFrictionSevenImpac('');
    setFrictionSevenObservation('');
    setFrictionEightProblem(false);
    setFrictionEightUrgent(false);
    setFrictionEightImpac('');
    setFrictionEightObservation('');
    setFrictionNineProblem(false);
    setFrictionNineUrgent(false);
    setFrictionNineImpac('');
    setFrictionNineObservation('');
    setFrictionTenProblem(false);
    setFrictionTenUrgent(false);
    setFrictionTenImpac('');
    setFrictionTenObservation('');
    setDistractionsOneProblem(false);
    setDistractionsOneUrgent(false);
    setDistractionsOneImpac('');
    setDistractionsOneObservation('');
    setDistractionsTwoProblem(false);
    setDistractionsTwoUrgent(false);
    setDistractionsTwoImpac('');
    setDistractionsTwoObservation('');
    setDistractionsThreeProblem(false);
    setDistractionsThreeUrgent(false);
    setDistractionsThreeImpac('');
    setDistractionsThreeObservation('');
    setDistractionsFourthProblem(false);
    setDistractionsFourthUrgent(false);
    setDistractionsFourthImpac('');
    setDistractionsFourthObservation('');
    setDistractionsFiveProblem(false);
    setDistractionsFiveUrgent(false);
    setDistractionsFiveImpac('');
    setDistractionsFiveObservation('');
    setDistractionsSixProblem(false);
    setDistractionsSixUrgent(false);
    setDistractionsSixImpac('');
    setDistractionsSixObservation('');
    setDistractionsSevenProblem(false);
    setDistractionsSevenUrgent(false);
    setDistractionsSevenImpac('');
    setDistractionsSevenObservation('');
    setMotivationOneProblem(false);
    setMotivationOneUrgent(false);
    setMotivationOneImpac('');
    setMotivationOneObservation('');
    setMotivationTwoProblem(false);
    setMotivationTwoUrgent(false);
    setMotivationTwoImpac('');
    setMotivationTwoObservation('');
    setMotivationThreeProblem(false);
    setMotivationThreeUrgent(false);
    setMotivationThreeImpac('');
    setMotivationThreeObservation('');
    setMotivationFourthProblem(false);
    setMotivationFourthUrgent(false);
    setMotivationFourthImpac('');
    setMotivationFourthObservation('');
    setMotivationFiveProblem(false);
    setMotivationFiveUrgent(false);
    setMotivationFiveImpac('');
    setMotivationFiveObservation('');
    setMotivationSixProblem(false);
    setMotivationSixUrgent(false);
    setMotivationSixImpac('');
    setMotivationSixObservation('');
    setMotivationSevenProblem(false);
    setMotivationSevenUrgent(false);
    setMotivationSevenImpac('');
    setMotivationSevenObservation('');
    setMotivationEightProblem(false);
    setMotivationEightUrgent(false);
    setMotivationEightImpac('');
    setMotivationEightObservation('');
    setSalesOneProblem(false);
    setSalesOneUrgent(false);
    setSalesOneImpac('');
    setSalesOneObservation('');
    setSalesTwoProblem(false);
    setSalesTwoUrgent(false);
    setSalesTwoImpac('');
    setSalesTwoObservation('');
    setSalesThreeProblem(false);
    setSalesThreeUrgent(false);
    setSalesThreeImpac('');
    setSalesThreeObservation('');
    setIntroducction('');
    setShowIntroducction(false);
    setConclusion('');
    setShowConclusion(false);
    let toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const closeModal = () => {
    setIsModalVisible(false);
  }

  let modal;
  let resetModal;

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <p style={{ textAlign: 'center' }}>¿Deseas borrar esta sección?</p>
          }
          onCancel={e => setIsModalVisible(false)}
          onConfirm={closeModal}
          cancelButtonText="Cancelar"
          confirmButtonText="Sí, borrar sección"
          defaultFocusedButton="confirm"
          buttonColor="danger"
        >
          <p>Esta acción no se puede deshacer</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  if (isResetModalVisible) {
    resetModal = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={
            <p style={{ textAlign: 'center' }}>
              ¿Deseas reiniciar todos
              <br />
              los campos del formulario?
            </p>
          }
          onCancel={() => setIsResetModalVisible(false)}
          onConfirm={() => {
            setIsResetModalVisible(false);
            resetData();
          }}
          cancelButtonText="Cancelar"
          confirmButtonText="Sí, reiniciar formulario"
          defaultFocusedButton="confirm"
          buttonColor="danger"
        >
          <p>Esta acción no se puede deshacer</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  

  return (
    <Fragment>
      {modal}
      {resetModal}
      <EuiFlexItem className="page-template heuristic" id="pageTemplate">
        <EuiPageContentHeader style={{ justifyContent: 'center' }} className="mb-5">
          <EditableTitle title={title} setTitle={setTitle} />
          <EuiSpacer size="xxl" />
        </EuiPageContentHeader>
          <ToggleableTextBlock
            content={introduction}
            setContent={setIntroducction}
            showContent={showIntroducction}
            setShowContent={setShowIntroducction}
            buttonLabel="Agregar texto de introducción"
          />
          <EuiSpacer size="xxl" />
        <EuiPageContentBody>
          <EuiForm>
            <EuiTitle size="s">
              <h1 className="Subtitle">Relevancia</h1>
            </EuiTitle>
            {formRelevanceQuestions.map((formData, idx) => {
              return (
                <Fragment key={idx}>
                  <EuiTitle size="xs" className="label">
                    <h1 className="mt-2 mb-2">{formData.title}</h1>
                  </EuiTitle>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Hay Problemas">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.problem}
                          onChange={formData.setProblem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Impacto">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          value={formData.impact}
                          onChangeHandler={formData.setImpact}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Es Urgente">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.urgent}
                          onChange={formData.setUrgent}
                          disabled={!formData.problem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Observaciones">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          row="4"
                          value={formData.observation}
                          onChangeHandler={formData.setObservation}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              )
            })}
            <EuiTitle size="s">
              <h1 className="Subtitle">Claridad de diseño</h1>
            </EuiTitle>
            {formDesignQuestions.map((formData, idx) => {
              return (
                <Fragment key={idx}>
                  <EuiTitle size="xs" className="label">
                    <h1 className="mt-2 mb-2">{formData.title}</h1>
                  </EuiTitle>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Hay Problemas">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.problem}
                          onChange={formData.setProblem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Impacto">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          value={formData.impact}
                          onChangeHandler={formData.setImpact}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Es Urgente">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.urgent}
                          onChange={formData.setUrgent}
                          disabled={!formData.problem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Observaciones">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          row="4"
                          value={formData.observation}
                          onChangeHandler={formData.setObservation}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              )
            })}
            <EuiTitle size="s">
              <h1 className="Subtitle">Fricción</h1>
            </EuiTitle>
            {formFrictionQuestions.map((formData, idx) => {
              return (
                <Fragment key={idx}>
                  <EuiTitle size="xs" className="label">
                    <h1 className="mt-2 mb-2">{formData.title}</h1>
                  </EuiTitle>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Hay Problemas">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.problem}
                          onChange={formData.setProblem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Impacto">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          value={formData.impact}
                          onChangeHandler={formData.setImpact}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Es Urgente">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.urgent}
                          onChange={formData.setUrgent}
                          disabled={!formData.problem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Observaciones">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          row="4"
                          value={formData.observation}
                          onChangeHandler={formData.setObservation}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              )
            })}
            <EuiTitle size="s">
              <h1 className="Subtitle">Distracciones</h1>
            </EuiTitle>
            {formDistractionsQuestions.map((formData, idx) => {
              return (
                <Fragment key={idx}>
                  <EuiTitle size="xs" className="label">
                    <h1 className="mt-2 mb-2">{formData.title}</h1>
                  </EuiTitle>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Hay Problemas">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.problem}
                          onChange={formData.setProblem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Impacto">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          value={formData.impact}
                          onChangeHandler={formData.setImpact}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Es Urgente">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.urgent}
                          onChange={formData.setUrgent}
                          disabled={!formData.problem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Observaciones">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          row="4"
                          value={formData.observation}
                          onChangeHandler={formData.setObservation}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              )
            })}
            <EuiTitle size="s">
              <h1 className="Subtitle">Motivación e incentivos</h1>
            </EuiTitle>
            {formMotivationQuestions.map((formData, idx) => {
              return (
                <Fragment key={idx}>
                  <EuiTitle size="xs" className="label">
                    <h1 className="mt-2 mb-2">{formData.title}</h1>
                  </EuiTitle>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Hay Problemas">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.problem}
                          onChange={formData.setProblem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Impacto">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          value={formData.impact}
                          onChangeHandler={formData.setImpact}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Es Urgente">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.urgent}
                          onChange={formData.setUrgent}
                          disabled={!formData.problem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Observaciones">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          row="4"
                          value={formData.observation}
                          onChangeHandler={formData.setObservation}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              )
            })}
            <EuiTitle size="s">
              <h1 className="Subtitle">Ciclo de Compras</h1>
            </EuiTitle>
            {formSalesQuestions.map((formData, idx) => {
              return (
                <Fragment key={idx}>
                  <EuiTitle size="xs" className="label">
                    <h1 className="mt-2 mb-2">{formData.title}</h1>
                  </EuiTitle>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Hay Problemas">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.problem}
                          onChange={formData.setProblem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Impacto">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          value={formData.impact}
                          onChangeHandler={formData.setImpact}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiFormRow label="Es Urgente">
                        <EuiCheckbox
                          id={htmlIdGenerator()()}
                          label=""
                          checked={formData.urgent}
                          onChange={formData.setUrgent}
                          disabled={!formData.problem}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFormRow label="Observaciones">
                        <CustomInput
                          disabled={!formData.problem}
                          minHeight={4}
                          placeholder=""
                          row="4"
                          value={formData.observation}
                          onChangeHandler={formData.setObservation}
                          isFullWidth={true}
                        />
                      </EuiFormRow>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </Fragment>
              )
            })}
          </EuiForm>
        </EuiPageContentBody>

        <EuiSpacer size="xxl" />

        <ToggleableTextBlock
            content={conclusion}
            setContent={setConclusion}
            showContent={showConclusion}
            setShowContent={setShowConclusion}
            buttonLabel="Agregar texto de conclusión"
          />
        
      </EuiFlexItem>
      <EuiBottomBar className="notPrint">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiLink href="/templates">Todas las Plantillas</EuiLink> /
            </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>{props.template.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="refresh"
                  onClick={() => {
                    setIsResetModalVisible(true);
                  }}
                >
                  Resetear
            </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="save"
                  onClick={() => {
                    setIsTitleEditable(false);
                    saveDataTemplate();
                  }}
                >
                  Guardar
            </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  color="primary"
                  fill
                  size="s"
                  iconType="check"
                  onClick={() => {
                    setIsTitleEditable(false);
                    makePdf();
                  }}
                >
                  Imprimir
            </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </Fragment>
  );
};

// 