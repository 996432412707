import { EuiFormRow, EuiIcon, EuiToolTip } from '@elastic/eui';
import React from 'react';
import { CustomInput } from '../CustomInput';
import PropTypes from 'prop-types';

const labels = {
  you: 'Tú',
  competitor: 'Competidor'
};

const PureCompetitorsDynamicList = props => {
  const onClickHandler = competitor => {
    props.setCompetitorIndex(competitor);
    props.setShowConfirmDeleteCompetitorModal(true);
  };

  return (
    <React.Fragment>
      <EuiFormRow label={labels.you} fullWidth>
        <CustomInput
          isFullWidth
          index={0}
          value={props.multiple[0] || ''}
          onChangeHandler={props.onChangeHandler}
          multiple={props.multiple}
          setMultiple={props.setMultiple}
        />
      </EuiFormRow>
      {props.competitors.map((competitor, index) => (
        <EuiFormRow
          key={competitor}
          label={<h3>{`${labels.competitor} ${index + 1}`}</h3>}
          labelAppend={
            <EuiToolTip position="top" content="Eliminar competidor">
              <EuiIcon
                style={{ cursor: 'pointer' }}
                type="minusInCircle"
                onClick={onClickHandler.bind(this, competitor)}
              />
            </EuiToolTip>
          }
          fullWidth
        >
          <CustomInput
            isFullWidth
            index={index + 1}
            value={props.multiple[index + 1] || ''}
            onChangeHandler={props.onChangeHandler}
            multiple={props.multiple}
            setMultiple={props.setMultiple}
          />
        </EuiFormRow>
      ))}
      {props.addCompetitorButton}
    </React.Fragment>
  );
};

PureCompetitorsDynamicList.propTypes = {
  competitors: PropTypes.array.isRequired,
  multiple: PropTypes.array.isRequired,
  setMultiple: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  addCompetitorButton: PropTypes.element.isRequired,
  setShowConfirmDeleteCompetitorModal: PropTypes.func.isRequired,
  setCompetitorIndex: PropTypes.func.isRequired
};

const CompetitorsDynamicList = React.memo(PureCompetitorsDynamicList);

export { CompetitorsDynamicList };
