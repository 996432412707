import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  content: {
    fontSize: 10,
    fontFamily: 'Poppins'
  }
});

const Content = React.memo(props => (
  <Text style={styles.content}>{props.content}</Text>
));

Content.propTypes = {
  content: PropTypes.string.isRequired
};

export { Content };
