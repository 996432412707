import {
  EuiButtonIcon,
  EuiComboBox,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiGlobalToastList,
  EuiHealth,
  EuiHighlight,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
  htmlIdGenerator
} from '@elastic/eui';
import React, { useState } from 'react';
import { read, write, remove } from '../../services/storage';
import { EditableTitle } from '../utils/EditableTitle';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';
import { SingleInputDynamicList } from '../utils/dynamic-lists/SingleInputDynamicList';
import { AddonsDynamicList } from '../utils/dynamic-lists/AddonsDynamicList';
import { UpsellDynamicList } from '../utils/dynamic-lists/UpsellDynamicList';
import { FormatsModal } from '../utils/FormatsModal';
import { TemplateBottomBar } from '../utils/TemplateBottomBar';
import { ConfirmModal } from '../utils/ConfirmModal';
import { storage } from '../../config/env';

import '../../assets/scss/components/create-offer.scss';

export const TemplateCreateOffer = props => {
  const localData = read(props.template.key) || {};
  const makeId = htmlIdGenerator();

  //#region labels
  const introPlaceholder = 'Agregar texto de introducción';
  const frontOfferLabel = 'Oferta Front';
  const frontOfferDesc =
    'Describe a continuación los contenidos de la oferta inicial y visible para todos los compradores';
  const itemToSellTitle = '¿Qué vendemos? - Nombre producto/servicio';
  const itemToSellPlaceholder = 'Ej. Termos para familias';
  const offerTypeTitle = 'Tipo de oferta';
  const offerTypePlaceholder = 'Selecciona el tipo de oferta';
  const offerTypeOptions = [
    {
      label: 'Pack',
      options: [
        {
          label: 'Juntar varios productos y servicios existentes',
          color: '#f498f4'
        },
        {
          label:
            'Juntar un gran producto/servicio estrella con otros productos o servicios solo creados para la oferta',
          color: '#f498f4'
        },
        {
          label:
            'Juntar un gran producto/servicio con otro de una marca colaboladora',
          color: '#f498f4'
        }
      ]
    },
    {
      label: 'Fundador',
      options: [
        {
          label:
            'Lanzar un producto o servicio con oferta y precio de miembro fundador',
          color: '#f4cccc'
        },
        {
          label:
            'Lanzar un producto o servicio con edición limitada o edición especial',
          color: '#f4cccc'
        }
      ]
    },
    {
      label: 'Lanzamientos',
      options: [
        {
          label:
            'Lanzar un producto o servicio con un precio exclusivo por lanzamiento',
          color: '#b7eef2'
        }
      ]
    },
    {
      label: 'Tiempo',
      options: [
        {
          label:
            'Oferta limitada a uno o más días. Es decir, sólo vigente de Viernes a Domingo',
          color: '#d9d2e9'
        },
        {
          label:
            'Oferta limitada a 48 horas desde que una persona muestra interés. Oferta evergreen',
          color: '#d9d2e9'
        },
        {
          label:
            'PRE-VENTA: Oferta para que las personas obtengan antes que nadie el producto o servicio. Ej: Pre-order libro',
          color: '#d9d2e9'
        }
      ]
    },
    {
      label: 'Unidades',
      options: [
        {
          label:
            'Oferta limitada a unas plazas/unidades disponibles en inventario',
          color: '#d9d9d9'
        },
        {
          label: 'Oferta BOGO (compra uno y ten otro gratis)',
          color: '#d9d9d9'
        }
      ]
    },
    {
      label: 'Club',
      options: [
        {
          label:
            'Lanzar un producto o servicio para ser parte de un club/comunidad. En estos clubes hay diferentes niveles y las personas aspiran a subir. Ej: Puntos con aerolínea de viaje',
          color: '#b6d7a8'
        }
      ]
    },
    {
      label: 'Descuento',
      options: [
        {
          label:
            'Oferta limitada a un descuento exclusivo por época festiva. Ej: Black Friday 50% OFF',
          color: '#fce5cd'
        },
        {
          label:
            'Oferta limitada a un descuento por X tiempo. Ej: 50% por 24 horas (día 25/abril/2021)',
          color: '#fce5cd'
        },
        {
          label:
            'Oferta limitada a un descuento por aniversario o día temático marca. Ej: Single Day de Alibaba',
          color: '#fce5cd'
        },
        {
          label:
            'Oferta para bloquear precio de membresía y no hacer que suba. Ej: paga todo el año y mantén la tarifa de 2021 en vez de la nueva de 2022',
          color: '#fce5cd'
        },
        {
          label:
            'Oferta de liquidación. Productos en zona de liquidación al precio más barato desde que se lanzaron',
          color: '#fce5cd'
        },
        {
          label:
            'Oferta para dar un GIFT-Card en función de compra. Ej: por cada $100 un GiftCard de $10',
          color: '#fce5cd'
        },
        {
          label:
            'Oferta para dar un GIFT-Card a un conocido. Ej: compra servicio LASER de $300 y regala $100 en un GIFT-Card a otra persona',
          color: '#fce5cd'
        }
      ]
    },
    {
      label: 'Regalo',
      options: [
        {
          label:
            'Oferta atada a un regalo. Ej: Compra cualquier vestido y llévate gratis un bolso. Ej2: Gasta 100$ y llévate un bolso gratis',
          color: '#fcfc43'
        },
        { label: 'Ofertada atada a un regalo gratis', color: '#fcfc43' }
      ]
    },
    {
      label: 'Subasta',
      options: [
        { label: 'Dejar que los interesados pujen', color: '#88b1f4' },
        {
          label:
            'Oferta a través de la cual los clientes pagan lo que quieran a partir de un monto mínimo. Lo mismo que pasa con donaciones',
          color: '#88b1f4'
        }
      ]
    },
    {
      label: 'Pruebas',
      options: [
        {
          label:
            'Prueba gratis por 7 días/30 días/3 meses y luego paga X / mes. Ej: Prueba gratis Clickfunnels por 14 días (pero ya tienen tu tarjeta para cobrarte el día 15)',
          color: '#f6b26b'
        },
        {
          label:
            'Prueba gratuita del producto en versión pequeña. El cliente paga solo el envío',
          color: '#f6b26b'
        }
      ]
    },
    {
      label: 'Tendencia/Novedad',
      options: [
        {
          label:
            'Oferta de un producto/servicio por temporada. Ej: Café Navidad o Servicio campañas Black Friday. Están limitados a una época, novedad o tendencia',
          color: '#a64d79'
        }
      ]
    },
    {
      label: 'Suscripción',
      options: [
        {
          label: 'Oferta para vender membresías con un regalo de bienvenida',
          color: '#38761d'
        }
      ]
    }
  ];
  const avatarsTitle = 'Avatares (A quién le vendemos)';
  const avatarsDesc =
    'Describe el perfil o perfiles del comprador para tu producto o servicio';
  const addAvatar = 'Agregar avatar';
  const offerResultsTitle = 'Resultados oferta';
  const offerResultsDesc =
    'Describe los resultados que obtendrá el cliente con esta oferta';
  const addOfferResult = 'Agregar resultado';
  const mainProductTitle = 'Producto/servicio principal';
  const mainProductPlaceholder = 'Ej. Termos para familias';
  const formatTitle = 'Formatos';
  const formatPlaceholder = 'Selecciona el formato';
  const addonsTitle = 'Add-ons y bonus';
  const addonsPlaceholder = 'Nombre del addon';
  const addAddonsLabel = 'Agregar addon o bonus';
  const priceTitle = 'Precio';
  const pricePlaceholder = '15.00';
  const valuePlaceholder = '150.00';
  const productPriceTitle = 'PRECIO producto';
  const productValueTitle = 'Valor';
  const backOfferTitle = 'Oferta back';
  const backOfferDesc =
    'Describe a continuación los componentes adicionales de la oferta';
  const bumpTitle = 'Bump';
  const finalPriceTitle = 'Precio final del producto + BUMP';
  const upsellTitle = 'Upsell';
  const addUpsell = 'Agregar upsell';
  const finalPriceUpsellTitle = 'Precio final del producto + BUMP + Upsell';
  const outroLabel = 'Agregar bloque de texto';
  const resetModalTitle = (
    <p style={{ textAlign: 'center' }}>
      ¿Deseas reiniciar todos
      <br />
      los campos del formulario?
    </p>
  );
  //#endregion labels

  //#region states
  const [title, setTitle] = useState(localData.title || props.template.name);
  const [introduction, setIntroduction] = useState(
    localData.introduction || ''
  );
  const [showIntroduction, setShowIntroduction] = useState(
    localData.showIntroduction !== undefined
      ? localData.showIntroduction
      : false
  );
  const [itemToSell, setItemToSell] = useState(localData.itemToSell || '');
  const [offerType, setOfferType] = useState(
    localData.offerType || [
      {
        color: offerTypeOptions[0].options[0].color,
        label: offerTypeOptions[0].options[0].label
      }
    ]
  );
  const [avatars, setAvatars] = useState(
    localData.avatars || [{ key: makeId(), value: '', disabled: false }]
  );
  const [offerResults, setOfferResults] = useState(
    localData.offerResults || [{ key: makeId(), value: '', disabled: false }]
  );
  const [mainProduct, setMainProduct] = useState(localData.mainProduct || '');
  const [mainProductFormat, setMainProductFormat] = useState(
    localData.mainProductFormat || ''
  );
  const [addons, setAddons] = useState(
    localData.addons || [
      { key: makeId(), values: ['', '', ''], disabled: false }
    ]
  );
  const [productPrice, setProductPrice] = useState(
    localData.productPrice || { value: '', price: '' }
  );
  const [bump, setBump] = useState(
    localData.bump || { addon: '', value: '', price: '', format: '' }
  );
  const [finalPriceBump, setFinalPriceBump] = useState(
    localData.finalPriceBump || ''
  );
  const [upsell, setUpsell] = useState(
    localData.upsell || [
      {
        key: makeId(),
        values: ['', '', '', ''],
        disabled: false
      }
    ]
  );
  const [finalPriceUpsell, setFinalPriceUpsell] = useState(
    localData.finalPriceUpsell || ''
  );
  const [outro, setOutro] = useState(localData.outro || '');
  const [showOutro, setShowOutro] = useState(
    localData.showOutro !== undefined ? localData.showOutro : false
  );
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [toasts, setToasts] = useState([]);
  //#endregion states

  const renderOfferTypeOptions = (option, searchValue, contentClassName) => {
    const { color, label } = option;
    return (
      <EuiHealth color={color}>
        <span className={contentClassName}>
          <EuiHighlight search={searchValue}>{label}</EuiHighlight>
        </span>
      </EuiHealth>
    );
  };

  const saveData = () => {
    const data = {
      title,
      introduction,
      showIntroduction,
      itemToSell,
      offerType,
      avatars,
      offerResults,
      mainProduct,
      mainProductFormat,
      addons,
      productPrice,
      bump,
      finalPriceBump,
      upsell,
      finalPriceUpsell,
      outro,
      showOutro
    };
    write(props.template.key, data);
    const toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const makePdf = () => {
    const data = {
      title,
      introduction,
      showIntroduction,
      itemToSell,
      offerType,
      avatars,
      offerResults,
      mainProduct,
      mainProductFormat,
      addons,
      productPrice,
      bump,
      finalPriceBump,
      upsell,
      finalPriceUpsell,
      outro,
      showOutro,
      template: props.template.key
    };
    write(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  };

  const resetData = () => {
    setTitle(props.template.name);
    setIntroduction('');
    setShowIntroduction(false);
    setItemToSell('');
    setOfferType([
      {
        color: offerTypeOptions[0].options[0].color,
        label: offerTypeOptions[0].options[0].label
      }
    ]);
    setAvatars([{ key: makeId(), value: '', disabled: false }]);
    setOfferResults([{ key: makeId(), value: '', disabled: false }]);
    setMainProduct('');
    setMainProductFormat('');
    setAddons([{ key: makeId(), values: ['', '', ''], disabled: false }]);
    setProductPrice({ value: '', price: '' });
    setBump({ addon: '', value: '', price: '', format: '' });
    setFinalPriceBump('');
    setUpsell([
      {
        key: makeId(),
        values: ['', '', '', ''],
        disabled: false
      }
    ]);
    setFinalPriceUpsell('');
    setOutro('');
    setShowOutro(false);
    setShowHelpModal(false);
    setShowConfirmModal(false);
    remove(props.template.key);
    const toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const helpModal = (
    <FormatsModal isVisible={showHelpModal} setIsVisible={setShowHelpModal} />
  );

  const confirmModal = (
    <ConfirmModal
      title={resetModalTitle}
      isVisible={showConfirmModal}
      setIsVisible={setShowConfirmModal}
      onConfirm={resetData}
    />
  );

  return (
    <div className="page-template" id="pageTemplate">
      <EuiPageContentHeader style={{ justifyContent: 'center' }}>
        <EditableTitle title={title} setTitle={setTitle} />
      </EuiPageContentHeader>
      <EuiSpacer size="xl" />
      <EuiPageContentBody>
        <ToggleableTextBlock
          content={introduction}
          setContent={setIntroduction}
          showContent={showIntroduction}
          setShowContent={setShowIntroduction}
          buttonLabel={introPlaceholder}
        />
      </EuiPageContentBody>

      <EuiSpacer />

      <EuiTitle size="s">
        <span className="text-subdued">{frontOfferLabel}</span>
      </EuiTitle>
      <EuiText size="s" color="subdued">
        {frontOfferDesc}
      </EuiText>

      <EuiSpacer size="xl" />

      <EuiTitle size="xxs">
        <span className="text-subdued">{itemToSellTitle}</span>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiFieldText
        placeholder={itemToSellPlaceholder}
        value={itemToSell}
        onChange={e => setItemToSell(e.target.value)}
        fullWidth={true}
      />

      <EuiSpacer size="xl" />

      <EuiTitle size="xxs">
        <span className="text-subdued">{offerTypeTitle}</span>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiComboBox
        placeholder={offerTypePlaceholder}
        options={offerTypeOptions}
        selectedOptions={offerType}
        onChange={setOfferType}
        singleSelection={{ asPlainText: true }}
        renderOption={renderOfferTypeOptions}
        fullWidth={true}
      />

      <EuiSpacer size="xl" />

      <EuiTitle size="xxs">
        <span className="text-subdued">{avatarsTitle}</span>
      </EuiTitle>
      <EuiText size="s" color="subdued">
        {avatarsDesc}
      </EuiText>
      <EuiSpacer size="s" />
      <SingleInputDynamicList
        items={avatars}
        setItems={setAvatars}
        placeholder="Ej. Personas que no toman mucha agua"
        addButtonLabel={addAvatar}
      />

      <EuiSpacer size="xl" />

      <EuiTitle size="xxs">
        <span className="text-subdued">{offerResultsTitle}</span>
      </EuiTitle>
      <EuiText size="s" color="subdued">
        {offerResultsDesc}
      </EuiText>
      <EuiSpacer size="s" />
      <SingleInputDynamicList
        items={offerResults}
        setItems={setOfferResults}
        placeholder="Ej. Un thermo que no gotea"
        addButtonLabel={addOfferResult}
      />

      <EuiSpacer size="xl" />

      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center" wrap>
        <EuiFlexItem grow={6}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{mainProductTitle}</span>
          </EuiTitle>
          <EuiFieldText
            placeholder={mainProductPlaceholder}
            value={mainProduct}
            onChange={e => setMainProduct(e.target.value)}
            fullWidth={true}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{formatTitle}</span>
          </EuiTitle>
          <EuiFieldText
            value={mainProductFormat}
            onChange={e => setMainProductFormat(e.target.value)}
            fullWidth
            placeholder={formatPlaceholder}
            append={
              <EuiToolTip position="top" content={`Ayuda`}>
                <EuiButtonIcon
                  iconType="questionInCircle"
                  aria-label="Ayuda"
                  onClick={() => setShowHelpModal(true)}
                />
              </EuiToolTip>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="xl" />

      <EuiFlexGroup>
        <EuiFlexItem grow={4}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{addonsTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{priceTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{formatTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <AddonsDynamicList
        addButtonLabel={addAddonsLabel}
        items={addons}
        setItems={setAddons}
        placeholders={[addonsPlaceholder, pricePlaceholder, formatPlaceholder]}
        setShowModal={setShowHelpModal}
      />

      <EuiSpacer size="xl" />

      <EuiFlexGroup
        alignItems="flexEnd"
        justifyContent="spaceBetween"
        style={{
          backgroundColor: '#eff8ff',
          padding: '0.5rem',
          margin: '0.25rem'
        }}
      >
        <EuiFlexItem grow={6}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{productPriceTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{productValueTitle}</span>
          </EuiTitle>
          <EuiFieldNumber
            step={0.01}
            value={productPrice.value}
            onChange={e =>
              setProductPrice({
                ...productPrice,
                value: e.target.value
              })
            }
            placeholder={valuePlaceholder}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{priceTitle}</span>
          </EuiTitle>
          <EuiFieldNumber
            step={0.01}
            value={productPrice.price}
            onChange={e =>
              setProductPrice({
                ...productPrice,
                price: e.target.value
              })
            }
            placeholder={pricePlaceholder}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="xl" />

      <EuiTitle size="s">
        <span className="text-subdued">{backOfferTitle}</span>
      </EuiTitle>
      <EuiText size="s" color="subdued">
        {backOfferDesc}
      </EuiText>

      <EuiSpacer />

      <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
        <EuiFlexItem grow={3}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{bumpTitle}</span>
          </EuiTitle>
          <EuiFieldText
            value={bump.addon}
            onChange={e => setBump({ ...bump, addon: e.target.value })}
            placeholder={addonsPlaceholder}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{productValueTitle}</span>
          </EuiTitle>
          <EuiFieldNumber
            step={0.01}
            value={bump.value}
            onChange={e => setBump({ ...bump, value: e.target.value })}
            placeholder={valuePlaceholder}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{priceTitle}</span>
          </EuiTitle>
          <EuiFieldNumber
            step={0.01}
            value={bump.price}
            onChange={e => setBump({ ...bump, price: e.target.value })}
            placeholder={pricePlaceholder}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{formatTitle}</span>
          </EuiTitle>
          <EuiFieldText
            value={bump.format}
            onChange={e => setBump({ ...bump, format: e.target.value })}
            placeholder={formatPlaceholder}
            append={
              <EuiToolTip position="top" content="Ayuda">
                <EuiButtonIcon
                  iconType="questionInCircle"
                  aria-label="Ayuda"
                  onClick={() => setShowHelpModal(true)}
                />
              </EuiToolTip>
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="xl" />

      <EuiFlexGroup
        alignItems="flexEnd"
        justifyContent="spaceBetween"
        style={{
          backgroundColor: '#eff8ff',
          padding: '0.5rem',
          margin: '0.25rem'
        }}
      >
        <EuiFlexItem grow={8}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{finalPriceTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{priceTitle}</span>
          </EuiTitle>
          <EuiFieldNumber
            readOnly
            step={0.01}
            value={Number(bump.price) + Number(productPrice.price)}
            onChange={() => null}
            placeholder={pricePlaceholder}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="xl" />

      <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
        <EuiFlexItem grow={3}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{upsellTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{productValueTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{priceTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{formatTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <UpsellDynamicList
        items={upsell}
        setItems={setUpsell}
        placeholders={[
          addonsPlaceholder,
          valuePlaceholder,
          pricePlaceholder,
          formatPlaceholder
        ]}
        addButtonLabel={addUpsell}
        setShowModal={setShowHelpModal}
      />

      <EuiSpacer size="xl" />

      <EuiFlexGroup
        alignItems="flexEnd"
        justifyContent="spaceBetween"
        style={{
          backgroundColor: '#eff8ff',
          padding: '0.5rem',
          margin: '0.25rem'
        }}
      >
        <EuiFlexItem grow={8}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{finalPriceUpsellTitle}</span>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <EuiTitle size="xxs">
            <span className="text-subdued">{priceTitle}</span>
          </EuiTitle>
          <EuiFieldNumber
            readOnly
            step={0.01}
            value={
              Number(bump.price) +
              Number(productPrice.price) +
              upsell.reduce(
                (total, upsellElem) => total + Number(upsellElem.values[2]),
                0
              )
            }
            onChange={() => null}
            placeholder={pricePlaceholder}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <ToggleableTextBlock
        content={outro}
        setContent={setOutro}
        showContent={showOutro}
        setShowContent={setShowOutro}
        buttonLabel={outroLabel}
      />
      <TemplateBottomBar
        templateName={props.template.name}
        saveDataFunction={saveData}
        generatePdfFunction={makePdf}
        toggleEditableTitles={[]}
        setResetModal={setShowConfirmModal}
      />
      {helpModal}
      {confirmModal}
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </div>
  );
};
