import {
  EuiFlexItem,
  EuiGlobalToastList,
  EuiPageContentHeader,
  EuiSpacer,
  EuiTitle,
  EuiPageContentBody,
  EuiFormRow,
  EuiFlexGroup,
  EuiFieldText,
  EuiColorPicker,
  EuiButton,
  EuiButtonIcon,
  EuiText,
  EuiListGroup,
} from '@elastic/eui';
import React, { useState, useMemo } from 'react';
import { storage } from '../../config/env';
import { read, write, remove } from '../../services/storage';
import { ConfirmModal } from '../utils/ConfirmModal';
import { CustomInput } from '../utils/CustomInput';
import { EditableTitle } from '../utils/EditableTitle';
import { TemplateBottomBar } from '../utils/TemplateBottomBar';
import { useColorPickerState } from '@elastic/eui/lib/services';
import { ToggleableTextBlock } from '../utils/ToggleableTextBlock';

export const TemplateWireframes = props => {
  const localData = read(props.template.key) || {};
  const [title, setTitle] = useState(localData.title || props.template.name);
  const [toasts, setToasts] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [domain, setDomain] = useState(localData.domain || '');
  const [skill, setSkill] = useState(localData.skill || '');
  const [image, setImage] = useState(localData.image || '');
  const [description, setDescription] = useState(localData.description || '');
  const [use, setUse] = useState('');
  const [color, setColor, errors] = useColorPickerState('');
  const [arrayColor, setArraycolor] = useState(localData.arrayColor || []);
  const isInvalid = useMemo(() => color !== '' && !!errors, [color, errors]);
  const [sites, setSites] = useState(localData.sites || '');
  const [articlesSites, setArticlesSites] = useState(localData.articlesSites || '');
  const [income, setIncome] = useState(localData.income || '');
  const [wireframes, setWireframes] = useState(localData.wireframes || []);
  const [labelLink, setLabelLink] = useState("");
  const [link, setLink] = useState("");
  const [introduction, setIntroduction] = useState(localData.introduction || '');
  const [showIntroduction, setShowIntroduction] = useState(
    localData.showIntroduction !== undefined
      ? localData.showIntroduction
      : false
  );
  const [conclusion, setConclusion] = useState(localData.conclusion || '');
  const [showConclusion, setShowConclusion] = useState(
    localData.showConclusion !== undefined
      ? localData.showConclusion
      : false
  );

  const  domainLabel = {
    label: '¿Cuál dominio estás considerando para tu sitio web?',
    helpText: 'No olvides verificar la disponibilidad del dominio y de que sea corto y fácil de recordar.'
  };
  const  skillLabel = {
    label: "¿Qué habilidades/temáticas (ej. Belleza, marketing, finanzas, etc) quieres presentar/escribir en tu sitio web?"
  };
  const  imageLabel = {
    label: "¿Qué imagen/estilo quieres proyectar en tu sitio web? (ej. Casual, profesional, disruptivo, serio, etc). "
  };
  const  arrayColorLabel = {
    label: "¿Qué paleta de colores anticipas utilizar en tu sitio web y por qué?"
  };
  const  sitesLabel = {
    label: "¿Qué otros sitios webs te gustan?"
  };
  const  articlesSitesLabel = {
    label: 'Para los artículos de tu sitio web:',
    helpTexts: ['¿Qué tipo de tono/voz quieres utilizar? (ej. Técnico, educacional, personal, profesional, etc).', '¿Qué tipo de categorías y palabras claves piensas utilizar?']
  };
  const  incomeLabel = {
    label: "¿Anticipas crear una línea de ingresos con tu sitio? si lo harás, describe tu plan."
  };
  const  wireframesLabel = {
    label: "Por cada página de tu sitio web, establece las razones de los siguientes elementos en cada una: ",
    helpTexts: ["Imagen / vídeos ","Llamadas a la acción - dónde y por qué","Información de contacto (de, correo, teléfono, dirección, etc)."]
  };

  const addToWireframes = () => {
    const itemLink = {
      label: labelLink,
      href: link,
      iconType: 'link',
      size: 's'
    }
    setWireframes([...wireframes, {...itemLink}])
    setLabelLink('');
    setLink('');
  }

  const saveData = () => {
    const data = {
      title,
      domain,
      skill,
      image,
      arrayColor,
      sites,
      articlesSites,
      income,
      wireframes,
      introduction,
      showIntroduction,
      conclusion,
      showConclusion,
    };
    write(props.template.key, data);
    const toast = {
      id: '1',
      title: 'Datos Guardados',
      color: 'success',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
  };

  const addToArrayColor = () => {
    const item = { description, use, color };
    setArraycolor([...arrayColor, { ...item }]);
    setDescription('');
    setUse('');
    // setColor('');
  }

  const removeData = index => {
    const arrayCopy = arrayColor;
    arrayColor.splice(index, 1);
    setArraycolor([...arrayCopy]);
  }
  const resetData = () => {
    setTitle(props.template.name);
    remove(props.template.key);
    setDomain('');
    setSkill('');
    setImage('');
    setArraycolor([]);
    setSites('');
    setArticlesSites('');
    setIncome('');
    setWireframes([]);
    setIntroduction('');
    setShowIntroduction(false);
    setConclusion('');
    setShowConclusion(false);
    const toast = {
      id: '2',
      title: 'Datos eliminados',
      color: 'danger',
      toastLifeTimeMs: 1500
    };
    setToasts(toasts.concat(toast));
    setShowConfirmModal(false);
    
  };

  const makePdf = () => {
    const data = {
      title,
      template: props.template.key,
      domain,
      skill,
      image,
      arrayColor,
      sites,
      articlesSites,
      income,
      wireframes,
      introduction,
      showIntroduction,
      conclusion,
      showConclusion,
    };
    write(storage.pdfTemplateData, data);
    window.open('/templates/pdf', '_blank');
  };

  const removeToast = removedToast => {
    setToasts(toasts.filter(toast => toast.id !== removedToast.id));
  };

  const confirmModal = (
    <ConfirmModal
      title={
        <p style={{ textAlign: 'center' }}>
          ¿Deseas reiniciar todos
          <br />
          los campos del formulario?
        </p>
      }
      isVisible={showConfirmModal}
      setIsVisible={setShowConfirmModal}
      onConfirm={resetData}
    />
  );


  return (
    <EuiFlexItem className="page-template wirefrema" id="pageTemplate">
      <EuiPageContentHeader style={{ justifyContent: 'center' }}>
        <EditableTitle title={title} setTitle={setTitle} />
      </EuiPageContentHeader>
      <EuiSpacer size="l" />
      <ToggleableTextBlock
        content={introduction}
        setContent={setIntroduction}
        showContent={showIntroduction}
        setShowContent={setShowIntroduction}
        buttonLabel="Agregar texto de introducción"
      />
      <EuiSpacer size="l" />
      <EuiPageContentBody>
        <EuiFormRow
          label={domainLabel.label}
          helpText={domainLabel.helpText}
          fullWidth
        >
          <CustomInput
            placeholder=""
            value={domain}
            onChangeHandler={setDomain}
            isFullWidth={true}
          />
        </EuiFormRow>
        <EuiFormRow
          label={skillLabel.label}
          fullWidth
        >
          <CustomInput
            placeholder=""
            value={skill}
            onChangeHandler={setSkill}
            isFullWidth={true}
          />
        </EuiFormRow>
        <EuiFormRow
          label={imageLabel.label}
          fullWidth
        >
          <CustomInput
            placeholder=""
            value={image}
            onChangeHandler={setImage}
            isFullWidth={true}
          />
        </EuiFormRow>

        <div className="sectionColor col-md-12" style={{ marginTop: '16px' }}>
          <div className="row">
            <EuiTitle size="xs" className="mb-1">
              <span className="text-subdued">{arrayColorLabel.label}</span>
            </EuiTitle>

            <div className="contentTable border-dashed" style={{ padding: '12px' }}>
              {arrayColor.length > 0 && true && (
                <div className="col-md-12 arrayColor">
                  <div className="row">
                    <div className="col-md-4">
                      Color
                    </div>
                    <div className="col-md-3">
                      Codigo
                    </div>
                    <div className="col-md-4">
                      Uso
                    </div>
                    <div className="col-md-1">
                    </div>
                  </div>
                </div>
              )}
              {arrayColor.length > 0 && true && (
                arrayColor.map((item, idx) => (
                  <div className="col-md-12 arrayColor" key={idx}>
                    <div className="row">
                      <div className="col-md-4">
                        {item.description}
                      </div>
                      <div className="col-md-3">
                        {item.color}
                      </div>
                      <div className="col-md-4">
                        {item.use}
                      </div>
                      <div className="col-md-1">
                        <EuiButtonIcon
                          size="s"
                          color="danger"
                          iconType="minusInCircle"
                          onClick={e => removeData(idx)}
                          aria-label="Your account"
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}

              <EuiSpacer size="s" />
              <EuiFlexGroup>
                <EuiFlexItem grow={3}>
                  <EuiFieldText
                    placeholder="Color"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    aria-label="Use aria labels when no actual label is in use"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={3}>
                  <EuiFormRow isInvalid={!!errors} error={errors}>
                    <EuiColorPicker isClearable={true} onChange={setColor} color={color} isInvalid={isInvalid} />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={3}>
                  <EuiFieldText
                    placeholder="Uso"
                    value={use}
                    onChange={e => setUse(e.target.value)}
                    aria-label="Use aria labels when no actual label is in use"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={1}>
                  <EuiButton size="s" iconType="plusInCircle" onClick={addToArrayColor}></EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>

            </div>
          </div>
        </div>

        <EuiSpacer />

        <EuiFormRow
          label={sitesLabel.label}
          fullWidth
        >
          <CustomInput
            placeholder=""
            value={sites}
            onChangeHandler={setSites}
            isFullWidth={true}
          />
        </EuiFormRow>

        <div className="col-md-12 mt-2">
          <div className="row">
            <EuiTitle size="xs">
                <span className="text-subdued">{articlesSitesLabel.label}</span>
            </EuiTitle>
            {articlesSitesLabel.helpTexts.map((text, idx)=>(
              <EuiText size="xs" className="pl-2" key={idx}>
                <p>{text}</p>
              </EuiText>
              ))}
            <CustomInput
              placeholder=""
              value={articlesSites}
              onChangeHandler={setArticlesSites}
              isFullWidth={true}
            />
          </div>
        </div>

        <EuiFormRow
          label={incomeLabel.label}
          fullWidth
          className="mt-3"
        >
          <CustomInput
            placeholder=""
            value={income}
            onChangeHandler={setIncome}
            isFullWidth={true}
          />
        </EuiFormRow>


        <div className="col-md-12 mt-2">
          <div className="row">
          <EuiTitle size="xs">
                <span className="text-subdued">{wireframesLabel.label}</span>
            </EuiTitle>
              {wireframesLabel.helpTexts.map((text, idx)=>(
                <EuiText size="xs" className="col-12 pl-2" key={idx}>
                  <p>{text}</p>
                </EuiText>
              ))}
            
            <div className="border-dashed mt-2 p-3">
              {wireframes.length > 0 && true && (
                <EuiText size="xs" className="pl-2">
                  <p>Lista de Url</p>
                </EuiText>
              )}
              <EuiListGroup listItems={wireframes} />
              <EuiFlexGroup>
              <EuiFlexItem grow={3}>
                  <EuiFieldText
                    placeholder="Descripción Link"
                    value={labelLink}
                    onChange={e => setLabelLink(e.target.value)}
                    aria-label="Label Link"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={3}>
                  <EuiFieldText
                    placeholder="Url"
                    value={link}
                    onChange={e => setLink(e.target.value)}
                    aria-label="Link"
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={1}>
                  <EuiButton size="s" iconType="plusInCircle" onClick={addToWireframes}></EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>


          </div>

        </div>


      </EuiPageContentBody>
      <EuiSpacer size="l" />

      <ToggleableTextBlock
          content={conclusion}
          setContent={setConclusion}
          showContent={showConclusion}
          setShowContent={setShowConclusion}
          buttonLabel="Agregar bloque de texto"
        />




      <TemplateBottomBar
        templateName={props.template.name}
        saveDataFunction={saveData}
        generatePdfFunction={makePdf}
        toggleEditableTitles={[]}
        setResetModal={setShowConfirmModal}
      />
      {confirmModal}
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </EuiFlexItem>
  );
};
