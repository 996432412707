import React from 'react';
import { EuiLoadingSpinner } from '@elastic/eui';

import '../../assets/scss/components/spinner.scss';

export const Spinner = () => (
  <div className="spinner-container">
    <EuiLoadingSpinner size="xl" />
  </div>
);
