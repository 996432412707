import React from 'react';
import PropTypes from 'prop-types';
import { Subtitle } from './Subtitle';
import { Content } from './Content';
import { Description } from './Description';
import { SpacerS, SpacerXs } from './Spacer';
import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap'
  },
  tag: {
    padding: 4,
    borderWidth: 1,
    borderColor: '#dddddd',
    borderStyle: 'solid',
    backgroundColor: '#eeeeee',
    color: '#333',
    fontFamily: 'Poppins',
    fontSize: 10,
    marginRight: 4
  }
});

const DescribedText = React.memo(props => (
  <React.Fragment>
    {!props.show ? (
      <React.Fragment>
        <Subtitle subtitle={props.title} />
        <SpacerXs />
        {props.showAsTags ? (
          <React.Fragment>
            {props.content.length > 0 ? (
              <Content content={props.content.map(tag => tag.text).join(', ')} />
            ) : (
              <Content content="No hay etiquetas" />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.isContentTags ? (
              <View style={styles.tagsContainer}>
                {props.content
                  .trim()
                  .split(',')
                  .map((tag, idx) => (
                    <Text key={idx} style={styles.tag}>
                      {tag}
                    </Text>
                  ))}
              </View>
            ) : (
              <Content content={props.content.trim()} />
            )}
          </React.Fragment>
        )}
        {props.description ? (
          typeof props.description == 'string' ? (
            <Description description={props.description.trim()} />
          ) : (
            props.description.map((text, idx) => (
              <Description key={idx} description={text.trim()} />
            ))
          )
        ) : null}
      </React.Fragment>
    ) : null}
    <SpacerS />
  </React.Fragment>
));

DescribedText.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  description: PropTypes.string,
  showAsTags: PropTypes.bool,
  isContentTags: PropTypes.bool
};

export { DescribedText };
