import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const FormatsModal = ({ isVisible, setIsVisible }) => {
  const closeModal = () => setIsVisible(false);

  const mainService = [
    { value: 'Entregables', bgColor: '#f498f4' },
    { value: 'Formación a medida', bgColor: '#f4cccc' },
    { value: 'Formación general', bgColor: '#f4cccc' },
    { value: 'Add-ons (Ej: PDF auditoría)', bgColor: '#b7eef2' },
    { value: 'Add-ons (Ej: Plan estratégico)', bgColor: '#b7eef2' },
    { value: 'Sesiones de asesoría/Consultoría', bgColor: '#7ef1fa' },
    { value: 'Auditoría/Evaluación', bgColor: '#d9d2e9' },
    { value: 'Otros servicios', bgColor: '#b6d7a8' },
    { value: 'Servicio de otro proveedor', bgColor: '#1dacb6' },
    { value: 'Emergency call', bgColor: '#fce5cd' },
    { value: 'Descuento para otros servicios', bgColor: '#3d85c6' },
    { value: 'Producto digital', bgColor: '#e69138' },
    { value: 'Producto físico', bgColor: '#6aa84f' }
  ];

  const mainProducts = [
    { value: 'Accesorios', bgColor: '#f498f4' },
    {
      value: 'Premium (merchandising premium que quieren pero no comprarían)',
      bgColor: '#f4cccc'
    },
    { value: 'Envío gratuito', bgColor: '#f4cccc' },
    { value: 'Descuento próxima compra', bgColor: '#b7eef2' },
    { value: 'Gift-card', bgColor: '#d9d2e9' },
    { value: 'Otros productos físicos', bgColor: '#b6d7a8' },
    {
      value: 'Upgrade gratis. Ej: Entrada Regular a entrada VIP',
      bgColor: '#3d85c6'
    },
    { value: 'Envío mismo día', bgColor: '#a487e4' },
    { value: 'Devoluciones gratis', bgColor: '#c9daf8' }
  ];

  const offerBases = [
    { value: 'Presencial', bgColor: '#f498f4' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: 'Live', bgColor: '#f4cccc' },
    { value: '', bgColor: 'transparent' },
    { value: 'Recursos/Productos', bgColor: '#b7eef2' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: 'Cursos/Programas', bgColor: '#d9d2e9' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: 'High Tickets', bgColor: '#b6d7a8' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: 'Comunidades', bgColor: '#fcfb43' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: 'Extras de valor', bgColor: '#88b1f4' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: 'Bonus', bgColor: '#fce5cd' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' },
    { value: '', bgColor: 'transparent' }
  ];

  const digitalProducts = [
    { value: 'Entrada Workshop presencial de 1 día', bgColor: '#f498f4' },
    { value: 'Entrada para Workshop High Ticket', bgColor: '#f498f4' },
    { value: 'Entrada para evento', bgColor: '#f498f4' },
    { value: 'Workshop online en vive', bgColor: '#f4cccc' },
    { value: 'Congreso online', bgColor: '#f4cccc' },
    { value: 'Documento en PDF informativo', bgColor: '#b7eef2' },
    { value: 'Documento en PDF interactivo', bgColor: '#b7eef2' },
    { value: 'Reto', bgColor: '#b7eef2' },
    { value: 'Diapositivas', bgColor: '#b7eef2' },
    { value: 'Plantilla editable', bgColor: '#1dacb6' },
    { value: 'Ebook', bgColor: '#7ef1fa' },
    { value: 'Workbook', bgColor: '#7ef1fa' },
    { value: 'Playbook (Híbrido de ebook y workbook)', bgColor: '#7ef1fa' },
    {
      value: 'Swipe File (carpeta con documentos de valor)',
      bgColor: '#b7eef2'
    },
    { value: 'Pack de vídeo', bgColor: '#b7eef2' },
    { value: 'Pack de plantillas', bgColor: '#b7eef2' },
    { value: 'Pack de productos digitales', bgColor: '#b7eef2' },
    { value: 'Curso online', bgColor: '#d9d2e9' },
    { value: 'Curso por email', bgColor: '#d9d2e9' },
    { value: 'Membresía mensual/trimestral', bgColor: '#a487e4' },
    { value: 'Mastermind', bgColor: '#a487e4' },
    { value: 'Mentoring grupal', bgColor: '#a487e4' },
    { value: 'Mentoría 1-1', bgColor: '#a487e4' },
    {
      value: 'Comunidad de soporte (Grupos FB, WhatsApp, Slack, etc.)',
      bgColor: '#fcfb43'
    },
    { value: 'Soporte en lecciones', bgColor: '#fcfb43' },
    { value: 'Soporte por teléfono e individual', bgColor: '#fcfb43' },
    { value: 'Calculadora (excel o programada)', bgColor: '#c9daf8' },
    { value: 'App/Software', bgColor: '#c9daf8' },
    { value: 'Tutorial', bgColor: '#c9daf8' },
    {
      value: 'Plantillas de plataformas. Ej: Plantillas de ClickFunnel',
      bgColor: '#c9daf8'
    },
    { value: 'Plantillas de Trello, etc.', bgColor: '#c9daf8' },
    { value: 'Masterclass tuya', bgColor: '#fce5cd' },
    { value: 'Masterclass externa', bgColor: '#fce5cd' },
    { value: 'Sesión grupal', bgColor: '#fabf82' },
    { value: 'Sesión individual', bgColor: '#fabf82' },
    { value: 'Grabación de taller en vivo', bgColor: '#fce5cd' },
    { value: 'Sesión de auditoría', bgColor: '#fabf82' },
    { value: 'Descuentos', bgColor: '#fce5cd' },
    { value: 'Versión gratis de app', bgColor: '#fce5cd' }
  ];

  return (
    <Fragment>
      <style>
        {`span.format { 
            display: block; 
            margin: 0; 
            padding: 0.25rem; 
            color: #000; 
            min-height: 24px; 
        }
        table {
          color: #000;
        }
        th, td {
          padding: 4px;
        }`}
      </style>
      {isVisible ? (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Elementos para ofertas</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiTitle size="s">
                <span
                  style={{
                    backgroundColor: '#fffb17',
                    textDecoration: 'underline',
                    display: 'block'
                  }}
                >
                  Elementos para servicios
                </span>
              </EuiTitle>
              <EuiSpacer />
              <span
                className="format"
                style={{
                  backgroundColor: '#ff0000',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
              >
                SERVICIO PRINCIPAL
              </span>
              {mainService.map((mainService, index) => (
                <span
                  key={index}
                  className="format"
                  style={{ backgroundColor: mainService.bgColor }}
                >
                  {mainService.value}
                </span>
              ))}
              <EuiSpacer />
              <EuiTitle size="s">
                <span
                  style={{
                    backgroundColor: '#fffb17',
                    textDecoration: 'underline',
                    display: 'block'
                  }}
                >
                  Elementos para productos físicos
                </span>
              </EuiTitle>
              <EuiSpacer />
              <span
                className="format"
                style={{
                  backgroundColor: '#ff0000',
                  color: '#fff',
                  fontWeight: 'bold'
                }}
              >
                PRODUCTO PRINCIPAL
              </span>
              {mainProducts.map((mainProduct, index) => (
                <span
                  key={index}
                  className="format"
                  style={{ backgroundColor: mainProduct.bgColor }}
                >
                  {mainProduct.value}
                </span>
              ))}
              <EuiSpacer />
              <EuiTitle size="s">
                <span
                  style={{
                    backgroundColor: '#fffb17',
                    textDecoration: 'underline',
                    display: 'block'
                  }}
                >
                  Elementos para productos digitales
                </span>
              </EuiTitle>
              <EuiSpacer />
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: '#ff0000',
                        color: '#fff',
                        fontWeight: 'bold'
                      }}
                    >
                      LA BASE DE LA OFERTA
                    </th>
                    <th
                      style={{
                        backgroundColor: '#ff0000',
                        color: '#fff',
                        fontWeight: 'bold'
                      }}
                    >
                      PRODUCTO PRINCIPAL
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {offerBases.map((offerBase, index) => (
                    <tr key={index}>
                      <td style={{ backgroundColor: offerBase.bgColor }}>
                        {offerBase.value}
                      </td>
                      <td
                        style={{
                          backgroundColor: digitalProducts[index].bgColor
                        }}
                      >
                        {digitalProducts[index].value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </EuiModalBody>
          </EuiModal>
        </EuiOverlayMask>
      ) : null}
    </Fragment>
  );
};

FormatsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired
};

export { FormatsModal };
