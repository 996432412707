import React, { useState, useEffect, Fragment } from 'react';
import { read as getData, write as setData, remove as removeData } from '../../services/storage';
import {
  EuiBottomBar,
  EuiButton,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiDescribedFormGroup,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiGlobalToastList,
  EuiLink,
  EuiOverlayMask,
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPopover,
  EuiSelect,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui';
import {CurrencyInput} from '../utils/CurrencyInput';

export const TemplateBudget = props => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const localData = getData(props.template.key) || {};
  const [toasts, setToasts] = useState([]);
  const closePopover = () => {
    setIsPopoverOpen(false);
  };
  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const [onboarding, setOnboarding] = useState(localData.onboarding || '');
  const [totalHours, setTotalHours] = useState(localData.totalHours || '');
  const [hourValue, setHourValue] = useState(localData.hourValue || '');
  const [hourCost, setHourCost] = useState(localData.hourCost || '');
  const [projectCost, setProjectCost] = useState(localData.projectCost || '');
  const [incidentals, setIncidentals] = useState(localData.incidentals || '');
  const [benefits, setBenefits] = useState(localData.benefits || 0);
  const [terms, setTerms] = useState(localData.terms || 0);
  const [toCollect, setToCollect] = useState(localData.toCollect || 0);
  const [amount, setAmount] = useState(localData.amount || 0);

  // const [onboarding, setOnboarding] = useState(0);
  const [, setHoursCost] = useState('');

const [someData, setSomeData] = useState({
  objetives: {
    objetive1: localData.objetive1 || "",
    objetive2: localData.objetive2 || "",
    objetive3: localData.objetive3 || "",
  },
  results: {
    resuslt1: localData.resuslt1 || "",
    resuslt2: localData.resuslt2 || "",
    resuslt3: localData.resuslt3 || "",
    resuslt4: localData.resuslt4 || "",
    resuslt5: localData.resuslt5 || "",
    resuslt6: localData.resuslt6 || "",
    resuslt7: localData.resuslt7 || "",
    resuslt8: localData.resuslt8 || "",
    resuslt9: localData.resuslt9 || "",
    resuslt10: localData.resuslt10 || "",
  },
  dedicatedHours: localData.dedicatedHours || "",
});
const removeToast = removedToast => {
  setToasts(toasts.filter(toast => toast.id !== removedToast.id));
};
const saveDataTemplate = () => {
  const dataSave = {
    onboarding,
    totalHours,
    hourValue,
    hourCost,
    projectCost,
    incidentals,
    benefits,
    terms,
    toCollect,
    amount,
    objetive1 : someData.objetives.objetive1,
    objetive2 : someData.objetives.objetive2,
    objetive3 : someData.objetives.objetive3,
    resuslt1 : someData.results.resuslt1,
    resuslt2 : someData.results.resuslt2,
    resuslt3 : someData.results.resuslt3,
    resuslt4 : someData.results.resuslt4,
    resuslt5 : someData.results.resuslt5,
    resuslt6 : someData.results.resuslt6,
    resuslt7 : someData.results.resuslt7,
    resuslt8 : someData.results.resuslt8,
    resuslt9 : someData.results.resuslt9,
    resuslt10 : someData.results.resuslt10,
    dedicatedHours: someData.dedicatedHours
  };
  setData(props.template.key, dataSave);
  let toast = { id: '1', title: 'Datos Guardados', color: 'success' };
  setToasts(toasts.concat(toast));
};

const resetData = () => {
  removeData(props.template.key);
  setOnboarding('');
  setTotalHours(''); 
  setHourValue('');
  setHourCost('');
  setProjectCost('');
  setIncidentals('');
  setBenefits(0);
  setTerms(0);
  setToCollect(0);
  setAmount(0);
  setSomeData({
    objetives: {
      objetive1: "",
      objetive2: "",
      objetive3: "",
    },
    results: {
      resuslt1: "",
      resuslt2: "",
      resuslt3: "",
      resuslt4: "",
      resuslt5: "",
      resuslt6: "",
      resuslt7: "",
      resuslt8: "",
      resuslt9: "",
      resuslt10: "",
    },
    dedicatedHours: "",
  })
};
const [isResetModalVisible, setIsResetModalVisible] = useState(false);
let resetModal;
if (isResetModalVisible) {
  resetModal = (
    <EuiOverlayMask>
      <EuiConfirmModal
        title={
          <p style={{ textAlign: 'center' }}>
            ¿Deseas reiniciar todos
            <br />
            los campos del formulario?
          </p>
        }
        onCancel={() => setIsResetModalVisible(false)}
        onConfirm={() => {
          setIsResetModalVisible(false);
          resetData();
        }}
        cancelButtonText="Cancelar"
        confirmButtonText="Sí, reiniciar formulario"
        defaultFocusedButton="confirm"
        buttonColor="danger"
      >
        <p>Esta acción no se puede deshacer</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  );
}

const [expenses, setExpenses] = useState("");
const [profit, setProfit] = useState("");
const [hoursT, setHoursT] = useState("");
const [, setHourPrice] = useState("");
const [, setErrorModal] = useState("");

const { objetives, results } = someData;
const { objetive1, objetive2, objetive3 } = objetives;
const {
  resuslt1,
  resuslt2,
  resuslt3,
  resuslt4,
  resuslt5,
  resuslt6,
  resuslt7,
  resuslt8,
  resuslt9,
  resuslt10,
} = results;
const collectData = (e, num) => {
  const value = e.target.value ? true : false;
  switch (num) {
    case 1:
      if (value) {
        setOnboarding(parseFloat(e.target.value));
      } else {
        setOnboarding(0);
      }
      break;
    case 2:
      setSomeData({
        ...someData,
        objetives: {
          ...objetives,
          objetive1: e.target.value,
        },
      });
      break;
    case 3:
      setSomeData({
        ...someData,
        objetives: {
          ...objetives,
          objetive2: e.target.value,
        },
      });

      break;
    case 4:
      setSomeData({
        ...someData,
        objetives: {
          ...objetives,
          objetive3: e.target.value,
        },
      });
      break;
    case 5:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt1: e.target.value,
        },
      });
      break;
    case 6:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt2: e.target.value,
        },
      });
      break;
    case 7:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt3: e.target.value,
        },
      });
      break;
    case 8:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt4: e.target.value,
        },
      });
      break;
    case 9:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt5: e.target.value,
        },
      });
      break;
    case 10:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt6: e.target.value,
        },
      });
      break;
    case 11:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt7: e.target.value,
        },
      });
      break;
    case 12:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt8: e.target.value,
        },
      });
      break;
    case 13:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt9: e.target.value,
        },
      });
      break;
    case 14:
      setSomeData({
        ...someData,
        results: {
          ...results,
          resuslt10: e.target.value,
        },
      });
      break;
    case 15:
      if (value) {
        setSomeData({
          ...someData,
          dedicatedHours: parseInt(e.target.value),
        });
      } else {
        setSomeData({
          ...someData,
          dedicatedHours: 0,
        });
      }
      break;
    case 16:
      if (value) {
        setHourPrice(parseInt(e.target.value));
      } else {
        setHourPrice(0);
      }
      break;
    case 17:
      if (value) {
        setHoursCost(parseFloat(e.target.value));
      } else {
        setHoursCost(0);
      }
      break;

    case 18:
      if (value) {
        setProjectCost(parseFloat(e.target.value));
      } else {
        setProjectCost(0);
      }
      break;
    case 19:
      if (value) {
        setIncidentals(parseFloat(e.target.value));
      } else {
        setIncidentals(0);
      }
      break;
    default:
      break;
  }
};
const calculatePrice = () => {
  if(expenses === "" || profit === "" || hoursT === ""){
    setErrorModal("Todos los campos son necesarios para calcular el precio de tu hora")
  }else{
    setErrorModal("");
    const calculated = (expenses + profit) / hoursT;
    setHourValue(calculated);
    closePopover();
    setExpenses("");
    setHoursT("");
    setProfit("");

  }
};

const calcData = (e, num) => {
  switch (num) {
    case 1:
      if(e){
        setExpenses(parseFloat(e));
      }else{
        setExpenses("");
      }
      break;
    case 2:
      if(e){
        setProfit(parseFloat(e));
      }else{
        setProfit("");
      }
      break;
    case 3:
      if(e){
        setHoursT(parseInt(e));
      }else{
        setHoursT("");
      }
      break;
    default:
      break;
  }
};

useEffect(()=>{
  if(totalHours > 0 || hourValue > 0){
    let calculate = parseFloat(totalHours) * parseFloat(hourValue);
    if(Number.isNaN(calculate))
    calculate = 0;
    setHourCost(calculate);
  }
},[totalHours, hourValue])


useEffect( () => {
  const _onboarding = onboarding === ""? 0: onboarding;
  const _hourCost = hourCost === ""? 0: hourCost;
  const _projectCost = projectCost === ""? 0: projectCost;
  const _incidentals = incidentals === ""? 0: incidentals;
  const _benefits = benefits === ""? 0: benefits;

  let sum = parseFloat(_onboarding) + parseFloat(_hourCost) + parseFloat(_projectCost) + parseFloat(_incidentals);
  let margin = (parseFloat(sum) * parseFloat(_benefits)) / 100;
  let collect = sum + margin;
  if(Number.isNaN(collect))
    collect = 0;
  setToCollect(collect);
}, [onboarding, hourCost, projectCost, incidentals, benefits]);

useEffect(() => {
  let amountCharge = (parseFloat(toCollect) * parseFloat(terms)) / 100;
  if(Number.isNaN(amountCharge))
    amountCharge = 0;
  setAmount(amountCharge);
  /* eslint-disable react-hooks/exhaustive-deps */
}, [terms])
/* eslint-enable */

  const buttonPopover = (
    <EuiButton
      iconSide="right"
      fill
      iconType="arrowDown"
      onClick={onButtonClick}
    >
      Calcular
    </EuiButton>
  );

  const formCalculateHour = (
    <EuiForm>
      <EuiDescribedFormGroup
        title={<h3>¿Cuánto necesitas para cubrir tus gastos cada mes?</h3>}
        description={
          <Fragment>Haz una suma de todos tus gastos fijos (Alquiler, móvil, herramientas, etc.)</Fragment>
        }
      >
        <EuiFormRow label="">
          <CurrencyInput name="expenses"
                id="expenses"
                placeholder="Ej. 1000.00"
                value={expenses}
                onChange={(e) => calcData(e, 1)} />
        </EuiFormRow>
      </EuiDescribedFormGroup>
      <EuiDescribedFormGroup
        title={<h3>¿Cuánto te gustaría ganar?</h3>}
        description={
          <Fragment>Una cifra por encima de tus gastos. Usualmente es un 75%-150% más.</Fragment>
        }
      >
        <EuiFormRow label="">
          <CurrencyInput name="profit"
                id="profit"
                value={profit}
                placeholder="Ej. 2000.00"
                onChange={(e) => calcData(e, 2)} />
        </EuiFormRow>
      </EuiDescribedFormGroup>
      <EuiDescribedFormGroup
        title={<h3>¿Cuántas horas a la semana trabajas?</h3>}
        description={
          <Fragment>Calcula los días que trabajas y la media de horas. Nosotros automáticamente multiplicaremos por 4 para saber las semanas en un mes trabajas. NO contabilices las horas de gestión de tu proyecto en correos, hablamos de horas para operaciones.</Fragment>
        }
      >
        <EuiFormRow label="">
          <CurrencyInput name="hoursT"
                value={hoursT}
                id="hoursT"
                placeholder="Ej. 30"
                onChange={(e) => calcData(e, 3)} />
        </EuiFormRow>
      </EuiDescribedFormGroup>
      <EuiSpacer />
      <div style={{ justifyContent:"center", display:"flex" }}>
      <EuiButton onClick={closePopover}>Cancelar</EuiButton> &emsp;<EuiButton onClick={calculatePrice} >Guardar</EuiButton>
      </div>
    </EuiForm>
  );

  return (
    <>
      {resetModal}
      <EuiFlexItem className="page-template" id="pageTemplate">
        <EuiPageContentHeader style={{ justifyContent: 'center' }}>
          <EuiTitle>
            <h2>Presupuestar Proyecto</h2>
          </EuiTitle>
        </EuiPageContentHeader>
        <EuiSpacer></EuiSpacer>
        <EuiSpacer>

        </EuiSpacer>
        <EuiPageContentBody>
          <EuiForm>
            <EuiDescribedFormGroup
              title={<h3>On-boarding / La base de tu servicio [OPCIONAL]</h3>}
              description={
                <Fragment>
                  ¿Cuál es la base que pedirás para comenzar a trabajar? El FEE
                  de on-boarding es de las estrategias más efectivas de Academia
                  de Consultores que enseñamos. El cliente el primer mes siempre
                  debe pagar este fee para que nunca pierdas. Si vas a cobrar
                  $1000 al mes, el on-boarding serían $1200-$2000, de modo tal
                  que si el cliente no sigue no habrás trabajado sin recibir
                  retribución.
                </Fragment>
              }
            >
              <EuiFormRow label="" fullWidth>
                <CurrencyInput 
                name="onboarding"
                value={onboarding}
                placeholder="Ej. 1000.00"
                onChange={(e)=> setOnboarding(e)}
                />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Objetivos del proyecto</h3>}
              description={
                <Fragment>
                  ¿Cuáles son los 3 objetivos de este proyecto?
                </Fragment>
              }
            >
              <EuiFormRow>
                <EuiFieldText name="objetive1"
                    id="objetive1"
                    placeholder="Primer objetivo"
                    onChange={(e) => collectData(e, 2)}
                    value={objetive1} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="objetive2"
                    id="objetive2"
                    placeholder="Segundo objetivo"
                    value={objetive2}
                    onChange={(e) => collectData(e, 3)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="objetive3"
                    id="objetive3"
                    placeholder="Tercer objetivo"
                    value={objetive3}
                    onChange={(e) => collectData(e, 4)} />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={
                <h3>
                  ¿Cuáles son los resultados de tendrá el cliente con este
                  proyecto?
                </h3>
              }
              description={
                <Fragment>
                  Lista mínimo 5 resultados que se llevará tu cliente al
                  contratar tus servicios.
                </Fragment>
              }
            >
              <EuiFormRow>
                <EuiFieldText name="resuslt"
                    id="resuslt"
                    placeholder="Resultado 1"
                    value={resuslt1}
                    onChange={(e) => collectData(e, 5)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt2"
                    id="resuslt2"
                    placeholder="Resultado 2"
                    value={resuslt2}
                    onChange={(e) => collectData(e, 6)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt3"
                    id="resuslt3"
                    placeholder="Resultado 3"
                    value={resuslt3}
                    onChange={(e) => collectData(e, 7)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt4"
                    id="resuslt4"
                    placeholder="Resultado 4"
                    value={resuslt4}
                    onChange={(e) => collectData(e, 8)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt5"
                    id="resuslt5"
                    placeholder="Resultado 5"
                    value={resuslt5}
                    onChange={(e) => collectData(e, 9)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt6"
                    id="resuslt6"
                    placeholder="Resultado 6"
                    value={resuslt6}
                    onChange={(e) => collectData(e, 10)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt7"
                    id="resuslt7"
                    placeholder="Resultado 7"
                    value={resuslt7}
                    onChange={(e) => collectData(e, 11)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt8"
                    id="resuslt8"
                    placeholder="Resultado 8"
                    value={resuslt8}
                    onChange={(e) => collectData(e, 12)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt9"
                    id="resuslt9"
                    placeholder="Resultado 9"
                    value={resuslt9}
                    onChange={(e) => collectData(e, 13)} />
              </EuiFormRow>
              <EuiFormRow>
                <EuiFieldText name="resuslt10"
                    id="resuslt10"
                    placeholder="Resultado 10"
                    value={resuslt10}
                    onChange={(e) => collectData(e, 14)} />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Horas totales que dedicarás al proyecto</h3>}
              description={
                <Fragment>
                  ¿Cuántas horas tienes que trabajar para tu cliente consiga los
                  resultados previos?
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput 
                    name="hours"
                    id="hours"
                    placeholder="Ej. 50"
                    value={totalHours}
                    onChange={(e) => setTotalHours(e)} />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Cuánto vale tu hora de trabajo</h3>}
              description={
                <Fragment>
                  Todo profesional de servicio tiene que tener el coste de su
                  hora operativa para poder presupuestar de forma correcta.
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput name="horas"
                    id="horas"
                    value={hourValue}
                    placeholder="Ej. 100.00"
                    onChange={(e) => setHourValue(e)} />
              </EuiFormRow>
              <EuiFormRow>
              <EuiPopover
                  id="formPopover"
                  ownFocus
                  button={buttonPopover}
                  isOpen={isPopoverOpen}
                  closePopover={closePopover}
                >
                  <div style={{ width: '800px' }}>{formCalculateHour}</div>
                </EuiPopover>
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Costes por horas</h3>}
              description={
                <Fragment>
                  Lo que tienes que incluir en tu presupuesto para cubrir el
                  tiempo que dedicarás al proyecto.
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput name="coste"
                    id="coste"
                    placeholder="Ej. 5000.00"
                    readOnly
                    value={hourCost} />
                
              </EuiFormRow>
              
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Costes del proyecto</h3>}
              description={
                <Fragment>
                  Todos los costes de trabajar el proyecto, horas de otros
                  miembros de tu equipo (si aplica), herramientas, recursos,
                  retenciones, coste de proveedores y otros costes indirectos.
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput name="projectCost"
                    id="projectCost"
                    placeholder="Ej. 6000.00"
                    value={projectCost}
                    onChange={(e) => setProjectCost(e)} />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Imprevistos</h3>}
              description={
                <Fragment>
                  Siempre guarda un pequeño monto para imprevistos.
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput name="incidentals"
                    id="incidentals"
                    placeholder="Ej. 150.00"
                    value={incidentals}
                    onChange={(e) => setIncidentals(e)} />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Margen de beneficios</h3>}
              description={
                <Fragment>
                  ¿Qué margen de beneficios quieres tener con este proyecto? Es
                  decir, cuando quieres ganar después de contabilizar el coste
                  real para llevar a cabo tu proyecto. Recomendamos que sea
                  mínimo un 20%. Nosotros mientras mayor es el riesgo y carga de
                  trabajo mayor margen buscamos.
                </Fragment>
              }
            >
              <EuiFormRow hasEmptyLabelSpace>
                <EuiSelect
                  hasNoInitialSelection
                  name="margin"
                    id="margin"
                    className="form-control"
                    onChange={(e) => setBenefits(e.target.value)}
                    value={benefits}
                  options={[
                    {value: '0', text:'Seleccionar...'},
                    { value: '15', text: ' 15%' },
                    { value: '20', text: ' 20%' },
                    { value: '30', text: ' 30%' },
                    { value: '50', text: ' 50%' },
                    { value: '70', text: ' 70%' }
                  ]}
                />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Terminos de pagos</h3>}
              description={
                <Fragment>
                  Lo que pedirás para comenzar a trabajar en el proyecto. En la
                  industria de servicios es normal pedir un 20% cuando el
                  trabajo previo es estratégico y cuando requiere de muchas
                  horas operativas se puede exigir un 40%-50%
                </Fragment>
              }
            >
              <EuiFormRow hasEmptyLabelSpace>
                <EuiSelect
                  hasNoInitialSelection
                  name="termsPago"
                    id="terms"
                    className="form-control"
                    onChange={(e) => setTerms(e.target.value)}
                    value={terms}
                  options={[
                    {value: '0', text:'Seleccionar...'},
                    { value: '20', text: ' 20%' },
                    { value: '30', text: ' 30%' },
                    { value: '40', text: ' 40%' },
                    { value: '50', text: ' 50%' }
                  ]}
                />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>Lo que deberías cobrar</h3>}
              description={
                <Fragment>
                  Esto es lo que deberías cobrar por hora. Recuerda que luego
                  con cada proyecto deberás calcular todas las horas y que tu
                  precio puedes subirlo en función de la demanda y precios de
                  competidores.
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput name="haveReceive"
                value={toCollect}
                    readOnly />
              </EuiFormRow>
            </EuiDescribedFormGroup>
            <EuiDescribedFormGroup
              title={<h3>El monto de tu primera factura</h3>}
              description={
                <Fragment>
                  Este es el monto de tu primera factura, es decir, lo que
                  cobrarás por comenzar a trabajar con este cliente.
                </Fragment>
              }
            >
              <EuiFormRow label="">
                <CurrencyInput 
                value={amount}
                    readOnly />
              </EuiFormRow>
            </EuiDescribedFormGroup>
          </EuiForm>
        </EuiPageContentBody>
      </EuiFlexItem>
      <EuiBottomBar className="notPrint">
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiLink href="/templates">Todas las Plantillas</EuiLink> /
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>{props.template.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="refresh"
                  onClick={() => {
                    setIsResetModalVisible(true);
                  }}
                >
                  Resetear
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  color="ghost"
                  size="s"
                  iconType="save"
                  onClick={() => {
                    saveDataTemplate();
                  }}
                >
                  Guardar
                </EuiButtonEmpty>
              </EuiFlexItem>
              
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={3000}
      />
    </>
  );
};
