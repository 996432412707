import React from 'react';
import PropTypes from 'prop-types';
import { Font, StyleSheet, Text } from '@react-pdf/renderer';
import { Spacer, SpacerS } from './Spacer';

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  content: {
    fontSize: 10,
    fontFamily: 'Poppins'
  }
});

const Introduction = React.memo(props => (
  <React.Fragment>
    {props.showField ? (
      <React.Fragment>
        <SpacerS />
        <Text style={styles.content}>{props.fieldData}</Text>
        <Spacer />
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Spacer />
      </React.Fragment>
    )}
  </React.Fragment>
));

Introduction.propTypes = {
  fieldData: PropTypes.string.isRequired,
  showField: PropTypes.bool.isRequired
};

export { Introduction };
