import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Poppins',
  src: 'https://fonts.gstatic.com/s/poppins/v13/pxiEyp8kv8JHgFVrFJA.ttf'
});

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Poppins'
  },
  description: {
    fontSize: 12,
    textAlign: 'center',
    marginHorizontal: 'auto',
    fontFamily: 'Poppins'
  }
});

const TitleSection = props => (
  <View>
    <Text style={styles.title}>{props.title}</Text>
    {props.description ? (
      <Text style={styles.description}>{props.description}</Text>
    ) : null}
  </View>
);

TitleSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export { TitleSection };
